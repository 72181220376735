import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import ListHeader from "../../../components/ListHeader/ListHeader";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import {
  ADD_COMPLAINT_CATEGORY_ENDPOINT,
  COMPLAINT_CATEGORY_AUDIT_LOG_ENDPOINT,
} from "../../../Routing/routes";
import { useDispatch, useSelector } from "react-redux";
import { getComplaintCategoryList } from "../../../redux/actions/DeskActions/ComplaintCategoryAction";
import ComplaintCategoryTable from "../../../components/TableComponent/ComplaintCategoryTable";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from '../../../Routing/permissions'

const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false, width: "50px" },
  {
    id: "complaintCategory",
    label: "Complaint Category",
    width: "600px",
    sort: false,
  },
  { id: "subCategory", label: "Sub Category Count" },
  { id: "action", label: "Action", sort: false },
];

function ManageComplaintCategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { complaintCategoryList, loading } = useSelector(
    (state) => state.complaintCategoryReducer
  );
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleAddComplaintCategoryClick = () => {
    navigate(ADD_COMPLAINT_CATEGORY_ENDPOINT);
  };

  useEffect(() => {
    dispatch(getComplaintCategoryList());
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-3">
        <div>
          <ListHeader
            leftContent="Manage Complaint Category"
            onClick={() => {
              navigate(-1);
            }}
          >
            {
              getPermission(loginData, [permissions.ADD_COMPLAINT_CATEGORY_PERMISSION]) &&
              <Button
                label="Add Complaint Category"
                onClick={handleAddComplaintCategoryClick}
              />
            }
            {getPermission(loginData, [
              permissions.VIEW_AUDIT_OF_COMPLAINT_PERMISSION
            ]) &&
              <>
                <div className="border-r border-[#CCC]"></div>
                <Button
                  label="Audit Logs"
                  onClick={handleNavigation(COMPLAINT_CATEGORY_AUDIT_LOG_ENDPOINT)}
                />
              </>
            }
          </ListHeader>
        </div>
        <div className="gap-2">
          <ComplaintCategoryTable
            loading={loading}
            data={complaintCategoryList?.map((item, index) => ({
              sNo: (index + 1)?.toString()?.padStart(2, "0"),
              id: item?.id,
              complaintCategory: item.title,
              subCategory: item?.sub_cat_count,
              type: item?.type,
              isShow: item?.isShow
            }))}
            columns={headCells}
            checkbox={false}
            pagination={false}
          ></ComplaintCategoryTable>

          {!complaintCategoryList?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-4 text-center">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default ManageComplaintCategory;
