import * as types from '../../index.types';


export function getWingsAndFlatDetails(data) {
    return {
        type: types.GET_WINGS_AND_FLATS_DETAILS,
        data
    };
}

export function getFlatDetailsWithUserName(data) {
    return {
        type: types.GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME,
        data
    };
}

export function addWing(data) {
    return {
        type: types.ADD_WINGS,
        data
    };
}

export function deleteWing(data) {
    return {
        type: types.DELETE_WING,
        data
    };
}

export function getWingAuditlogs(data) {
    return {
        type: types.GET_WINGS_AUDIT_LOGS,
        data
    };
}

export function downloadWingExcel(data) {
    return {
        type: types.DOWNLOAD_WINGS_EXCEL_SHEET,
        data
    };
}

export function getFlatDetails(data) {
    return {
        type: types.GET_FLATS_DETAILS,
        data
    };
}

export function addFlats(data) {
    return {
        type: types.ADD_FLATS_DETAILS,
        data
    };
}

export function deleteFlat(data) {
    return {
        type: types.DELETE_FLAT,
        data
    };
}

export function downloadFlatExcel(data) {
    return {
        type: types.DOWNLOAD_FLATS_EXCEL_SHEET,
        data
    };
}

export function uploadFlatsExcel(data) {
    return {
        type: types.UPLOAD_FLATS_EXCEL_SHEET,
        data
    };
}

export function getBillTariffDetails(data) {
    return {
        type: types.GET_BILL_TARIFF_DETAILS,
        data
    };
}

export function updateBillTariffDetails(data) {
    return {
        type: types.UPDATE_BILL_TARIFF_DETAILS,
        data
    };
}

export function downloadBillTariffExcel(data) {
    return {
        type: types.DOWNLOAD_BILL_TARIFF_EXCEL_SHEET,
        data
    };
}

export function uploadBillTariffExcel(data) {
    return {
        type: types.UPLOAD_BILL_TARIFF_EXCEL_SHEET,
        data
    };
}


export function getHeadList(data) {
    return {
        type: types.GET_HEAD_LIST,
        data
    };
}

export function getUtilityReadingsDetails(data) {
    return {
        type: types.GET_UTILITY_READING_DETAILS,
        data
    };
}
export function updateUtilityReadingsDetails(data) {
    return {
        type: types.UPDATE_UTILITY_READING_DETAILS,
        data
    };
}

export function uploadUtilityReadingsExcel(data) {
    return {
        type: types.UPLOAD_UTILITY_READING_EXCEL_SHEET,
        data
    };
}