import React, { useEffect, useState } from 'react'
import Layout from '../../Layout/Layout'
import ListHeaderBack from '../../../components/ListHeader/ListHeaderBack'
import { useNavigate, useParams } from "react-router-dom";
import { INVOICE_LIST_ENDPOINT } from '../../../Routing/routes'
import ButtonG from '../../../components/Button/ButtonG'
import Button from '../../../components/Button/Button'
import { TextInputFM } from '../../../components/InputFields/TextInput'
import Checkbox from '../../../components/Checkbox/Checkbox'
import TextRadio from '../../../components/InputFields/TextRadio'
import { Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getDebitSetting, updateDebitSettings } from '../../../redux/actions/FinanceActions/SettingActions/CreditDebitTariffHeaderAction'
import { CircularProgress } from '@mui/material'
import Loader from '../../../components/Loader/Loader'
import { DropdownFM2 } from '../../../components/Dropdown/dropdown2'
import { getBookingGLMappingList } from '../../../redux/actions/ClubActions/ViewBookingsAction'
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
// import {getInvoiceTypeList,} from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

const DebitnoteSetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { id } = useParams()
  const { loginData } = useSelector(state => state.loginReducer)
  const { loading, updateLoader, debitSettings } = useSelector(state => state.creditDebitTariffHeaderReducer)
  const { glMappingList } = useSelector(state => state.viewBookingReducer)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  // const { typeLists } = useSelector((state) => state.invoiceSettingReducer)
  // const [invoiceType, setInvoiceType] = useState(1);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  // const handleCancelClick = () => {
  //   navigate(INVOICE_LIST_ENDPOINT);
  // };

  useEffect(() => {
    // dispatch(getInvoiceTypeList({societyId: loginData?.default_community?.community_id}))
    dispatch(getBookingGLMappingList({ societyId: loginData?.default_community?.community_id }))
    dispatch(getDebitSetting({ societyId: loginData?.default_community?.community_id, invoiceType: id, type: "debitnote" }))

  }, [])
  useEffect(() => {
    if (debitSettings) {
      const content = debitSettings?.notes || '';
      const contentBlock = htmlToDraft(content);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, [debitSettings])

  return (
    <Layout>
      <div className='w-full h-full bg-white border border-[#CCC] rounded-lg p-2'>
        <Formik
          initialValues={{
            invoiceType: id,
            header: debitSettings?.header || '',
            displayName: debitSettings?.hidejoin_member_name || 1,
            showFlatNo: debitSettings?.hide_show_flat || 0,
            showBilltype: debitSettings?.hide_show_type || 0,
            showAddress: debitSettings?.hide_show_address || 0,
            showGST: debitSettings?.hide_show_gst || 0,
            showFlatArea: debitSettings?.hide_show_flat_area || 0,
            showReceipt: debitSettings?.hide_show_receipt || 0,
            showVAN: debitSettings?.hide_show_van || 0,
            gstApplicable: debitSettings?.gst_applicable || 0,
            gstApplicableOnInterest: debitSettings?.interest_gst || 0,
            hsnCode: debitSettings?.interest_hsn || '',
            service: debitSettings?.interest_service || 'Y',
            cgstRate: debitSettings?.cgst_rate || '',
            sgstRate: debitSettings?.sgst_rate || '',
            igstRate: debitSettings?.igst_rate || '',
            interest: debitSettings?.debitinterest || '',
            cgst: debitSettings?.sgst_head || '',
            sgst: debitSettings?.cgst_head || '',
            igst: debitSettings?.igst_head || '',
            roundOffFeature: debitSettings?.roundoff || 0,
            roundoff: debitSettings?.roundoff_head || '',
            notes: debitSettings?.notes || '',
            type: "debitnote"
          }}
          onSubmit={values =>
            dispatch(updateDebitSettings({ ...values, societyId: loginData?.default_community?.community_id }))
          }
          enableReinitialize
        >
          {
            ({ values, dirty, setFieldValue }) => {
              return (
                <Form>
                  <div className='border-b border-[#CCC]'>
                    <ListHeaderBack
                      onClick={handleNavigation(INVOICE_LIST_ENDPOINT)}
                      title="Debit note Setting"
                    >
                      <div className="flex gap-2">
                        {/* <ButtonG
                        label="Cancel"
                        onClick={handleCancelClick}
                        className="h-8 font-semibold"
                        type='button'
                      /> */}
                        {getPermission(loginData, [permissions.EDIT_DEBIT_NOTE_SETTINGS]) && <Button
                          label={
                            <span className="inline-block w-12">
                              {
                                updateLoader ?
                                  <CircularProgress sx={{ color: "white" }} size={17} />
                                  :
                                  'Save'
                              }
                            </span>
                          }
                          className="h-8 font-semibold"
                          type='submit'
                          disabled={updateLoader || loading || !dirty}
                        />}
                      </div>
                    </ListHeaderBack>
                  </div>
                  {
                    loading ?
                      <Loader /> :
                      <div className='flex justify-between '>
                        <table className='border-separate border-spacing-y-2.5 ml-2'>
                          {/* <tr>
                        <td className='text-sm w-56 pt-2.5 align-top'>Invoice Type</td>
                        <td>
                        <DropdownFM2
                          options={typeLists?.map(
                            (item) => ({
                              label: item?.invoice_title,
                              value: item?.id,
                            })
                          ) || []}
                          placeholder="Select Invoice Type"
                          className=""
                          width="225px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFieldValue('invoiceType', selectedItem.value)
                            setInvoiceType(selectedItem.value)
                            dispatch(getDebitSetting({societyId: loginData?.default_community?.community_id,invoiceType : selectedItem.value}))

                          }}
                          value={values.invoiceType === "" ?
                            null
                            :
                            {
                              label: typeLists?.find(item => item.id === values.invoiceType)?.invoice_title || "",
                              value: values.invoiceType
                            }
                          }
                          name={"invoiceType"}
                        />
                        </td>
                      </tr> */}
                          <tr>
                            <td className='text-sm w-56 pt-2.5 align-top'>Header</td>
                            <td>
                              <TextInputFM placeholder="Enter Details" name='header' className="w-56" />
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">Display Joint Member Name</td>
                            <td>
                              <div className="w-56">
                                <Checkbox
                                  text='Yes'
                                  initialchecked={values.displayName === 2}
                                  onChange={(e) => setFieldValue('displayName', e.target.checked ? 2 : 1)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">Show Flat Number</td>
                            <td>
                              <div className="w-56">
                                <Checkbox
                                  text='Yes'
                                  initialchecked={!!values.showFlatNo}
                                  onChange={(e) => setFieldValue('showFlatNo', e.target.checked ? 1 : 0)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">Show Bill Type</td>
                            <td>
                              <div className="w-56">
                                <Checkbox
                                  text='Yes'
                                  initialchecked={!!values.showBilltype}
                                  onChange={(e) => setFieldValue('showBilltype', e.target.checked ? 1 : 0)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">Show Address</td>
                            <td>
                              <div className="w-56">
                                <Checkbox
                                  text='Yes'
                                  initialchecked={!!values.showAddress}
                                  onChange={(e) => setFieldValue('showAddress', e.target.checked ? 1 : 0)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">Show GST Number</td>
                            <td>
                              <div className="w-56">
                                <Checkbox
                                  text='Yes'
                                  initialchecked={!!values.showGST}
                                  onChange={(e) => setFieldValue('showGST', e.target.checked ? 1 : 0)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">Show Flat Area</td>
                            <td>
                              <div className="w-56">
                                <Checkbox
                                  text='Yes'
                                  initialchecked={!!values.showFlatArea}
                                  onChange={(e) => setFieldValue('showFlatArea', e.target.checked ? 1 : 0)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">Show Receipt</td>
                            <td>
                              <div className="w-56">
                                <Checkbox
                                  text='Yes'
                                  initialchecked={!!values.showReceipt}
                                  onChange={(e) => setFieldValue('showReceipt', e.target.checked ? 1 : 0)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">Show Virtual Account Number</td>
                            <td>
                              <div className="w-56">
                                <Checkbox
                                  text='Yes'
                                  initialchecked={!!values.showVAN}
                                  onChange={(e) => setFieldValue('showVAN', e.target.checked ? 1 : 0)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">GST Applicable</td>
                            <td>
                              <div className="w-56">
                                <Checkbox
                                  text='Yes'
                                  initialchecked={!!values.gstApplicable}
                                  onChange={(e) => setFieldValue('gstApplicable', e.target.checked ? 1 : 0)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">GST Applicable On Interest</td>
                            <td>
                              <div className="w-56">
                                <Checkbox
                                  text='Yes'
                                  initialchecked={!!values.gstApplicableOnInterest}
                                  onChange={(e) => setFieldValue('gstApplicableOnInterest', e.target.checked ? 1 : 0)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-sm w-56 align-top pt-2.5">Interest HSN Code</td>
                            <td>
                              <TextInputFM placeholder="Enter Code" className="w-56" name='hsnCode' />
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">Interest Service / Goods</td>
                            <td>
                              <TextRadio
                                options={[
                                  { name: 'service', label: 'Service', value: 'Y' },
                                  { name: 'service', label: 'Goods', value: 'N' }
                                ]}
                                defaultSelected={values.service}
                                onChange={(value) => { setFieldValue('service', value) }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">CGST Rate (%)</td>
                            <td className='flex items-center'>
                              <TextInputFM placeholder="Enter Rate" className="w-56" name='cgstRate' />
                              <span className="text-[#AAA] text-xs ml-2">%</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">SGST Rate (%)</td>
                            <td className='flex items-center'>
                              <TextInputFM placeholder="Enter Rate" className="w-56" name='sgstRate' />
                              <span className="text-[#AAA] text-xs ml-2">%</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">IGST Rate (%)</td>
                            <td className='flex items-center'>
                              <TextInputFM placeholder="Enter Rate" className="w-56" name='igstRate' />
                              <span className="text-[#AAA] text-xs ml-2">%</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top ">Interest (GL Mapping)</td>
                            <td>
                              <div className=''>
                                <DropdownFM2
                                  options={glMappingList?.map(item => ({
                                    label: item.name,
                                    value: item.ac_code
                                  })) || []}
                                  width="224px"
                                  height='38px'
                                  placeholder="Select"
                                  className="text-xs"
                                  onSelect={(selectedItem) => {
                                    setFieldValue('interest', selectedItem.value)
                                  }}
                                  name='interest'
                                  value={values.interest ?
                                    {
                                      label: glMappingList?.find(item => item.ac_code === values.interest)?.name,
                                      value: values.interest
                                    }
                                    :
                                    null
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top ">CGST (GL Mapping)</td>
                            <td>
                              <div className=''>
                                <DropdownFM2
                                  options={glMappingList?.map(item => ({
                                    label: item.name,
                                    value: item.ac_code
                                  })) || []}
                                  width="224px"
                                  height='38px'
                                  placeholder="Select"
                                  className="text-xs"
                                  onSelect={(selectedItem) => {
                                    setFieldValue('cgst', selectedItem.value)
                                  }}
                                  name='cgst'
                                  value={values.cgst ?
                                    {
                                      label: glMappingList?.find(item => item.ac_code === values.cgst)?.name,
                                      value: values.cgst
                                    }
                                    :
                                    null
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top ">SGST (GL Mapping)</td>
                            <td>
                              <div className=''>
                                <DropdownFM2
                                  options={glMappingList?.map(item => ({
                                    label: item.name,
                                    value: item.ac_code
                                  })) || []}
                                  width="224px"
                                  height='38px'
                                  placeholder="Select"
                                  className="text-xs"
                                  onSelect={(selectedItem) => {
                                    setFieldValue('sgst', selectedItem.value)
                                  }}
                                  name='sgst'
                                  value={values.sgst ?
                                    {
                                      label: glMappingList?.find(item => item.ac_code === values.sgst)?.name,
                                      value: values.sgst
                                    }
                                    :
                                    null
                                  }
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="w-56 text-sm align-top ">IGST (GL Mapping)</td>
                            <td>
                              <div className=''>
                                <DropdownFM2
                                  options={glMappingList?.map(item => ({
                                    label: item.name,
                                    value: item.ac_code
                                  })) || []}
                                  width="224px"
                                  height='38px'
                                  placeholder="Select"
                                  className="text-xs"
                                  onSelect={(selectedItem) => {
                                    setFieldValue('igst', selectedItem.value)
                                  }}
                                  name='igst'
                                  value={values.igst ?
                                    {
                                      label: glMappingList?.find(item => item.ac_code === values.igst)?.name,
                                      value: values.igst
                                    }
                                    : null
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top">Round Off Feature</td>
                            <td>
                              <div className="w-56">
                                <Checkbox
                                  text='Yes'
                                  initialchecked={!!values.roundOffFeature}
                                  onChange={(e) => setFieldValue('roundOffFeature', e.target.checked ? 1 : 0)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top ">Round Off</td>
                            <td>
                              <div className=''>
                                <DropdownFM2
                                  options={glMappingList?.map(item => ({
                                    label: item.name,
                                    value: item.ac_code
                                  })) || []}
                                  width="224px"
                                  height='38px'
                                  placeholder="Select"
                                  className="text-xs"
                                  onSelect={(selectedItem) => {
                                    setFieldValue('roundoff', selectedItem.value)
                                  }}
                                  name='roundoff'
                                  value={values.roundoff ?
                                    {
                                      label: glMappingList?.find(item => item.ac_code === values.roundoff)?.name,
                                      value: values.roundoff
                                    }
                                    :
                                    null
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-56 text-sm align-top ">Notes</td>
                            <td>
                              <Editor
                                editorState={editorState}
                                onEditorStateChange={(newEditorState)=>{
                                  setEditorState(newEditorState);
                                  let content = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
                                  setFieldValue('notes',content);
                                }}
                                toolbar={{
                                  options: [
                                    "inline",
                                    "fontSize",
                                    "list",
                                    "textAlign",
                                    "link",
                                    "image",
                                    "colorPicker",
                                    "history",
                                  ],
                                  inline: {
                                    inDropdown: false,
                                    options: ["bold", "italic", "underline"],
                                  },
                                  list: { inDropdown: true },
                                  textAlign: { inDropdown: true },
                                  link: { inDropdown: true },
                                  history: {
                                    inDropdown: false,
                                    options: ["redo", "undo"],
                                  },
                                }}
                                editorStyle={{
                                  height: "250px",
                                  width: "100%",
                                  padding: "10px",
                                  border: "1px solid #f1f1f1",
                                  overflowY: "auto",
                                  zIndex : 1,
                                }}
                              />
                            </td>
                          </tr>
                        </table>
                      </div>
                  }
                </Form>
              )
            }
          }
        </Formik>
      </div>
    </Layout>
  )
}
export default DebitnoteSetting