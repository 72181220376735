import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../actions/index.types";
import * as req from "../../../axios/request/DeskReqs/ComplaintCategoryReqs/ComplaintCategoryReqs";
import { notify } from "../../../utils/notification";

function* getComplaintCategoryList(action) {
  try {
    const result = yield call(req.getComplaintCategoryListReq);
    if (result.statusCode === 200) {
      yield put({
        type: types.GET_COMPLAINT_CATEGORY_LIST_SUCCESS,
        payload: result.data,
      });
    } else {
      yield put({
        type: types.GET_COMPLAINT_CATEGORY_LIST_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* getComplaintCategoryDetailsList(action) {
  try {
    const result = yield call(req.getComplaintCategoryDetailsListReq, action);
    if (result.statusCode === 200) {
      yield put({
        type: types.GET_COMPLAINT_CATEGORY_DETAILS_LIST_SUCCESS,
        payload: result.data,
      });
    } else {
      yield put({
        type: types.GET_COMPLAINT_CATEGORY_DETAILS_LIST_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* addComplaintCategory(action) {
  try {
    const result = yield call(req.addComplaintCategoryReq, action);
    if (result.statusCode === 200) {
      notify("success", result.message);
      action.data?.onSuccesCallback();
      yield put({ type: types.ADD_COMPLAINT_CATEGORY_LIST_SUCCESS });
    } else {
      yield put({
        type: types.ADD_COMPLAINT_CATEGORY_LIST_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* editComplaintCategory(action) {
  try {
    const result = yield call(req.editComplaintCategoryReq, action);
    if (result.statusCode === 200) {
      notify("success", result.message);
      action.data?.onSuccessCallback();
      yield put({ type: types.EDIT_COMPLAINT_CATEGORY_LIST_SUCCESS });
    } else {
      yield put({
        type: types.EDIT_COMPLAINT_CATEGORY_LIST_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* deleteComplaintCategory(action) {
  try {
    const result = yield call(req.deleteCategoryReq, action);
    if (result.statusCode === 200) {
      notify("success", result.message);
      action.data?.onSuccessCallback();
      yield put({ type: types.DELETE_CATEGORY_SUCCESS, payload: action.data });
    }
  } catch (e) {
    console.log(e);
  }
}

function* getComplaintCategoryAuditLogs(action) {
  try {
      const result = yield call(req.getComplaintCategoryAuditLogsReq, action);
      if (result.statusCode === 200) {
          yield put({ type: types.GET_COMPLAINT_CATEGORY_AUDIT_LOG_SUCCESS, payload: result.data});  
      } else {
          yield put({ type: types.GET_COMPLAINT_CATEGORY_AUDIT_LOG_FAILURE, message: result?.response?.data?.message });
      }
  } catch (e) {
      console.log(e)
  }
}

function* getComplaintSetting(action) {
  try {
    const result = yield call(req.getComplaintSettingReq, action);
    if (result.statusCode === 200) {
      yield put({
        type: types.GET_COMPLAINT_SETTING_LIST_SUCCESS,
        payload: result.data,
      });
    } else {
      yield put({
        type: types.GET_COMPLAINT_SETTING_LIST_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateComplaintSetting(action) {
  try {
    const result = yield call(req.updateComplaintSettingReq, action);
    if (result.statusCode === 200) {
      notify("success", result.message);
      yield put({
        type: types.UPDATE_COMPLAINT_SETTING_LIST_SUCCESS,
        payload: {
          // society_id: action.data.societyId,
          cat_setting: action.data.cat_setting,
        },
      });
    } else {
      yield put({
        type: types.UPDATE_COMPLAINT_SETTING_LIST_FAILURE,
        message: result?.response?.data?.message,
      });
      notify("error", "Something went wrong");
    }
  } catch (e) {
    notify("error", "Something went wrong");
    console.log(e);
  }
}

function* getComplaintTypeSetting(action) {
  try {
      const result = yield call(req.getComplaintTypeSettingReq, action);
      if (result.statusCode === 200) {
          yield put({ type: types.GET_COMPLAINT_TYPE_SETTING_SUCCESS, payload: result.data});  
      } else {
          yield put({ type: types.GET_COMPLAINT_TYPE_SETTING_FAILURE, message: result?.response?.data?.message });
      }
  } catch (e) {
      console.log(e)
  }
}

function* updateComplaintTypeSetting(action) {
  try {
      const result = yield call(req.updateComplaintTypeSettingReq, action);
      if (result.statusCode === 200) {
          notify('success', result.message);
          yield put({ type: types.UPDATE_COMPLAINT_TYPE_SETTING_SUCCESS, payload: result.data});  
      } else {
          yield put({ type: types.UPDATE_COMPLAINT_TYPE_SETTING_FAILURE, message: result?.response?.data?.message });
      }
  } catch (e) {
      console.log(e)
  }
}

export default function* complaintCategorySaga() {
  yield takeLatest(types.GET_COMPLAINT_CATEGORY_LIST, getComplaintCategoryList);
  yield takeLatest(
    types.GET_COMPLAINT_CATEGORY_DETAILS_LIST,
    getComplaintCategoryDetailsList
  );
  yield takeLatest(types.ADD_COMPLAINT_CATEGORY_LIST, addComplaintCategory);
  yield takeLatest(types.EDIT_COMPLAINT_CATEGORY_LIST, editComplaintCategory);
  yield takeLatest(types.DELETE_CATEGORY, deleteComplaintCategory);
  yield takeLatest(types.GET_COMPLAINT_CATEGORY_AUDIT_LOG, getComplaintCategoryAuditLogs);
  yield takeLatest(types.GET_COMPLAINT_SETTING_LIST, getComplaintSetting);
  yield takeLatest(types.UPDATE_COMPLAINT_SETTING_LIST, updateComplaintSetting);
  yield takeLatest(types.GET_COMPLAINT_TYPE_SETTING, getComplaintTypeSetting);
  yield takeLatest(types.UPDATE_COMPLAINT_TYPE_SETTING, updateComplaintTypeSetting);
}
