import React from "react";
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";
import { useEffect } from "react";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Search from "../../../components/SearchBox/Search";
import NoticeAndCircularTable from "./NoticeAndCircularTable";
import { useSelector, useDispatch } from "react-redux";
import { getNoticeCircularList } from "../../../redux/actions/SocialAndCommunicationActions/NoticeAndCircularAction";
import moment from "moment";
import {
  ARCHIVE_NOTICE_AND_CIRCULARS_ENDPOINT,
  CREATE_NOTICE_AND_CIRCULARS_ENDPOINT,
  DRAFT_NOTICE_AND_CIRCULARS_ENDPOINT,
  NOTICE_AND_CIRCULARS_AUDIT_LOG_ENDPOINT,
  NOTICE_AND_CIRCULARS_ENDPOINT,
} from "../../../Routing/routes";

export const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false, width: "50px" },
  {
    id: "subject",
    label: "Subject",
    sort: true,
    width: "200px",
  },
  {
    id: "type",
    label: "Type",
    sort: true,
    width: "150px",
  },
  { id: "notice_on", label: "Notice On", sort: true, width: "100px" },
  // { id: "expiredOn", label: "Expired On", sort: true, width: "100px" },
  { id: "action", label: "Action", sort: false, width: "70px" },
];

export const rows = [
  {
    id: 1,
    type: "notice",
    subject: "test 435234",
    createdOn: "2023-03-02",
  },
];
const NoticeAndCircular = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { noticeCircularList, loading } = useSelector(
    (state) => state.noticeAndCircularReducer
  );

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("filter_text")) {
      searchParams.delete("page");
      searchParams.delete("limit");
      searchParams.delete("filter_text");
      navigate({
        pathname: `${NOTICE_AND_CIRCULARS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["filter_text", text?.trim()],
        ])}`,
      });
    } else {
      searchParams.delete("filter_text");
      navigate({
        pathname: `${NOTICE_AND_CIRCULARS_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };

  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      filter_type: "notice",
      page: searchParams.get("page") || 1,
      filter_text: searchParams.get("filter_text") || "",
      limit: searchParams.get("limit") || 10,
    };
    dispatch(getNoticeCircularList(data));
  };

  useEffect(() => {
    getTableData();
  }, [searchParams]);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="Notice and Circulars">
          <Search
            width={36}
            height="8"
            onclick={handleSearch}
            value={searchParams.get("filter_text")}
          />
          <span className="border-r border-[#CCC]"></span>
          {getPermission(loginData, [
            permissions.VIEW_NOTICE_CIRCULAR_ARCHIVES_PERMISSION,
          ]) && (
            <Button
              label="Archives"
              onClick={handleNavigation(ARCHIVE_NOTICE_AND_CIRCULARS_ENDPOINT)}
            />
          )}
          {getPermission(loginData, [
            permissions.VIEW_NOTICE_CIRCULAR_DRAFTS_PERMISSION,
          ]) && (
            <Button
              label="Drafts"
              onClick={handleNavigation(DRAFT_NOTICE_AND_CIRCULARS_ENDPOINT)}
            />
          )}
          {getPermission(loginData, [
            permissions.CREATE_NOTICE_CIRCULAR_PERMISSION,
          ]) && (
            <Button
              label="Write On Notice Board"
              onClick={handleNavigation(CREATE_NOTICE_AND_CIRCULARS_ENDPOINT)}
            />
          )}
          <Button
              label="Audit Logs"
              onClick={handleNavigation(NOTICE_AND_CIRCULARS_AUDIT_LOG_ENDPOINT)}
            />
        </ListHeader>
        <div>
          <NoticeAndCircularTable
            loading={loading}
            data={noticeCircularList?.list?.map((item, index) => ({
              sNo: (
                (noticeCircularList?.current_page - 1) *
                  noticeCircularList?.limit +
                1 +
                index
              )
                ?.toString()
                ?.padStart(2, "0"),

              id: item?.id,
              subject: item?.title,
              type: item?.type,
              notice_on: item?.notice_date
                ? moment(new Date(item?.notice_date))?.format("MMM DD, YYYY")
                : "-",
              comment_count: item?.comment_count,
              ack_count: item?.ack_count - 1 > 0 ? item?.ack_count - 1 : 0,
            }))}
            columns={headCells}
            checkbox={false}
            pagination={noticeCircularList?.list?.length}
            totalCount={noticeCircularList?.total_count}
            totalPages={noticeCircularList?.total_pages}
            start={
              (noticeCircularList?.current_page - 1) *
                noticeCircularList?.limit +
              1
            }
            end={
              (noticeCircularList?.current_page - 1) *
                noticeCircularList?.limit +
              noticeCircularList?.list?.length
            }
            currentPage={noticeCircularList?.current_page}
            rowsPerPage={searchParams.get("limit") || 10}
            getTableData={getTableData}
          ></NoticeAndCircularTable>
        </div>
        {!noticeCircularList?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default NoticeAndCircular;
