import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../api";
import axiosInstance from "../../../axiosInstance";
import { logOut } from "../../../../utils/helpers/logOut";
import getBrowserDetails from "../../../../utils/helpers/getBrowserDetails";
import { errorMsg } from "../../../../consts/responseMessages";

export async function getTitleListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_TITLE_LIST_API}?society_id=${action.data.societyId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getOccupantsListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_OCCUPANTS_LIST_API}?society_id=${
        action.data.societyId
      }&page_no=${action.data.page}&page_limit=${action.data.pageLimit}${
        action.data.wingId ? `&wing_id=${action.data.wingId}` : ""
      }${action.data.flatId ? `&flat_id=${action.data.flatId}` : ""}${
        action.data.titleId ? `&title_id=${action.data.titleId}` : ""
      }${
        action.data.searchText
          ? `&search_text=${encodeURIComponent(action.data.searchText)}`
          : ""
      }`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function downloadOccupantsExcelSheetReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_OCCUPANTS_EXCEL_API}?society_id=${
        action.data?.societyId
      }${action.data.wingId ? `&wing_id=${action.data.wingId}` : ""}${
        action.data.flatId ? `&flat_id=${action.data.flatId}` : ""
      }${
        action.data.status ? `&title_id=${action.data.status}` : ""
      }&is_sample=${action.data.isSample ? 1 : 0}${
        action.data.searchText
          ? `&search_text=${encodeURIComponent(action.data.searchText)}`
          : ""
      }`,
      {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function sendEmailToOccupantsReq(action) {
  const BODY = {
    email_subject: action.data.subject,
    email_body: action.data.message,
    subscribe_id: action.data.subscriberId,
  };

  return axiosInstance
    .post(apiEndpoints.SEND_EMAIL_TO_OCCUPANTS_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function changeOccupantStatusReq(action) {
  const BODY = {
    status: action.data?.status,
    subscribe_ids: action.data?.subscribeId,
  };

  return axiosInstance
    .put(apiEndpoints.CHANGE_OCCUPANTS_STATUS_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function changeOccupantStatusInBulkReq(action) {
  const BODY = {
    action: action.data?.action,
    subscribe_ids: action.data?.subscribeIds,
  };

  return axiosInstance
    .put(apiEndpoints.CHANGE_OCCUPANTS_STATUS_IN_BULK_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getMemberPrivacyListReq(action) {
  let queryString = "";
  action.data?.subscribeIds?.forEach((item, index) => {
    if (index) queryString += `,${item}`;
    else queryString += `subscribe_ids=${item}`;
  });
  console.log(queryString);

  return axiosInstance
    .get(`${apiEndpoints.GET_MEMBER_PRIVACY_DETAILS_API}?${queryString}`)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function updateMemberPrivacyReq(action) {
  const BODY = {
    subscribe_ids: action.data?.memberPrivacy?.map((item) => item.subcriberId),
    mem_privacy_data: action.data?.memberPrivacy?.map((item) => ({
      mem_privacy_id: item?.memberPrivacyId,
      m_name: item?.name,
      m_number: item?.number,
      m_email: item?.email,
      m_dob: item?.dob,
      m_staff: item?.staff,
    })),
  };

  return axiosInstance
    .put(apiEndpoints.UPDATE_MEMBER_PRIVACY_DETAILS_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function addOccupantsReq(action) {
  const BODY = {
    "society_id": action.data?.societyId,
    "browser_details": getBrowserDetails(),
    "session_id": action.data?.sessionId,
    "ip": action.data?.ipAddress,
    "title_id": action.data?.titleId,
    "register_as": action.data?.registerAs,
    "subscriber_prefix": action.data?.prefix,
    "first_name": action.data?.firstName,
    "last_name": action.data?.lastName,
    "middle_name": action.data?.middleName,
    "subscriber_gender": action.data?.gender,
    "country_id": action.data?.countryId,
    "mobile_country_code": action.data?.mobileCountryCode,
    "subscriber_number": action.data?.mobileNo,
    "number_visible": action.data?.mobileVisible,
    "subscriber_alternate_number": action.data?.secondaryMobileNo,
    "alt_send_sms": action.data?.sendeSMSOnAlternateNo,
    "subscriber_email": action.data?.primaryEmail,
    "email_visible": action.data?.emailVisible,
    "sec_send_email": action.data?.sendeSMSOnAlternateEmail,
    "set_login": +action.data?.loginAccess,
    "subscriber_sec_email": action.data?.secondaryEmail,
    "aadhar_card": action.data?.adharCardNo,
    "aadhar_file": action.data?.adharFile,
    "wing_id": action.data?.wingId,
    "flat_id": action.data?.flatId,
    "picture": action.data?.profilePic,
    "cam_start_date": action.data?.camStartDate,
  };
  return axiosInstance
    .post(apiEndpoints.ADD_OCCUPANT_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function deleteOccupantsReq(action) {
  const BODY = {
    ip: action.data?.ipAddress,
    browser_details: getBrowserDetails(),
    session_id: action.data?.sessionId,
    flat_id: action.data?.deleteflatId,
    subscribe_id: action.data?.subscriberId,
  };

  return axiosInstance
    .delete(apiEndpoints.DELETE_OCCUPANT_API, { data: BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getOccupantsAuditLogsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_OCCUPANT_AUDIT_LOGS_API}?subscribe_id=${action.data.subscriberId}&flat_id=${action.data.flatId}&user_acc_id=${action.data.userAccId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getExistingOccupantsListReq(action) {
  return axiosInstance
    .get(
      `${
        apiEndpoints.GET_EXISTING_OCCUPANT_API
      }?search_text=${encodeURIComponent(action.data.searchText)}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function addExistingOccupantsListReq(action) {
  const BODY = {
    society_id: action.data?.societyId,
    browser_details: getBrowserDetails(),
    session_id: action.data?.sessionId,
    ip: action.data?.ipAddress,
    user_id: action.data?.userId,
    title_id: action.data?.titleId,
    register_as: action.data?.registerAs,
    subscriber_prefix: action.data?.prefix,
    first_name: action.data?.firstName,
    last_name: action.data?.lastName,
    middle_name: action.data?.middleName,
    subscriber_gender: action.data?.gender,
    country_id: action.data?.countryID ? parseInt(action.data?.countryID) : 0,
    mobile_country_code: action.data?.mobileCountryCode,
    subscriber_number: action.data?.mobileNo,
    subscriber_email: action.data?.email,
    picture: action.data?.profilePic,
    wing_id: action.data?.wingId,
    flat_id: action.data?.flatId,
  };

  return axiosInstance
    .post(apiEndpoints.ADD_EXISTING_OCCUPANT_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getArchivedOccupantsListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_ARCHIVED_OCCUPANTS_API}?society_id=${action.data.societyId}&page_no=${action.data?.page}&page_limit=${action.data.pageLimit}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getOccupantDetailsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_OCCUPANT_DETAILS_API}?subscribe_id=${
        action.data.subscribeId
      }&society_id=${action.data.societyId}&user_type=${"User"}&user_id=${
        action.data?.userId
      }`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function editOccupantDetailsReq(action) {
  const BODY = {
    society_id: action.data?.societyId,
    browser_details: getBrowserDetails(),
    session_id: action.data?.sessionId,
    ip: action.data?.ipAddress,
    occu_profile: {
      subscribe_id: action.data?.subscribeId,
      subscriber_number: action.data?.mobileNo,
      subscriber_alternate_number: action.data?.secondaryMobileNo,
      sec_send_email :action.data?.sendeSMSOnAlternateEmail,
      alt_send_sms: action.data?.sendeSMSOnAlternateNo,
      subscriber_email: action.data?.primaryEmail,
      subscriber_sec_email: action.data?.secondaryEmail,
      subscriber_prefix: action.data?.prefix,
      first_name: action.data?.firstName,
      last_name: action.data?.lastName,
      middle_name: action.data?.middleName,
      subscriber_gender: action.data?.gender,
      picture: action.data?.profilePic,
      number_visible: action.data?.mobileVisible,
      email_visible: action.data?.emailVisible,
      pancard_no: action.data?.panCardNo?.toUpperCase(),
      aadhar_card: action.data?.adharCardNo,
      aadhar_file: action.data?.adharFile,
      dob: action.data?.dob,
      anniversary_date: action.data?.anniversaryDate,
      gst_registration_no: action.data?.gstRegistrationNumber,
      gst_visible: action.data?.showInBill,
      mobile_country_code: action.data?.mobileCountryCode,
      country_id: action.data?.countryId,
      set_login: action.data?.loginAccess,
      class_of_member: action.data?.classMemberId,
      landline_office: action.data?.landlineNo,
      title_id: action.data?.titleId,
      title: action.data?.title,
    },
    occupant_flats: action.data?.flats?.map((item) => ({
      flat_id: item?.flatId,
      date_of_entrance_fee: item?.dateOfpayment,
      age_on_date_of_admission: parseInt(item?.age) || 0,
      date_of_membership: item?.dateOfMembership,
      date_of_admission: item?.dateofAdmission,
      billing_name: item?.billingName,
      gst_no: item?.gstNo,
      cam_start_date: item?.camStartDate,
      lives_here: item?.liveHereOrNot,
      intercom_no: item?.intercomNo,
      landline_resi: item?.landlineNumber,
      profession: item?.profession,
      photo_id_submitted: item?.photoSubmitted,
      flat_address: item?.fullAddress,
      flat_desc: item?.description,
      no_of_share_certificate: item?.noOfShareCertificate,
      share_cert_no: item?.shareCertificateNo,
      electric_meter: item?.electricMeter,
      sale_deed_no: item?.saleDeedNo,
      member_deposit: parseFloat(item?.memberSecurityDeposit) || 0,
      builder_deposit: parseFloat(item?.buildSecurityDeposit) || 0,
    })),
  };
  return axiosInstance
    .post(apiEndpoints.UPDATE_OCCUPANT_DETAILS_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getMortgageRegisterDetailsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_MORTGAGE_DETAILS_API}?society_id=${action.data.societyId}&flat_id=${action.data.flatId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function addMortgageRegisterDetailsReq(action) {
  const BODY = {
    mortgage_id: action.data.id || 0,
    society_id: action.data?.societyId,
    flat_id: action.data?.flatId,
    financial_institute: action?.data.bankName,
    lien_letter: action?.data.lienLetter,
    lien_receipt: action?.data.lienReceipt,
    date_lien_cancellation: action?.data.dateLienCancellation,
    cancellation_details: action?.data.cancellationDetails,
    mc_meeting: action?.data.mcMeeting,
    resolution_reference: action?.data.resolutionRef,
    remark: action?.data.remark,
    cancel_letter_doc: action?.data.cancellationLetter,
    society_noc_doc: action?.data.societyNoc,
    charge_letter_doc: action?.data.letterOfLien,
  };

  return axiosInstance
    .post(apiEndpoints.UPDATE_MORTGAGE_DETAILS_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function addFamilyMemberReq(action) {
  const BODY = {
    "society_id": action.data?.societyId,
    "house_unique_id": parseInt(action.data?.flatId),

    "user_data": action.data?.familyMembers.map((item) => ({
      "family_role_id": item.role,
      "full_name": item?.name,
      "email": item?.emailAddress,
      "mobile_number": `+${item?.mobileCountryCode}-${item?.mobileNo}`,
      "relationship": item?.relationship,
      "gender": item?.gender,
      "blood_group": item?.bloodGroup,
      "dob": item?.dateOfBirth,
      "anniversary_date": item?.anniversaryDate,
      "profession": item?.profession,
      "other_details": item?.otherDetails,
      "profile_image": "",
      "is_family_login": item?.isFamilyLogin,
      "age": item?.age,
    })),
  };

  return axiosInstance
    .post(apiEndpoints.ADD_FAMILY_MEMBER_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function deleteFamilyMemberReq(action) {

  return axiosInstance
    .delete(`${apiEndpoints.DELETE_FAMILY_MEMBER_API}/${action.data?.deleteFamilyId}`)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getOccupantsStaffListReq(action) {
  const BODY = {
    society_id: action.data?.societyId,
    page: 1,
    limit: 10,
    search_text: "",
    filter_wing: "",
    filter_flat: "",
    filter_staff_type: "",
    filter_approval: "",
  };

  return axiosInstance
    .post(apiEndpoints.GET_OCCUPANTS_STAFF_LIST_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}


export async function updateFamilyMemberReq(action) {
  const BODY= {
    "house_unique_id": parseInt(action.data?.flatId),
    "family_role_id": action.data?.familyMembers?.[0]?.role,
    "id": action.data?.familyMembers?.[0]?.id,
    "is_family_login": action.data?.familyMembers?.[0]?.isFamilyLogin,
    "full_name": action.data?.familyMembers?.[0]?.name,
    "mobile_number": `+${action.data?.familyMembers?.[0]?.mobileCountryCode}-${action.data?.familyMembers?.[0]?.mobileNo}`,
    "age": action.data?.familyMembers?.[0]?.age,
    "relationship": action.data?.familyMembers?.[0]?.relationship,
    "gender": action.data?.familyMembers?.[0]?.gender,
    "blood_group": action.data?.familyMembers?.[0]?.bloodGroup,
    "dob": action.data?.familyMembers?.[0]?.dateOfBirth,
    "anniversary_date": action.data?.familyMembers?.[0]?.anniversaryDate,
    "profession": action.data?.familyMembers?.[0]?.profession,
    "other_details": action.data?.familyMembers?.[0]?.otherDetails,
    "email": action.data?.familyMembers?.[0]?.emailAddress,
  }

  return axiosInstance.put(apiEndpoints.UPDATE_FAMILY_MEMBER_API, {...BODY})
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if (errors.response?.data.statusCode === 400) {
              notify('error', errors.response.data.message);
          }else if (errors.response.data.statusCode === 401) {
              notify('error', errors.response.data.message);
              logOut()
          } else {
              notify('warning', "Something went wrong");
          }
          return errors
      })
}

export async function addFamilyMemberThroughExcelReq(action) {

  var bodyFormData = new FormData();
  bodyFormData.append('society_id', action.data?.societyId);
  bodyFormData.append('flat_id', action.data?.flatId);
  bodyFormData.append('browser_details', getBrowserDetails());
  bodyFormData.append('session_id', action.data?.sessionId);
  bodyFormData.append('ip', action.data?.ipAddress);
  bodyFormData.append('file', action.data.file);

  return axiosInstance.post(apiEndpoints.ADD_FAMILY_MEMBER_THROUGH_EXCEL_API, bodyFormData)
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if (errors.response?.data.statusCode === 400) {
              if(errors.response?.data?.type === 'COLS_DATA_NOT_FOUND')
                  notify('error', "Columns name doesn't match");
              else
                  notify('error', errors.response.data.message);
          }else if (errors.response.data.statusCode === 401) {
              notify('error', errors.response.data.message);
              logOut()
          } else {
              notify('warning', "Something went wrong");
          }
          return errors

      })
}

export async function updateFamilyMemberThroughExcelReq(action) {

  var bodyFormData = new FormData();
  bodyFormData.append('society_id', action.data?.societyId);
  // bodyFormData.append('flat_id', action.data?.flatId);
  bodyFormData.append('browser_details', getBrowserDetails());
  bodyFormData.append('session_id', action.data?.sessionId);
  bodyFormData.append('ip', action.data?.ipAddress);
  bodyFormData.append('file', action.data.file);

  return axiosInstance.post(apiEndpoints.UPDATE_FAMILY_MEMBER_THROUGH_EXCEL_API, bodyFormData)
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if (errors.response?.data.statusCode === 400) {
              if(errors.response?.data?.type === 'COLS_DATA_NOT_FOUND')
                  notify('error', "Columns name doesn't match");
              else
                  notify('error', errors.response.data.message);
          }else if (errors.response.data.statusCode === 401) {
              notify('error', errors.response.data.message);
              logOut()
          } else {
              notify('warning', "Something went wrong");
          }
          return errors

      })
}

export async function downloadExcelSampleOfFamilyMemberReq(action) {

  return axiosInstance.get(`${apiEndpoints.DOWNLOAD_FAMILY_MEMBER_EXCEL_SAMPLE_API}?flat_id=${action.data.flatId}`, {
      responseType: 'arraybuffer',
  })
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if(errors.response?.data.statusCode === 501){
              notify('warning', errors.response.data.message);
          }
          return errors

      })
}

export async function getFlatDocumentsReq(action) {
  return axiosInstance.get(`${apiEndpoints.GET_FLAT_DOCUMENTS_API}?society_id=${action.data.societyId}&flat_id=${action.data.flatId}`)
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if(errors.response?.data.statusCode === 501){
              notify('warning', errors.response.data.message);
          }
          return errors

      })
}

export async function updateFlatDocumentsReq(action) {
  const BODY= {
    "society_id": action.data.societyId,
    "flat_id": parseInt(action.data.flatId),
    "flat_doc_id": action.data.flatDocId || 0,
    "noc": action.data.noc,
    "sale_deed": action.data.saleDeed,
    "rent_agreement": action.data.rentAgreement,
    "other_docs": action.data.documents?.map(item=>({
      "other_flat_doc_id": item.id,
      "title": item.title,
      "file": item.doc,
      "status": item.status
    }))
  }

  return axiosInstance.post(apiEndpoints.UPDATE_FLAT_DOCUMENTS_API, {...BODY})
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if (errors.response?.data.statusCode === 400) {
              notify('error', errors.response.data.message);
          }else if (errors.response.data.statusCode === 401) {
              notify('error', errors.response.data.message);
              logOut()
          } else {
              notify('warning', "Something went wrong");
          }
          return errors
      })
}

export async function getNomineeListReq(action) {
  return axiosInstance.get(`${apiEndpoints.VIEW_NOMINEE_DETAILS_API}?house_unique_id=${action.data.flatId}`)
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if(errors.response?.data.statusCode === 501){
              notify('warning', errors.response.data.message);
          }
          return errors

      })
}

export async function addUpdateNomineeReq(action) {
  const BODY= {
    "society_id": action.data.societyId,
    "house_unique_id": action.data.flatId,
    "nominee_data": action.data.nominees?.map((item)=>({
      "id" : item.id || 0,
      "full_name": item.name,
      "remarks": item.remarks,
      "address": item.address,
      "relationship": item.relationship,
      "share": parseFloat(item.share),
      "dob": item.dob,
      "date_of_nomination": item.dateOfNomination,
      "mc_meeting_date": item.dateOfMC,      
      "ip": action.data.ipAddress
    }))
  }

  return axiosInstance.post(apiEndpoints.ADD_UPDATE_NOMINEE_DETAILS_API, {...BODY})
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if (errors.response?.data.statusCode === 400) {
              notify('error', errors.response.data.message);
          }else if (errors.response.data.statusCode === 401) {
              notify('error', errors.response.data.message);
              logOut()
          } else {
              notify('warning', "Something went wrong");
          }
          return errors
      })
}

export async function deleteNomineeReq(action) {
  const BODY= {
    "id": action.data.id,
    "ip": action.data.ipAddress,
  }
  
  return axiosInstance.put(apiEndpoints.DELETE_NOMINEE_DETAILS_API, {...BODY})
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if (errors.response?.data.statusCode === 400) {
              notify('error', errors.response.data.message);
          }else if (errors.response.data.statusCode === 401) {
              notify('error', errors.response.data.message);
              logOut()
          } else {
              notify('warning', "Something went wrong");
          }
          return errors
      })
}

export async function getVehicleDetailsListReq(action) {
  return axiosInstance.get(`${apiEndpoints.GET_VEHICLE_DETAILS_API}?house_unique_id=${action.data.id}`)
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if(errors.response?.data.statusCode === 501){
              notify('warning', errors.response.data.message);
          }
          return errors

      })
    }


export async function updateVehicleDetailsReq(action) {
  const BODY= {
    "society_id": action.data.societyId,
    "house_unique_id": action.data.flatId,
    "vehicle_data": action.data.vehicles?.map((item)=>({
      "id" : item.id || 0,
      "parking_lot": parseInt(item?.stickerNo),
      "parking_type": item?.parkingType?.toString(),
      "parking_no": item?.parkingNo,
      "location" : item?.location,
      "vehicle_type" : item?.vehicleType,
      "vehicle_no" : item?.vehicleNo,
      "vehicle_brand" : item?.vehicleBrand,
      "vehicle_model" : item?.modal,
      "vehicle_reg_year" : item?.year,
      "rf_id" : item?.rfid,
    }))
  }

  return axiosInstance.post(apiEndpoints.ADD_UPDATE_VEHICLE_DETAILS_API, {...BODY})
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if (errors.response?.data.statusCode === 400) {
              notify('error', errors.response.data.message);
          }else if (errors.response.data.statusCode === 401) {
              notify('error', errors.response.data.message);
              logOut()
          } else {
              notify('warning', "Something went wrong");
          }
          return errors
      })
}

export async function deleteVehicleReq(action) {

  return axiosInstance
    .delete(`${apiEndpoints.DELETE_VEHICLE_DETAILS_API}/${action.data?.id}`)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}
