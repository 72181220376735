import React, { useEffect, useState } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import ButtonG from "../../../components/Button/ButtonG";
import Button from "../../../components/Button/Button";
import Layout from "../../Layout/Layout";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { addMoreHeadValidation } from "../../../validationSchema/setupRecordsSchema/invoiceSettingSchema";
import { MultiSelectFM } from "../../../components/MultiSelect/MultiSelect";
import {
  getGLAccountList,
  getInvoiceHeadList,
  addMoreHead,
} from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {CheckboxFM} from "../../../components/Checkbox/Checkbox";

const AddMoreHeads = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { typeId } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const [glCodes, setglCode] = useState([])
  const { loading, updateLoader, glCodeList, headlist } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  let types = [
    {
      value: "normal",
      label: "Normal",
    },
    {
      value: "utility",
      label: "Utility",
    },
    {
      value: "percentage",
      label: "Percentage",
    },
  ]
  let fotmulas = [
    {
      value: "Per Sq.Ft.",
      label: "Per Sq.Ft.",
    },
    {
      value: "Equally Divided",
      label: "Equally Divided",
    },
    {
      value: "Per Flat",
      label: "Per Flat",
    },
  ]
  let UtilityType = [
    {
      value: "selective",
      label: "Selective",
    },
    {
      value: "incremental",
      label: "Incremental",
    },
  ]
  let percentagePeriodType = [
    {
      value: "na",
      label: "Not Applicable",
    },
    {
      value: "day",
      label: "Days wise",
    },
    {
      value: "month",
      label: "Month wise",
    },
  ]
  useEffect(() => {
    dispatch(getGLAccountList({ societyId: loginData?.default_community?.community_id, typeId: typeId }))
    dispatch(getInvoiceHeadList({ societyId: loginData?.default_community?.community_id, typeId: typeId }))
  }, [dispatch, loginData, typeId]);
  useEffect(() => {
    if (glCodeList) {
      setglCode(glCodeList)
    }
  }, [glCodeList]);
  const handleSave = (values) => {
    console.log(values)
    dispatch(
      addMoreHead({
        societyId: loginData?.default_community?.community_id,
        typeId: typeId,
        headName: values.headName,
        headDetail: values.headDetail,
        glCode: values.glCode,
        hsnCode: values.hsnCode,
        type: values.type,
        amount: values.amount,
        formula: values.formula,
        tenancyPeriod: values.tenancyPeriod,
        unitRoundOff : values.unitRoundOff,
        showUnitDetail : values.showUnitDetail,
        onSuccessCallback: () => {
          navigate(-1);
        },
      })
    );
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            headName: '',
            headDetail: [],
            glCode: '',
            hsnCode: '',
            type: 'normal',
            amount: 0,
            formula: '',
            tenancyPeriod: 'na',
            unitRoundOff: 1,
            showUnitDetail: 1,
          }}
          enableReinitialize
          validationSchema={addMoreHeadValidation}
          onSubmit={handleSave}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack
                    title="Add More Heads"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <div className="flex gap-3">
                      <span className="border-l border-[#CCC]"></span>
                      <ButtonG label="Reset" type='reset' className="h-8" />
                      <Button
                        label={
                          <span className="inline-block w-12">
                            {updateLoader ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={17}
                              />
                            ) : ("Save")}
                          </span>
                        }
                        className="h-8"
                        type='submit'
                        disabled={loading || updateLoader}
                      />
                    </div>
                  </ListHeaderBack>
                </div>
                <div className="flex justify-between gap-4 p-2 mt-2">
                  {loading ?
                    <Loader />
                    : <div className="flex flex-col w-1/2 pr-5">
                      <TextInputFM
                        label="Head Name"
                        placeholder="Enter Head Name"
                        className="w-56 h-9"
                        name='headName'
                      />
                      <div className="flex justify-between">
                        <span className="text-sm mt-[7.8px]">GL Account Name</span>
                        <DropdownFM2
                          options={glCodes.map((detail) => {
                            return {
                              value: detail.ac_code,
                              label: detail.name
                            }
                          })}
                          value={formik.values.glCode ? {
                            label: glCodes?.find((detail) => detail.ac_code === formik.values.glCode).name,
                            value: formik.values.glCode
                          } : null}
                          onSelect={(selectedItem) => {
                            formik.setFieldValue('glCode', selectedItem.value)
                          }}
                          name="glCode"
                          placeholder="Select Gl Account"
                          width="14rem"
                          height="36px"
                        />
                      </div>
                      <TextInputFM
                        label="HSN Code"
                        placeholder="Enter HSN Code"
                        className="w-56 h-9"
                        name='hsnCode'
                        maxLength={20}
                      />
                      <div className="flex justify-between">
                        <span className="text-sm mt-[7.8px]">Head type</span>
                        <DropdownFM2
                          options={types.map((detail) => {
                            return {
                              value: detail.value,
                              label: detail.label
                            }
                          })}
                          value={formik.values.type ? {
                            label: types?.find((detail) => detail.value === formik.values.type).label,
                            value: formik.values.type
                          } : null}
                          onSelect={(selectedItem) => {
                            formik.setFieldValue('type', selectedItem.value)
                            formik.setFieldValue('formula', "")
                          }}
                          name="type"
                          placeholder="Select Gl Account"
                          width="14rem"
                          height="36px"
                        />
                      </div>
                      {formik.values.type === 'normal' ?
                        <TextInputFM
                          label="Amount"
                          type="decimal"
                          placeholder="Enter Amount"
                          className="w-56 h-9"
                          name='amount'
                          maxLength={20}
                        /> : <></>}
                      {formik.values.type === 'normal' ?
                        <div className="flex justify-between">
                          <span className="text-sm mt-[7.8px]">Formula</span>
                          <DropdownFM2
                            options={fotmulas.map((detail) => {
                              return {
                                value: detail.value,
                                label: detail.label
                              }
                            })}
                            value={formik.values.formula ? {
                              label: formik.values.formula,
                              value: formik.values.formula
                            } : null}
                            onSelect={(selectedItem) => {
                              formik.setFieldValue('formula', selectedItem.value)
                            }}
                            name="formula"
                            placeholder="Select Formula"
                            width="14rem"
                            height="36px"
                          />
                        </div>
                        : <></>}
                      {formik.values.type === 'utility' ?
                        <div className="flex justify-between">
                          <span className="text-sm mt-[7.8px]">Utility Type</span>
                          <DropdownFM2
                            options={UtilityType.map((detail) => {
                              return {
                                value: detail.value,
                                label: detail.label
                              }
                            })}
                            value={formik.values.formula ? {
                              label: UtilityType?.find((item) => item.value === formik.values.formula)?.label || "",
                              value: formik.values.formula,
                            } : null}
                            onSelect={(selectedItem) => {
                              formik.setFieldValue('formula', selectedItem.value)
                            }}
                            name="formula"
                            placeholder="Select Formula"
                            width="14rem"
                            height="36px"
                          />
                        </div>
                        : <></>}
                        {formik.values.type === 'utility' ?
                        <div className="flex justify-between">
                          <span className="text-sm mt-[7.8px]">Unit Round Off</span>
                          <CheckboxFM
                              text=""
                              className="w-[215px] justify-self-start mt-[7.8px]  text-xs"
                              initialchecked={formik.values.unitRoundOff===1?true:false}
                              onChange={(e) =>{
                                //console.log(formik.values.unitRoundOff)
                                formik.setFieldValue('unitRoundOff', e.target.checked ? 1 : 0)
                              }}
                              name="unitRoundOff"
                            />
                        </div>
                        : <></>}
                        {formik.values.type === 'utility' ?
                        <div className="flex justify-between">
                          <span className="text-sm mt-[7.8px]">Display Unit Details</span>
                          <CheckboxFM
                              text=""
                              className="w-[215px] justify-self-start mt-[7.8px]  text-xs"
                              initialchecked={formik.values.showUnitDetail===1?true:false}
                              onChange={(e) =>{
                                //console.log(formik.values.unitRoundOff)
                                formik.setFieldValue('showUnitDetail', e.target.checked ? 1 : 0)
                              }}
                              name="showUnitDetail"
                            />
                        </div>
                        : <></>}
                      {formik.values.type === 'percentage' ?
                        <div>
                          <div className="flex justify-between">
                            <span className="text-sm mt-[7.8px]">Heads</span>
                            <MultiSelectFM
                              name="headDetail"
                              options={
                                headlist?.map((item) => ({
                                  value: item?.key,
                                  label: item?.name,
                                })) || []
                              }
                              width="224px"
                              height={"fit-content"}
                              placeholder="Select Heads"
                              className="text-xs"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "headDetail",
                                  selectedItem.map((item) => item.value)
                                );
                              }}
                              isMulti={true}
                            />
                          </div>
                          {/* <div className="flex justify-between"> */}
                          {/* <span className="text-sm">Percentage</span> */}
                          <TextInputFM
                            label="Percentage"
                            type="decimal"
                            placeholder="Enter Percentage"
                            className="w-56 h-9"
                            name='amount'
                            maxLength={20}
                          />
                          
                          {/* </div> */}
                        </div>
                        : <></>}
                         {formik.values.type !== 'utility' ?
                        <div className="flex justify-between">
                            <span className="text-sm mt-[7.8px]">Tenancy Period</span>
                            <DropdownFM2
                              options={percentagePeriodType?.map((detail) => {
                                return {
                                  value: detail.value,
                                  label: detail.label
                                }
                              })}
                              value={formik.values.tenancyPeriod ? {
                                label: percentagePeriodType?.find((item) => item.value === formik.values.tenancyPeriod)?.label || "",
                                value: formik.values.tenancyPeriod,
                              } : null}
                              onSelect={(selectedItem) => {
                                formik.setFieldValue('tenancyPeriod', selectedItem.value)
                              }}
                              name="tenancyPeriod"
                              placeholder="Select Tenancy Period"
                              width="14rem"
                              height="36px"
                            />
                          </div>: <></>}
                    </div> 
                  }

                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default AddMoreHeads;
