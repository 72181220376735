import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../pages/Layout/Layout";
import { BankAuditData } from "../../../consts/BankDetailsAuditData";
import { Arrow } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import {getBankDetailsAuditLog} from '../../../redux/actions/SetupRecordsActions/BuildingActions/BankDetailsAction'
import Loader from "../../../components/Loader/Loader";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const Accordion = ({ label, isOpen, toggleAccordion, data, desc, descr}) => {

  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <button className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
            border rounded flex justify-center items-center">
            {isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            )}
          </button>
          <div className="text-[#000000] text-sm font-semibold">{label} {`(${data?.audit})`}</div>
        </div>
        <div>
            <div className="text-xs text-right text-[#888]">{desc}</div>
            <div className="text-xs text-right text-[#888]">{descr}</div>
          </div>
      </div>
      
      {isOpen && (
        <>
          <div className="flex justify-between w-full text-sm p-2 font-bold">
            <div className="w-1/2">{' '}</div>
            <div className="w-1/2">Old Value</div>
            <div className="w-1/2">New Value</div>
          </div>
          {
            BankAuditData?.[0]?.values.map((item, i0) => (
              <React.Fragment key={i0}>
                <div className="flex justify-between w-full text-sm p-2">
                  <div className="w-1/2">{item?.label}</div>
                  <div className="w-1/2">{data?.old_bank_details?.[item.key]?.toString() || '-'}</div>
                  <div className="w-1/2">{data?.new_bank_details?.[item.key]?.toString() || '-'}</div>
                </div>
              </React.Fragment>
            ))
          }
        </>
      )}
    </div>
  )
}

const BankAuditLogs = () => {
    const navigate = useNavigate();
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const dispatch = useDispatch()
    const {loginData} = useSelector(state=>state.loginReducer)
    const {loading, bankAuditLogsData} = useSelector(state=>state.bankDetailsReducer)

    const handleNavigation = (path) => () => {
      navigate(path);
    };
  
    const toggleAccordion = (index) => {
      setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    useEffect(()=>{
      const sendRequest={
        societyId: loginData?.default_community?.community_id,
      }
      dispatch(getBankDetailsAuditLog(sendRequest))
    }, [])
  
    return (
      <Layout>
        <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
          <div className="flex gap-5 pb-2">
            <div className="flex w-1/2">
              <button
                onClick={handleNavigation(-1)}
                className="text-sm p-2 text-red-650 font-semibold flex gap-2 justify-center items-center"
              >
                <span>
                  <Arrow />
                </span>
                Back
              </button>
              <span className="border-r border-[#CCCCCC]"></span>
              <h1 className="text-sm font-semibold p-2">Bank Audit Logs</h1>
            </div>
          
          </div>
          <div className="border-b border-[#CCCCCC]"></div>
          {
            loading && !bankAuditLogsData ?
            <Loader/>
            :
            <div>
              {bankAuditLogsData?.length > 0 ? (
                bankAuditLogsData?.map((audit, index)=>(
                  <Accordion
                    label={audit?.old_bank_details?.['Bank Account Name'] ? audit?.old_bank_details?.['Bank Account Name'] : audit?.new_bank_details?.['Bank Account Name']}
                    key={index}
                    data={audit}
                    isOpen={index === openAccordionIndex}
                    toggleAccordion={() => toggleAccordion(index)}
                    desc={`Modified By ${audit?.modified_by} On ${moment(new Date(audit?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
                    descr={`Ip Address: ${audit?.IpAddress}. Session id - ${audit?.modified_sessionId}`}
                  />
                ))
              ) : (
                  <div className="text-center py-4 text-[#999999] flex justify-center">No Audits</div>
                )}
            </div>
          }
        </div>
      </Layout>
    );
  };

export default BankAuditLogs;
