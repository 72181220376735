import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from '../../Layout/Layout';
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useDispatch } from "react-redux";
import { getFacilityAuditLogs } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import ImageComponent from "../../../components/ImageComponent/ImageComponent";
import { useNavigate } from "react-router-dom";
import { getFacilityBookingsAuditLogs } from "../../../redux/actions/ClubActions/BookAFacilityAction";

const AuditData = [
  { key: 'facilities_name', label: 'Facilities Name:	' },
  { key: 'invoice_number', label: 'Invoice Name:	' },
  { key: 'wing_flat', label: 'Wing Flat:	' },
  { key: 'booking_frequency', label: 'Booking Frequency:	' },
  { key: 'date_of_booking', label: 'Date of Booking:	' },
  { key: 'from_date', label: 'From Date:	' },
  { key: 'to_date', label: 'To Date:	' },
  { key: 'time_slot', label: 'Time Slot:	' },
  { key: 'no_of_booking', label: 'No of Booking:	' },
  { key: 'no_of_day', label: 'No of Day:' },
  { key: 'booking_amount', label: 'Booking Amount:' },
  { key: 'security_deposit', label: 'Security Deposit:' },
  { key: 'total_amount', label: 'Total Amount:' },
  { key: 'status', label: 'Status:' },
  { key: 'booking_purpose', label: 'Booking Purpose:' },
  { key: 'closedBy', label: 'Cancel By' },
  { key: 'cancel_reason', label: 'Cancel Reason' },
]


const Accordion = React.memo(
  ({ data, isOpen, toggleAccordion, label, descr, desc, children }) => {

    return (
      <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
        <div
          className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b "
          onClick={toggleAccordion}
        >
          <div className="flex items-center">
            <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
              {isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              )}
            </button>
            <div className="text-[#000000] text-sm font-semibold">{label}</div>
          </div>
          <div>
            <div className="text-xs text-right text-[#888]">{desc}</div>
            <div className="text-xs text-right text-[#888]">{descr}</div>
          </div>
        </div>
        {isOpen && (
          <>
            <div className="flex justify-between w-full text-sm p-2 font-bold">
              <div className="w-1/2">{' '}</div>
              <div className="w-1/2">Old Value</div>
              <div className="w-1/2">New Value</div>
            </div>
            {
              AuditData?.map((item, i0) => (
                <React.Fragment key={i0}>
                  <div className="flex justify-between w-full text-sm p-2">
                    <div className="w-1/2">{item?.label}</div>
                    {item.key !== "time_slot" ?
                      (item.key !== "date_of_booking" && item.key !== "from_date" && item.key !== "to_date") ?
                        <>
                          <div className="w-1/2">{data?.old_data?.[item.key]?.toString() || '-'}</div>
                          <div className="w-1/2">{data?.new_data?.[item.key]?.toString() || '-'}</div>
                        </>
                        :
                        <>
                          <div className="w-1/2">{data?.old_data?.[item.key] ? moment(data?.old_data?.[item.key]).format('DD-MM-YYYY') : "-"}</div>
                          <div className="w-1/2">{data?.new_data?.[item.key] ? moment(data?.new_data?.[item.key]).format('DD-MM-YYYY') : "-"}</div>
                        </>
                      :
                      <>
                        <div className="w-1/2">{data?.old_data?.[item.key] ? `${data?.old_data?.["time_slot"]} (${data?.old_data?.["facilities_type"]})` : "-"}</div>
                        <div className="w-1/2">{data?.new_data?.[item.key] ? `${data?.new_data?.["time_slot"]} (${data?.new_data?.["facilities_type"]})` : "-"}</div>
                      </>
                    }
                  </div>
                </React.Fragment>
              ))
            }
          </>
        )}
      </div>
    );
  }
);

const FacilityAuditAccordians = ({ data }) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const { facilityBookingsAudit } = useSelector(state => state.bookAFacilityReducer)

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      {facilityBookingsAudit?.map((item, index) => (
        <Accordion
          label={`Audit ${index + 1} (${item.audit})`}
          key={index}
          isOpen={index === openAccordionIndex}
          toggleAccordion={() => toggleAccordion(index)}
          desc={`On ${moment(new Date(item?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
          descr={`Ip Address: ${item?.IpAddress}. Session id - ${item?.modified_sessionId}`}
          data={item}
        />
      ))}
    </div>
  );
};



function FacilityAuditLogs() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading } = useSelector(state => state.viewBookingReducer)
  const { loginData } = useSelector(state => state.loginReducer)

  useEffect(() => {
    dispatch(getFacilityBookingsAuditLogs({ societyId: loginData?.default_community?.community_id }))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack onClick={() => navigate(-1)} title="Facility Bookings Audit">
          </ListHeaderBack>
        </div>
        <div>
          {
            loading ?
              <Loader />
              :
              <FacilityAuditAccordians />
          }
        </div>
      </div>
    </Layout>
  );
}

export default FacilityAuditLogs