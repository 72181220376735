import React, { useEffect, useRef, useState } from "react";
import Button from "../Button/Button";

function CaptureImage({
  image,
  setImage,
  className,
  className2,
  videoClassName,
  canvasClassName,
}) {
  const [buttonLabel, setButtonLabel] = useState("Take Photo");
  const [currentStream, setCurrentStream] = useState(null);
  const [camera, setCamera] = useState(false);
  const videoRef = useRef();
  const canvasRef = useRef(null);

  const startCamera = () => {
    if (!camera) {
      setImage("");
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (navigator.mediaDevices?.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            video: true,
          })
          .then(function (stream) {
            setCurrentStream(stream);
            setCamera(true);
            videoRef.current.srcObject = stream;
            videoRef.current.style.display = "block";
            setButtonLabel("Stop Camera");
          })
          .catch(function (err) {
            console.log(err);
          });
      }
      setImage("");
    } else {
      setCamera(false);
      currentStream.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setButtonLabel("Start Camera");
    }
  };

  const snapshot = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    setImage(canvasRef.current.toDataURL("image/jpeg"));
    if (camera) {
      setCamera(false);
      currentStream.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setButtonLabel("Start Camera");
    }
  };

  useEffect(() => {
    if (!image) {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");
      ctx?.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [image]);

  return (
    <div className={`flex h-[120px] ${className}`}>
      <div className={`relative me-4 ${className2}`}>
        <video
          autoplay="true"
          className={`rounded ${videoClassName}`}
          id="camera"
          width="170"
          height="127"
          ref={videoRef}
        />
        <canvas
          className={`border border-[#CCCCCC] rounded absolute top-0 ${canvasClassName}`}
          ref={canvasRef}
          width="169"
          height="126"
        ></canvas>
      </div>

      <div className="flex flex-col">
        <Button type="button" label={buttonLabel} onClick={startCamera} />
        {camera && (
          <Button
            type="button"
            label={"Click Photo"}
            className={"mt-2"}
            onClick={snapshot}
          />
        )}
      </div>
    </div>
  );
}

export default CaptureImage;
