import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TextInput, TextInputFM } from "../../../../components/InputFields/TextInput";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import { SOCIETY_STAFF_ENDPOINT } from "../../../../Routing/routes";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import ProfilePicture from "../../../../components/ProfilePicture/ProfilePicture";
import  { CaptureButtonFM } from "../../../../components/Button/CaptureButton";
import { UploadButtonFM } from "../../../../components/Button/UploadButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getSocietyStaffDetails, getSocietyStaffProfileList, getSocietyWingFlatList, updateSocietyStaff } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/StaffPersonnelAction";
import { Formik, Form, ErrorMessage } from "formik";
import { genderOptions, namePrefixOptions, societyStaffTypeOptions, societyStaffVehicleTypeOptions } from "../../../../consts/DropDownOptions";
import Loader from "../../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { EditSocietyStaffValidation } from "../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import moment from "moment";
import EditStaffBoxForm from "./EditStaffBoxForm";
import countries from "../../../../consts/countries";
import { DOMESTIC_STAFF_TYPE } from "../../../../consts/constant";
import { getOccupantDetails } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";

const EditSocietyStaff = ({type}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ipAddress } = useSelector(state => state.ipAddressReducer);
  const {id, subscribeId, userId, flatId } = useParams();

  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, staffProfileList, staffDetails} = useSelector(state=>state.staffPersonnelReducer)
  const occupantDetailLoading = useSelector((state) => state.occupantInfoReducer.loading);
  const occupantDetail = useSelector((state) => state.occupantInfoReducer.occupantDetail);

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = (values, {resetForm, initialValues}) => {

    dispatch(updateSocietyStaff({
      ...values, 
      ipAddress,
      sessionId: loginData?.session_id,
      societyId: loginData?.default_community?.community_id,
      staffId: staffDetails?.staff_details?.staff_id,
      staffLevelId: staffDetails?.staff_details?.staff_level_id,
      staffDocId: staffDetails?.staff_details?.staff_doc_id,
      onSuccessCallback: ()=>{
        navigate(-1)
      }
    }))
  };
  
  useEffect(()=>{
    const societyId= loginData?.default_community?.community_id
    dispatch(getSocietyStaffProfileList({societyId}))
    dispatch(getSocietyWingFlatList({societyId}))
    dispatch(getSocietyStaffDetails({staffId: id}))

    if(type===DOMESTIC_STAFF_TYPE)
    dispatch(
      getOccupantDetails({
        subscribeId: subscribeId,
        societyId: loginData?.default_community?.community_id,
        userType: loginData?.user_type,
        userId: userId,
      })
    );
  }, [])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
      <Formik
        initialValues={{
          prefix: staffDetails?.staff_details?.staff_name_prefix || '',
          firstName: staffDetails?.staff_details?.staff_fname || '',
          lastName: staffDetails?.staff_details?.staff_lname || '',
          profilePic: staffDetails?.staff_details?.profile_image || '',
          staffType: type===DOMESTIC_STAFF_TYPE ? 'Domestic' :  (staffDetails?.staff_details?.staff_type || 'Society'),
          staffProfileId: staffProfileList?.find(item=> item.title=== staffDetails?.staff_details?.staff_role)?.id || '',
          startValidity: staffDetails?.staff_details?.start_validity || '',
          endValidity: staffDetails?.staff_details?.end_validity || '',
          workPerDay: staffDetails?.staff_details?.per_day_hours || '',
          salary: staffDetails?.staff_details?.staff_salary || '',
          mobileCountryCode: staffDetails?.staff_details?.mobile_country_code,
          countryISO: countries?.find(item=> item.phonecode?.replace('+','') === staffDetails?.staff_details?.mobile_country_code)?.iso,
          countryId: countries?.find(item=> item.phonecode?.replace('+','') === staffDetails?.staff_details?.mobile_country_code)?.id,
          contact: staffDetails?.staff_details?.phone_number || '',
          email:   staffDetails?.staff_details?.staff_email || '',
          gender: staffDetails?.staff_details?.staff_gender || '',
          address: staffDetails?.staff_details?.staff_address || '',
          dob: staffDetails?.staff_details?.dob || '',
          vehicleType: staffDetails?.staff_details?.vehicle_type || '',
          vehicleNumber: staffDetails?.staff_details?.staff_vehicle_number || '',
          fingerPrintImg: staffDetails?.staff_details?.finger_print || '',
          adharCardNo: staffDetails?.staff_details?.uid_no || '',
          adharDoc: staffDetails?.staff_details?.uid_image || '',
          panCardNo: staffDetails?.staff_details?.pan_card_no || '',      
          panDoc: staffDetails?.staff_details?.pan_image || '',
          drivingLicenceNo: staffDetails?.staff_details?.driving_licence_no || '',
          drivingLicenceDoc: staffDetails?.staff_details?.driving_licence_image || '',
          rationNo: staffDetails?.staff_details?.ration_no || '',
          rationDoc: staffDetails?.staff_details?.ration_image || '',
          flatData: staffDetails?.staff_flat_details?.map(item=> ({
            staffFlatId: item?.staff_flat_id,
            flatId: item?.flatid,
            joinDate: item?.joining_date,
            status: item?.status,
            dateofResign: item?.resign_date,
            reason: item?.resign_reason || ''
          })) || []
        }}
        enableReinitialize
        onSubmit={handleSaveClick}
        validationSchema={EditSocietyStaffValidation}
        >    
          {
            ({values, dirty, setFieldValue})=>{
              return (
                <Form>
                  <div className="flex justify-between border-b border-[#CCC] pb-2">
                  <h1 className="text-base font-semibold p-1">{type===DOMESTIC_STAFF_TYPE ? `${occupantDetail?.flat_data?.find((item) => item.flat_id?.toString() === flatId)?.flat_no ? `${occupantDetail?.user_detail?.prefix ? occupantDetail?.user_detail?.prefix :''} ${occupantDetail?.user_detail?.f_name} ${occupantDetail?.user_detail?.m_name ?occupantDetail?.user_detail?.m_name : ''} ${occupantDetail?.user_detail?.l_name} (${occupantDetail?.flat_data?.find((item) => item.flat_id?.toString() === flatId)?.wing_no} - ${occupantDetail?.flat_data?.find((item) => item.flat_id?.toString() === flatId)?.flat_no}) >> Edit Staff`  : ''}` : "Edit Society Staff"}</h1>
                    <div className="flex gap-2">
                      <ButtonG
                        label="Cancel"
                        onClick={handleCancelClick}
                        className="h-8"
                        type='button'
                      />  
                      <Button
                        label={
                          <span className="w-12 inline-block">
                            {
                              updateLoader ?
                                <CircularProgress sx={{color: "white"}} size={17}/>
                                :
                                'Save'
                              }
                          </span>
                          }
                        className="px-5 h-8"
                        type='submit'
                        disabled={loading || updateLoader || !dirty}
                      />
                    </div>
                  </div>
                  {
                    (loading || occupantDetailLoading) && staffDetails?.staff_details?.staff_id?.toString() !== id ?
                    <Loader/>:
                    <>
                      {
                        // (occupantDetail?.flat_data?.find((item) => item.flat_id?.toString() === flatId)?.staff_data?.find(item=> item.id?.toString()===id) || type !==DOMESTIC_STAFF_TYPE) && staffDetails &&

                        (occupantDetail?.flat_data?.find((item) => item.flat_id?.toString() === flatId)?.flat_no || type !==DOMESTIC_STAFF_TYPE) && staffDetails &&
                        <>
                          <div className="flex gap-4 p-2">
                            <div className="flex flex-row justify-between w-full gap-7">
                              <div className="flex flex-col w-1/2">
                                <div className="flex justify-between text-sm">
                                  <span className="mt-2">Staff First Name</span>
                                  <div className="flex gap-2">
                                    <DropdownFM2
                                      options={namePrefixOptions}
                                      width={"73px"}
                                      className="text-xs"
                                      placeholder={"Select"}
                                      onSelect={(selectedItem)=>{
                                        setFieldValue(`prefix`, selectedItem.value)
                                      }}
                                      height='38px'
                                      name={`prefix`}
                                      {...(values.prefix) && {value: {
                                        label: values.prefix,
                                        value: values.prefix,
                                        }
                                      }}
                                    />
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter First Name"
                                      className="w-36"
                                      name={`firstName`}
                                      maxLength={20}
                                      />
                                  </div>
                                </div>
                                <TextInputFM
                                  label="Last Name"
                                  placeholder="Enter Last Name"
                                  className="w-56"
                                  name={`lastName`}
                                  maxLength={20}
                                />
                                <div className="flex justify-between">
                                  <span className="text-sm mt-2">Society Staff Type</span>
                                  <DropdownFM2
                                    options={societyStaffTypeOptions}
                                    width={"224px"}
                                    className="text-xs"
                                    placeholder={"Select Staff Type"}
                                    onSelect={(selectedItem)=>{
                                      setFieldValue(`staffType`, selectedItem.value)
                                      if(selectedItem.value==='Society')
                                        setFieldValue(`flatData`, [])
                                      else
                                        setFieldValue(`flatData`, staffDetails?.staff_flat_details?.map(item=> ({
                                          staffFlatId: item?.staff_flat_id,
                                          flatId: item?.flatid,
                                          joinDate: item?.joining_date,
                                          status: item?.status,
                                          dateofResign: item?.resign_date,
                                          reason: item?.resign_reason || ''
                                        })) || [])
                                    }}
                                    height='38px'
                                    name={`staffType`}
                                    value={
                                      values.staffType &&{
                                        label: values.staffType,
                                        value: values.staffType
                                      }
                                    }
                                    disabled={type===DOMESTIC_STAFF_TYPE}
                                  />
                                </div>
                                <div className="flex justify-between ">
                                  <span className="text-sm mt-2">Staff Profile</span>
                                  <DropdownFM2
                                    options={staffProfileList?.map((item)=>({
                                      value: item?.id,
                                      label: item?.title
                                    })) || []}
                                    width={"224px"}
                                    className="text-xs"
                                    placeholder={"Select Staff Profile"}
                                    onSelect={(selectedItem)=>{
                                      setFieldValue(`staffProfileId`, selectedItem.value)
                                    }}
                                    height='38px'
                                    name={`staffProfileId`}
                                    value={
                                      values.staffProfileId &&{
                                        label: staffProfileList?.find(item=> item.id=== values.staffProfileId)?.title,
                                        value: values.staffProfileId
                                      }
                                    }
                                  />
                                </div>
                                <div className="flex flex-row justify-between">
                                  <span className="text-sm mt-2">Staff ID Validity From</span>
                                  <div className="flex gap-4">
                                    <DatePickerComponentFM
                                      className="w-56 h-9 ml-4 justify-between"
                                      onDateChange={(selectedDate) => {
                                        setFieldValue('startValidity', selectedDate)
                                      }}
                                      name='startValidity'
                                      defaultValue={values.startValidity ? new Date(values.startValidity) : null}
                                      maxDate={new Date()}
                                    />
                                  </div>
                                </div>
                                <TextInputFM
                                  label="Working Hours per Day"
                                  placeholder="Enter count"
                                  className="w-56"
                                  name={`workPerDay`}
                                  type='number'
                                  maxLength={2}
                                />
                                <div className="flex justify-between">
                                  <span className="text-sm mt-2">Country</span>
                                  <DropdownFM2
                                    options={ countries?.map((item)=>({
                                      label: `${item?.name?.toUpperCase()} (${item?.phonecode})`,
                                      value: item?.phonecode?.replace("+",""),
                                      iso: item.iso,
                                      id: item?.id
                                    })) || []}
                                    width={"224px"}
                                    className="text-xs"
                                    placeholder={"Select Country"}
                                    onSelect={(selectedItem)=>{
                                      setFieldValue(`mobileCountryCode`, selectedItem.value)
                                      setFieldValue(`countryId`, selectedItem.id)
                                      setFieldValue(`countryISO`, selectedItem.iso)
                                    }}
                                    height='38px'
                                    name={`mobileCountryCode`}
                                    value={values.mobileCountryCode ? {
                                      label: `${countries?.find(item=> item.phonecode?.replace('+','') === values.mobileCountryCode)?.name?.toUpperCase()} (+${values.mobileCountryCode})`,
                                      value: values.mobileCountryCode,
                                      iso: values.countryISO,
                                      id: values.countryId
                                    }: null}
                                  />
                                </div>
                                <TextInputFM
                                  label="Staff Contact Number"
                                  placeholder="Enter contact number"
                                  className="w-56"
                                  name='contact'
                                  type='phone'
                                  maxLength={17}
                                />
                              <div className="flex justify-between">
                                <span className="text-sm mt-2">Gender</span>
                                  <DropdownFM2
                                    options={genderOptions}
                                    width={"224px"}
                                    className="text-xs"
                                    placeholder="Select Gender"
                                    onSelect={(selectedItem)=>{
                                      setFieldValue(`gender`, selectedItem.value)
                                    }}
                                    height='38px'
                                    name={`gender`}
                                    value={
                                      values.gender &&{
                                        label: genderOptions?.find(item=> item.value=== values.gender)?.label,
                                        value: values.gender
                                      }
                                    }                              
                                  />
                                </div>
                                <div className="flex flex-row justify-between">
                                  <span className="text-sm mt-2">Staff Date of Birth</span>
                                  <DatePickerComponentFM
                                    className="w-56 h-9 ml-4 justify-between"
                                    onDateChange={(selectedDate) => {
                                      setFieldValue('dob', moment(selectedDate)?.format('YYYY-MM-DD'))
                                    }}
                                    name='dob'
                                    defaultValue={validateDate(values.dob) ? new Date(values.dob) : null}
                                    maxDate={moment().subtract(18, 'years').toDate()}
                                  />
                                </div>
                                <div className="flex justify-between">
                                  <span className="text-sm mt-2">Vehicle Type</span>
                                  <DropdownFM2
                                    options={societyStaffVehicleTypeOptions}
                                    width={"224px"}
                                    className="text-xs"
                                    placeholder="Select Vehicle Type"
                                    onSelect={(selectedItem)=>{
                                      setFieldValue(`vehicleType`, selectedItem.value)
                                    }}
                                    height='38px'
                                    name={`vehicleType`}
                                    value={
                                      values.vehicleType &&{
                                        label: societyStaffVehicleTypeOptions?.find(item=> item.value === values.vehicleType)?.label,
                                        value: values.vehicleType
                                      }
                                    }                              
                                  />
                                </div>
                                <div className="h-4">{staffDetails?.staff_details?.fringer_print_url && <Link to={staffDetails?.staff_details?.fringer_print_url} className="underline text-blue-500 text-xs" target="_blank">Click here to View</Link>}</div>
                                <div className="flex justify-between w-full">
                                  <div className="text-sm w-1/2 mt-2">Staff Finger Image</div>
                                  <div className="w-56 mb-1">
                                    <CaptureButtonFM
                                      showFileName={true} 
                                      name={`fingerPrintImg`}
                                      accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                      onSelect={(e)=>setFieldValue(`fingerPrintImg`, e)} 
                                      fileName={values.fingerPrintImg?.name}
                                    />
                                  </div>
                                </div>
                                <TextInputFM
                                  label="Identity Proof Number"
                                  placeholder="Enter"
                                  className="w-56"
                                  name='adharCardNo'
                                  type='alphaNumber'
                                  maxLength={12}
                                />
                                <TextInputFM
                                  label="Pan Card"
                                  placeholder="Enter Pan Number"
                                  className="w-56"
                                  name='panCardNo'
                                  type='alphaNumber'
                                  maxLength={10}
                                />
                                <TextInputFM
                                  label="Driving Licence"
                                  placeholder="xxxx-xxxx-xxxx"
                                  className="w-56"
                                  name='drivingLicenceNo'
                                  type='alphaNumber'
                                  maxLength={10}
                                />
                                <TextInputFM
                                  label="Ration Card"
                                  placeholder="Enter Ration Number"
                                  className="w-56"
                                  name='rationNo'
                                  type='alphaNumber'
                                  maxLength={10}
                                />
                              </div>
                              <div className="flex flex-col w-1/2">
                                <div className="my-1.5">
                                  <ProfilePicture
                                    onSelect={(e)=>{setFieldValue('profilePic', e)}} 
                                    onDelete={(e)=>{setFieldValue('profilePic', '')}}
                                    defaultURL={staffDetails?.staff_details?.profile_image}
                                  />
                                  <div className="text-red-500 text-end text-xs italic h-4 pe-32">
                                    <ErrorMessage name='profilePic'/>
                                  </div>
                                </div>
                                <div className="flex flex-row justify-between  mt-[120px]">
                                  <span className="text-sm mt-2">Staff ID Validity To</span>
                                  <DatePickerComponentFM
                                    className="w-56 h-9 ml-4 justify-between"
                                    onDateChange={(selectedDate) => {
                                      setFieldValue('endValidity', selectedDate)
                                    }}
                                    name='endValidity'
                                    {...(values.startValidity && {minDate: new Date(moment(values.startValidity).add(1, 'days'))})}
                                    defaultValue={values.endValidity ? new Date(values.endValidity) : null}
                                    readOnly={!values.startValidity}
                                  />
                                </div>
                  
                                <TextInputFM
                                  label="Staff Salary"
                                  placeholder="Enter Amount"
                                  className="w-56"
                                  name='salary'
                                  type='number'
                                  maxLength={10}

                                />
                                <TextInputFM
                                  label="Staff Email ID"
                                  placeholder="Enter Email"
                                  className="w-56"
                                  name='email'

                                />
                                <div className="w-full">
                                  <TextAreaFM
                                    label="Address"
                                    placeholder="Enter Address"
                                    className={"w-56 h-[87px]"}
                                    name='address'
                                    maxLength={300}
                                  />
                                </div>
                                <TextInputFM
                                  label="Staff Vehicle Number"
                                  placeholder="Enter vehicle number"
                                  className="w-56"
                                  name='vehicleNumber'
                                  type='alphaNumber'
                                  maxLength={10}
                                />
                                <div className="flex justify-between mb-2 mt-[43px]">
                                  <span className="text-sm mt-2">Upload Identity Proof</span>
                                  <div className="w-56">
                                    <div className="h-4 -mt-4 mb-2">{staffDetails?.staff_details?.uid_image_url && <Link to={staffDetails?.staff_details?.uid_image_url} className="underline text-blue-500 text-xs" target="_blank">Click here to View</Link>}</div>
                                    <UploadButtonFM showFileName={true} 
                                      name={`adharDoc`}
                                      accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                      onSelect={(e)=>setFieldValue(`adharDoc`, e)} 
                                      fileName={values?.adharDoc?.name}
                                      />
                                  </div>
                                </div>
                                <div className="flex justify-between mb-2">
                                  <span className="text-sm mt-2">Upload PAN Card</span>
                                  <div className="w-56">
                                  <div className="h-4 -mt-4 mb-2">{staffDetails?.staff_details?.pan_image_url && <Link to={staffDetails?.staff_details?.pan_image_url} className="underline text-blue-500 text-xs" target="_blank">Click here to View</Link>}</div>
                                    <UploadButtonFM showFileName={true} 
                                      name={`panDoc`}
                                      accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                      onSelect={(e)=>setFieldValue(`panDoc`, e)} 
                                      fileName={values?.panDoc?.name}
                                      />
                                  </div>
                                </div>
                                <div className="flex justify-between mb-2">
                                  <span className="text-sm mt-2">Upload Driving Licence</span>
                                  <div className="w-56">
                                  <div className="h-4 -mt-4 mb-2">{staffDetails?.staff_details?.driving_licence_image_url && <Link to={staffDetails?.staff_details?.driving_licence_image_url} className="underline text-blue-500 text-xs" target="_blank">Click here to View</Link>}</div>
                                    <UploadButtonFM showFileName={true} 
                                      name={`drivingLicenceDoc`}
                                      accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                      onSelect={(e)=>setFieldValue(`drivingLicenceDoc`, e)} 
                                      fileName={values?.drivingLicenceDoc?.name}
                                      />
                                  </div>
                                </div>
                                <div className="flex justify-between mb-2">
                                  <span className="text-sm mt-2">Upload Ration Card</span>
                                  <div className="w-56">
                                    <div className="h-4 -mt-4 mb-2">{staffDetails?.staff_details?.ration_image_url && <Link to={staffDetails?.staff_details?.ration_image_url} className="underline text-blue-500 text-xs" target="_blank">Click here to View</Link>}</div>
                                    <UploadButtonFM showFileName={true} 
                                      name={`rationDoc`}
                                      accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                      onSelect={(e)=>setFieldValue(`rationDoc`, e)} 
                                      fileName={values?.rationDoc?.name}
                                      />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <EditStaffBoxForm values={values} type={type} setFieldValue={setFieldValue}/>
                        </>
                      }
                    </>
                  }
                </Form>
              )}
          }
        </Formik>
      </div>
    </Layout>
  );
};

export default EditSocietyStaff;
