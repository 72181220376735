import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getLoggedComplaintTicketDetails } from "../../redux/actions/DeskActions/DeskAction";
import Loader from "../Loader/Loader";
import { COMPLAINT_DASHBOARD_ENDPOINT } from "../../Routing/routes";
import parse from "html-react-parser"

const PrintComponent = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, complaintLoggedTicket } = useSelector(
    (state) => state.deskReducers
  );

  useEffect(() => {
    const afterprint = () => {
      // navigate(COMPLAINT_DASHBOARD_ENDPOINT);
    };
    dispatch(getLoggedComplaintTicketDetails({ ticketId: id }));
    window.addEventListener("afterprint", afterprint);
    return () => {
      window.removeEventListener("afterprint", afterprint);
    };
  }, []);

  useEffect(() => {
    if (complaintLoggedTicket) {
      window.print();
    } else {
      // alert("No Data Found");
      // navigate(COMPLAINT_DASHBOARD_ENDPOINT);
    }
  }, [complaintLoggedTicket]);

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="py-2 font-bold text-2xl text-center underline">
        <span>{loginData?.user_name}</span>
        <span>{loginData?.office_address}</span>
      </div>
      <hr />
      <table className="table-auto w-full flex justify-center">
        <tbody>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Ticket ID:
            </td>
            <td className="py-2">{complaintLoggedTicket?.ticket || ""}</td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Creation Date:
            </td>
            <td className="py-2">
              {complaintLoggedTicket?.creation_date.split(" ")[0] || ""}
            </td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Creation Time:
            </td>
            <td className="py-2">
              {complaintLoggedTicket?.creation_date.slice(11) || ""}
            </td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Status:
            </td>
            <td className="py-2">{complaintLoggedTicket?.status || ""}</td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Nature:
            </td>
            <td className="py-2">{complaintLoggedTicket?.nature}</td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Logged By:
            </td>
            <td className="py-2">{complaintLoggedTicket?.full_name || ""}</td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Flat No.:
            </td>
            <td className="py-2">{complaintLoggedTicket?.wing_flat || ""}</td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Category:
            </td>
            <td className="py-2">
              {complaintLoggedTicket?.complaint_category || ""}
            </td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Serviced By:
            </td>
            <td className="py-2">{complaintLoggedTicket?.serviced_by || ""}</td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Assigned To:
            </td>
            <td className="py-2">
              {complaintLoggedTicket?.assigned_to_name} {complaintLoggedTicket?.assigned_title_name ? `(${complaintLoggedTicket?.assigned_title_name})` : ""}
            </td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Subject:
            </td>
            <td className="py-2">{complaintLoggedTicket?.subject || ""}</td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Description:
            </td>
            <td className="py-2">{complaintLoggedTicket?.description ? parse(complaintLoggedTicket?.description) : ""}</td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Closed By:
            </td>
            <td className="py-2">{complaintLoggedTicket?.user_name || ""}</td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Attachments:
            </td>
            <td className="py-2">
              {complaintLoggedTicket?.attachments.length > 0 ?
                complaintLoggedTicket?.attachments.map(item =>
                  <tr>
                    <Link
                      target="_blank"
                      className="ms-1 underline text-xs text-blue-500"
                      to={item?.attachment_Url}
                    >
                      View Uploaded Doc
                    </Link>
                  </tr>
                ) : (
                  <span>-</span>
                )}
              {/* {complaintLoggedTicket?.attachments.length > 0 ? (
                <span>
                  <Link
                    target="_blank"
                    className="ms-1 underline text-xs text-blue-500"
                    to={complaintLoggedTicket.attachments[0].attachment_Url}
                  >
                    View Uploaded Doc
                  </Link>
                </span>
              ) : (
                "No"
              )} */}
            </td>
          </tr>
          <tr className="text-[#222] text-sm">
            <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
              Comment:
            </td>
            <td className="py-4 border-b-2">
              {complaintLoggedTicket?.comments &&
                complaintLoggedTicket?.comments?.length > 0 ? (
                <div className="grid grid-col-6 gap-3 w-full">
                  {complaintLoggedTicket?.comments?.map((comment, index) => (
                    <div key={index} className="py-2 ">
                      <p> User Name: {comment.comment_by} </p>
                      <p> Comment: {comment.comments || "-"} </p>
                      {/* <p> Type: {comment.type} </p> */}
                    </div>
                  ))}
                </div>
              ) : (
                "-"
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Print = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);

  // const getUsersPDF = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get("https://jsonplaceholder.typicode.com/users");
  //     setUserData(response.data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // };

  return (
    <div>
      <PrintComponent data={userData} />
      {/* <button onClick={getUsersPDF}>Generate PDF</button> */}
      <div>{loading ? "Loading..." : ""}</div>
    </div>
  );
};

export default Print;
