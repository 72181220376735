import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import {
  BILL_TARRIF_SETUP_ENDPOINT,
  MANAGE_FLATS_ENDPOINT,
  UTILITY_READING_ENDPOINT,
  WINGS_AND_FLATS_AUDIT_LOG_ENDPOINT,
} from "../../../Routing/routes";
import { DownloadExt } from "../../../assets";
import ButtonIco from "../../../components/Button/ButtonPrint";
import ListHeader from "../../../components/ListHeader/ListHeader";
import { FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addWing, deleteWing, downloadWingExcel, getWingsAndFlatDetails } from '../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction'
import { wingsAndFlatValidation } from "../../../validationSchema/setupRecordsSchema/buildingSchema";
import { downloadExcel } from "../../../utils/helpers/downloadMedia";
import Loader from '../../../components/Loader/Loader'
import * as permissions from '../../../Routing/permissions'
import { getPermission } from "../../../utils/helpers/getPermissions";
import { CircularProgress } from "@mui/material";
import DeleteModal from "../../../components/Modal/DeleteModal";

const WingsandFlats = () => {
  const navigate = useNavigate();
  const { loading, updateLoader, wingsData } = useSelector(state => state.wingsReducer)
  const { loginData } = useSelector(state => state.loginReducer)
  const { ipAddress } = useSelector(state => state.ipAddressReducer)
  const { downLoader } = useSelector(state => state.wingsDownloadExcelReducer)

  const dispatch = useDispatch()

  const handleAuditLogClick = () => {
    navigate(WINGS_AND_FLATS_AUDIT_LOG_ENDPOINT);
  };
  const handleManageFlatClick = (id) => {
    navigate(`${MANAGE_FLATS_ENDPOINT}/${id}`);
  };
  const handleBillTariffSetupClick = (id) => {
    navigate(`${BILL_TARRIF_SETUP_ENDPOINT}/${id}`);
  };
  const handleUploadReadingClick = (id) => {
    navigate(`${UTILITY_READING_ENDPOINT}/${id}`);
  };
  const handleDownloadExcel = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: 'wings_excel_sheet.xlsx' })
    }
    dispatch(downloadWingExcel({ societyId: loginData?.default_community?.community_id, onSucessCallback }))
  }

  const handleDelete = (arrayHelpers, index, wing) => {
    if (wing.wingId) {
      dispatch(deleteWing({
        wingId: wing.wingId,
        ipAddress,
        sessionId: loginData?.session_id,
        onSuccessCallback: () => {
          arrayHelpers.remove(index);
        }
      }))
    } else {
      arrayHelpers.remove(index);
    }
  }

  useEffect(() => {
    dispatch(getWingsAndFlatDetails())
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          enableReinitialize
          initialValues={{
            wings: wingsData ? wingsData?.map((item) => ({
              wingId: item?.wing_id,
              wingNo: item?.number,
              wingTitle: item?.title || '',
              numberOfFloors: item?.no_of_floors,
              numberOfUnits: item?.no_of_units || 0
            })) : []
          }}
          onSubmit={values =>
            dispatch(addWing({ ...values, ipAddress, societyId: loginData?.default_community?.community_id, sessionId: loginData?.session_id, onSucessCallback: () => { dispatch(getWingsAndFlatDetails()) } }))
          }
          validationSchema={wingsAndFlatValidation}
        >
          {
            ({ values, dirty }) => {
              return (
                <Form>
                  <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Wings Details">
                      <div className="flex gap-2">
                        {downLoader ?
                          <ButtonIco className=" w-32 justify-center" children={<CircularProgress sx={{ color: "#d32f2f" }} size={17} />} />
                          :
                          <ButtonIco icon={DownloadExt} children='All Wing Flat Download Excel' onClick={handleDownloadExcel} />
                        }
                        {
                          getPermission(loginData, [permissions.VIEW_WINGS_AUDIT_PERMISSION])
                          &&
                          <Button
                            label="Audit Log"
                            onClick={handleAuditLogClick}
                            className="px-4 font-semibold h-8"
                            type='button'
                          />
                        }
                        <div className="border-r"></div>
                        {
                          getPermission(loginData, [permissions.ADD_WINGS_PERMISSION])
                          &&
                          <Button
                            label={
                              <span className="w-8 inline-block">
                                {
                                  updateLoader ?
                                    <CircularProgress sx={{ color: "white" }} size={17} />
                                    :
                                    'Save'
                                }
                              </span>
                            }
                            className="px-4 font-semibold h-8"
                            type='submit'
                            disabled={loading || updateLoader || !dirty}
                          />
                        }
                      </div>
                    </ListHeader>
                  </div>
                  <div className="text-sm text-[#AAA] p-2 -mb-1">
                    <p className="font-semibold flex gap-1">
                      Note:
                      <span className="font-normal">
                        Please add Flat No. Wing wise. Leave Wing title field
                        empty if your society has no Wings.
                      </span>
                    </p>
                  </div>
                  {
                    loading && !wingsData
                      ?
                      <Loader />
                      :
                      <FieldArray
                        name="wings"
                        render={arrayHelpers => (
                          <div className="p-2 flex flex-wrap gap-4 items-center">
                            {values.wings && values.wings.length > 0 && (
                              values.wings.map((wing, index) => (
                                <WingBox
                                  key={index}
                                  index={index}
                                  handleManageFlatClick={handleManageFlatClick}
                                  handleBillTariffSetupClick={handleBillTariffSetupClick}
                                  handleUploadReadingClick={handleUploadReadingClick}
                                  onDelete={() => { handleDelete(arrayHelpers, index, wing) }}
                                  values={values}
                                />
                              ))
                            )}
                            {
                              getPermission(loginData, [permissions.ADD_WINGS_PERMISSION])
                              &&
                              <button
                                type='button'
                                className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-[49%] h-[155px] p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center"
                                onClick={() =>
                                  arrayHelpers.push({
                                    wingNo: '',
                                    wingTitle: '',
                                    numberOfFloors: '',
                                    numberOfUnits: 0,
                                  })
                                }
                              >
                                Add More Wing
                              </button>
                            }
                          </div>
                        )}
                      />
                  }
                </Form>
              )
            }
          }

        </Formik>
      </div>
    </Layout>
  );
};

const WingBox = ({
  values,
  index,
  handleManageFlatClick,
  handleBillTariffSetupClick,
  handleUploadReadingClick,
  onDelete
}) => {
  const { loginData } = useSelector(state => state.loginReducer)
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div className="border border-[#CCC] overflow-hidden rounded-lg w-[49%] h-[315px]">
      <h2 className="border-b p-3 text-sm font-semibold bg-[#FDF2F3] flex justify-between items-center">
        <span>S No.: {(index + 1)?.toString()?.padStart(2, 0)}</span>
        {
          getPermission(loginData, [permissions.DELETE_WINGS_PERMISSION]) &&
          <button
            className="text-red-650 font-semibold"
            onClick={() => setShowDeleteModal(true)}
            type='button'
          >
            <span className="mr-2">
              <FontAwesomeIcon icon={faTrashCan} />
            </span>
            Delete
          </button>
        }
      </h2>
      <div className="p-3">
        <TextInputFM
          label="Wing Number:"
          placeholder="Enter Wing Number"
          className="w-56 h-9"
          name={`wings.${index}.wingNo`}
          maxLength={25}
        />
        <TextInputFM
          label="Wing Title:"
          placeholder="Enter Title"
          className="w-56 h-9"
          name={`wings.${index}.wingTitle`}
          maxLength={50}
        />
        <TextInputFM
          label="Total Number Of Floors:"
          placeholder="Enter Number"
          className="w-56 text-xs h-9"
          name={`wings.${index}.numberOfFloors`}
          type={'number'}
          maxLength={3}
        />

        <div className="text-sm flex items-center justify-between pt-2.5 mb-1">
          <div>Total Number Of Units:</div>
          <div className="w-56 pl-2 text-xs">{values.wings[index].numberOfUnits}</div>
        </div>
        {
          !values.wings[index].wingId ?
            <span className="text-[#AAAAAA] font-bold text-[13px] h-8">
              Note : Leave Wing title field empty, if your society has no Wings.
            </span>
            :
            <div className="flex gap-3 pb-3 mt-4 justify-between">
              <Button
                label="Manage Flat"
                onClick={() => { handleManageFlatClick(values.wings[index].wingId) }}
                className="w-[112px] h-8"
                type='button'
              />
              {
                getPermission(loginData, [permissions.VIEW_BILL_TARIFF_PERMISSION]) &&
                <Button
                  label="Bill Tariff Setup"
                  onClick={() => { handleBillTariffSetupClick(values.wings[index].wingId) }}
                  className="w-[135px] h-8"
                  type='button'
                />
              }
              {
                getPermission(loginData, [permissions.VIEW_UTILITY_READING_PERMISSION]) &&
                <Button
                  label="Upload Utility Reading"
                  onClick={() => { handleUploadReadingClick(values.wings[index].wingId) }}
                  className="w-[185px] h-8"
                  type='button'
                />
              }
            </div>
        }

      </div>
      <DeleteModal
        isOpen={showDeleteModal}
        title={'Delete Wing'}
        text={'Do you really want to delete this wing?'}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={onDelete}
      />
    </div>
  );
};

export default WingsandFlats;
