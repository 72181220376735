import React, { useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { TextAreaFM } from "../../components/InputFields/TextArea";
import Button from "../../components/Button/Button";
import { Form, Formik } from "formik";
import { SendMessageValidation } from "../../validationSchema/ClubSchema/BookAFacilitySchema";
import { TextInputFM } from "../InputFields/TextInput";
import { useSelector } from "react-redux";
import QRCode from "qrcode.react";
import Modal from "react-modal";

import ButtonG from "../Button/ButtonG";
import zIndex from "@mui/material/styles/zIndex";
const customStyles = {
  content: {
    width: "auto",
    height: "200px",
    minHeight: "fit-content",
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    overflow: "visible",
    zIndex: 999,
  },
};
const QrCodeGenerateModal = ({ qrValue = null, isOpen = false, onClose }) => {
  const qrCodeRef = useRef();
  const downloadQRCode = () => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current.querySelector("canvas");
      const imageURL = canvas.toDataURL("image/png");
      // Create an anchor element and trigger a download
      const link = document.createElement("a");
      link.href = imageURL;
      link.download = "qrcode.png";
      link.click();
      link.remove();
    }
  };
  // Function to handle the copy action
  const handleCopy = () => {
    // Using the Clipboard API to copy the text
    navigator.clipboard
      .writeText(qrValue)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        alert("Failed to copy text: " + err);
      });
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="bg-white rounded-lg w-96 max-w-[300px] px-2  ">
        <div className="flex justify-between  gap-8 items-start ">
          <h2 className="p-2 text-lg font-semibold items-center text-center  ">
            Scan QR Code
          </h2>
          <button
            onClick={onClose}
            className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
          >
            <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
          </button>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div
          ref={qrCodeRef}
          className="text-center flex flex-wrap justify-center my-5"
        >
          {qrValue ? (
            <div>
              <QRCode value={qrValue} size={250} />
              <div className="mt-2">
                <input
                  type="text"
                  value={qrValue}
                  placeholder="Copy Link"
                  className="w-full p-1"
                  disabled
                />
              </div>

              <button
                className="text-red-650 text-sm text-center "
                onClick={handleCopy}
              >
                Copy Link
              </button>
            </div>
          ) : (
            "QR not available"
          )}
        </div>
        <div className="text-center">
          <Button
            label={"Download QR Code"}
            onClick={downloadQRCode}
            className="py-2 font-semibold h-9"
            type="button"
          />
        </div>
      </div>
    </Modal>
  );
};

export default QrCodeGenerateModal;
