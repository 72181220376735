import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { getSocietyStaffAuditLogs } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/StaffPersonnelAction";

const StaffAuditData = [
  { key: 'staff_fname', label: 'Staff Name:	' },
  // { key: 'staff_lname', label: 'Staff Name:	' },
  { key: 'emp_code', label: 'EMP Id:	' },
  { key: 'staff_code', label: 'Code:	' },
  { key: 'staff_gender', label: 'Gender:	' },
  { key: 'dob', label: 'DOB:	' },
  { key: 'staff_email', label: 'Email:	' },
  { key: 'phone_number', label: 'Contact:	' },
  { key: 'vehicle_type', label: 'Vehicle Type:' },
  { key: 'staff_vehicle_number', label: 'Vehicle Number:' },
  { key: 'staff_address', label: 'Address:' },
  { key: 'staff_type', label: 'Type:' },
  { key: 'staff_salary', label: 'Salary:' },
  { key: 'per_day_hours', label: 'Working Hours per Day:' },
  { key: 'start_validity', label: 'ID Validity Start:' },
  { key: 'end_validity', label: 'ID Validity End:' },
  { key: 'approval_status', label: 'Status:' },
  { key: 'approval_comments', label: 'Comments:' },
  { key: 'approved_by', label: 'Approved By:' },
  { key: 'pan_card_no', label: 'Pan No.:' },
  { key: 'uid_no', label: 'Identity Proof No.:' },
  { key: 'driving_licence_no', label: 'Driving Licence.:' },
  { key: 'ration_no', label: 'Ration No.:' },
]

const StaffFlatAuditData = [
  { key: 'wing_flat', label: 'Flat No.:' },
  { key: 'joining_date', label: 'Join Date:' },
  { key: 'status', label: 'Status:' },
]


const Accordion = React.memo(
  ({ data, isOpen, toggleAccordion, label, descr, desc, children }) => {
    const oldStaffDetails = data?.old_data?.flat_details
    const newStaffDetails = data?.new_data?.flat_details
    const flatData = oldStaffDetails?.length > newStaffDetails?.length ? oldStaffDetails : newStaffDetails

    const formatDate = (date) => (date ? moment(date).format("DD-MM-YYYY") : "-");

    const getGender = (gender) =>
      gender === "M" ? "Male" : gender === "F" ? "Female" : gender === "O" ? "Other" : "-";

    const getStatusLabel = (status) =>
      status === "A" ? "Working" : status === "D" ? "Not Working" : status === "I" ? "Leave" : "-";

    const renderRow = (label, oldValue, newValue) => (
      <div className="flex justify-between w-full text-sm p-2">
        <div className="w-1/2">{label}</div>
        <div className="w-1/2">{oldValue || "-"}</div>
        <div className="w-1/2">{newValue || "-"}</div>
      </div>
    );

    return (
      <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
        <div
          className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b "
          onClick={toggleAccordion}
        >
          <div className="flex items-center">
            <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
              {isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              )}
            </button>
            <div className="text-[#000000] text-sm font-semibold">{label}</div>
          </div>
          <div>
            <div className="text-xs text-right text-[#888]">{desc}</div>
            <div className="text-xs text-right text-[#888]">{descr}</div>
          </div>
        </div>
        {isOpen && (
          <>
            <div className="flex justify-between w-full text-sm p-2 font-bold">
              <div className="w-1/2">{' '}</div>
              <div className="w-1/2">Old Value</div>
              <div className="w-1/2">New Value</div>
            </div>
            {
              StaffAuditData?.map((item, i0) => {
                const oldValue = item.key === "start_validity" || item.key === "end_validity"
                  ? formatDate(data?.old_data?.staff_details?.[item.key])
                  : item.key === "staff_fname"
                    ? `${data?.old_data?.staff_details?.staff_name_prefix || ""} ${data?.old_data?.staff_details?.staff_fname || ""} ${data?.old_data?.staff_details?.staff_lname || ""}`
                    : item.key === "staff_gender"
                      ? getGender(data?.old_data?.staff_details?.[item.key])
                      : data?.old_data?.staff_details?.[item.key]?.toString()

                const newValue = item.key === "start_validity" || item.key === "end_validity"
                  ? formatDate(data?.new_data?.staff_details?.[item.key])
                  : item.key === "staff_fname"
                    ? `${data?.new_data?.staff_details?.staff_name_prefix || ""} ${data?.new_data?.staff_details?.staff_fname || ""} ${data?.new_data?.staff_details?.staff_lname || ""}`
                    : item.key === "staff_gender"
                      ? getGender(data?.new_data?.staff_details?.[item.key])
                      : data?.new_data?.staff_details?.[item.key]?.toString()

                return <React.Fragment key={i0}>
                  {renderRow(item.label, oldValue, newValue)}
                </React.Fragment>
              })
            }
            {
              flatData?.map((_, i0) =>
                <React.Fragment key={i0}>
                  {StaffFlatAuditData?.map((item, i1) => {
                    const oldValue = item.key === "joining_date"
                      ? formatDate(data?.old_data?.flat_details?.[i0]?.[item.key])
                      : item.key === "status"
                        ? getStatusLabel(data?.old_data?.flat_details?.[i0]?.[item.key])
                        : data?.old_data?.flat_details?.[i0]?.[item.key]?.toString();

                    const newValue = item.key === "joining_date"
                      ? formatDate(data?.new_data?.flat_details?.[i0]?.[item.key])
                      : item.key === "status"
                        ? getStatusLabel(data?.new_data?.flat_details?.[i0]?.[item.key])
                        : data?.new_data?.flat_details?.[i0]?.[item.key]?.toString();

                    return (
                      <React.Fragment key={item.key}>
                        {renderRow(item.label, oldValue, newValue)}
                      </React.Fragment>
                    )
                  })}
                </React.Fragment>
              )
            }
          </>
        )}
      </div>
    );
  }
);

const FacilityAuditAccordians = ({ data }) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const { societyStaffAuditLogs } = useSelector(state => state.staffPersonnelReducer)

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      {societyStaffAuditLogs?.map((item, index) => (
        <Accordion
          label={`Audit ${index + 1} (${item.audit})`}
          key={index}
          isOpen={index === openAccordionIndex}
          toggleAccordion={() => toggleAccordion(index)}
          desc={`On ${moment(new Date(item?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
          descr={`Ip Address: ${item?.IpAddress}. Session id - ${item?.modified_sessionId}`}
          data={item}
        />
      ))}
    </div>
  );
};



function SocietyStaffAuditLogs() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading } = useSelector(state => state.staffPersonnelReducer)
  const { loginData } = useSelector(state => state.loginReducer)


  useEffect(() => {
    dispatch(getSocietyStaffAuditLogs({ societyId: loginData?.default_community?.community_id }))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack onClick={() => navigate(-1)} title="Society Staff Audit">
          </ListHeaderBack>
        </div>
        <div>
          {
            loading ?
              <Loader />
              :
              <FacilityAuditAccordians />
          }
        </div>
      </div>
    </Layout>
  );
}

export default SocietyStaffAuditLogs;