import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SOCIETY_PROFILE_ENDPOINT, SPECIFIC_DETAILS_ENDPOINT } from "../../../Routing/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../Layout/Layout";
import { SocietyProfileAuditData } from "../../../consts/SocietyProfileAuditData";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useDispatch, useSelector } from "react-redux";
import { getSocietyProfileAuditLogs } from '../../../redux/actions/SetupRecordsActions/BuildingActions/SocietyProfileAction'
import moment from "moment";
import Loader from '../../../components/Loader/Loader'
import ImageComponent from "../../../components/ImageComponent/ImageComponent";
import { getSpecificDetailsAuditLogs } from "../../../redux/actions/SetupRecordsActions/BuildingActions/SpecificDetailsAction";

export const BankAuditData = [
    {
        label: "",
        values: [
            { key: "Registration Number", label: "Registration Number", value: "D-Block" },
            { key: "Date Of Registration", label: "Date Of Registration", value: "" },
            { key: "Act", label: "Act", value: "" },
            { key: "Total Built up Area", label: "Total Built up Area", value: "8" },
            { key: "Area of Plot", label: "Area of Plot", value: "8" },
            { key: "Construction Cost Per Unit", label: "Construction Cost Per Unit", value: "8" },
            { key: "Construction Unit", label: "Construction Unit", value: "8" },
            { key: "Value Per Share", label: "Value Per Share", value: "8" },
            { key: "Total number of Water Inlets", label: "Total number of Water Inlets", value: "8" },
        ],
    },
];

const Accordion = React.memo(
    ({ data, isOpen, toggleAccordion, label, descr, desc }) => {

        return (
            <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
                <div
                    className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b "
                    onClick={toggleAccordion}
                >
                    <div className="flex items-center">
                        <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
                            {isOpen ? (
                                <FontAwesomeIcon icon={faChevronUp} size="xs" />
                            ) : (
                                <FontAwesomeIcon icon={faChevronDown} size="xs" />
                            )}
                        </button>
                        <div className="text-[#000000] text-sm font-semibold">{label}</div>
                    </div>
                    <div>
                        <div className="text-xs text-right text-[#888]">{desc}</div>
                        <div className="text-xs text-right text-[#888]">{descr}</div>
                    </div>
                </div>
                {isOpen && (
                    <>
                        <div className="flex justify-between w-full text-sm p-2 font-bold">
                            <div className="w-1/2">{' '}</div>
                            <div className="w-1/2">Old Value</div>
                            <div className="w-1/2">New Value</div>
                        </div>
                        {
                            BankAuditData?.[0]?.values.map((item, i0) => (
                                <React.Fragment key={i0}>
                                    <div className="flex justify-between w-full text-sm p-2">
                                        <div className="w-1/2">{item?.label}</div>
                                        {item.key == "Date Of Registration" ?
                                            <>
                                                <div className="w-1/2">{data?.old_specific_details?.[0]?.[item.key] ? moment(data?.old_specific_details?.[0]?.[item.key]).format('DD-MM-YYYY') : "-"}</div>
                                                <div className="w-1/2">{data?.new_specific_details?.[0]?.[item.key] ? moment(data?.new_specific_details?.[0]?.[item.key]).format('DD-MM-YYYY') : "-"}</div>
                                            </>
                                            :
                                            <>
                                                <div className="w-1/2">{data?.old_specific_details?.[0]?.[item.key]?.toString() || '-'}</div>
                                                <div className="w-1/2">{data?.new_specific_details?.[0]?.[item.key]?.toString() || '-'}</div>
                                            </>
                                        }
                                    </div>
                                </React.Fragment>
                            ))
                        }
                    </>
                )}
            </div>
        );
    }
);

const SpecificDetailsAuditLog = () => {
    const navigate = useNavigate();
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const dispatch = useDispatch()
    const { loginData } = useSelector(state => state.loginReducer)
    const { loading, specificDetailsAuditLogData } = useSelector(state => state.specificDetailsReducer)
    const handleNavigation = (path) => () => {
        navigate(path);
    };

    const toggleAccordion = (index) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        const sendRequest = {
            societyId: loginData?.default_community?.community_id,
        }
        dispatch(getSpecificDetailsAuditLogs(sendRequest))
    }, [])

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <ListHeaderBack
                    onClick={handleNavigation(SPECIFIC_DETAILS_ENDPOINT)}
                    title="Specific Details Audit Logs"
                ></ListHeaderBack>
                <div className="border-b border-[#CCCCCC]"></div>
                <div>

                    {
                        loading && !specificDetailsAuditLogData ?
                            <Loader />
                            :
                            specificDetailsAuditLogData &&
                            specificDetailsAuditLogData?.map((item, index) => {
                                return (
                                    <>
                                        <Accordion
                                            label={`Audit ${index + 1}`}
                                            key={index}
                                            data={specificDetailsAuditLogData?.[index]}
                                            isOpen={index === openAccordionIndex}
                                            toggleAccordion={() => toggleAccordion(index)}
                                            desc={`Modified By ${item?.modified_by} On ${moment(new Date(item?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
                                            descr={`Ip Address: ${item?.IpAddress}. Session id - ${item?.modified_sessionId}`}
                                        />
                                    </>
                                )
                            })
                    }
                </div>
            </div>
        </Layout>
    );
};

export default SpecificDetailsAuditLog;
