import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./Table.css";
import { SortIcon } from "../../assets";
import styled from "@emotion/styled";
import {
  stableSort,
  getComparator,
} from "../../utils/helpers/universalFunctions";


  const TableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #ffffff;
    }
    // &:nth-of-type(even) {
    //   background-color: #f4f5f8;
    // }
    &:nth-of-type(odd):hover {
      background-color: #ffffff;
    }
    // &:nth-of-type(even):hover {
    //   background-color: #f4f5f8;
    // }
  `;
  
const CreditNoteandAdvanceTable = ({
  children,
  data,
  columns,
  checkbox = true,
  pagination = true,
  setAnyChecked,
  creditkey,
  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  setSelected,
  selected,
  getTableData,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);



  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, row) => {
    let newSelected = []
    if(Object.keys(selected).length === 0){
      newSelected =  [row.id]
    }else{
      newSelected = Object.values(selected).includes(row.id) 
      ? Object.values(selected).filter((selectedId) => selectedId !== row.id)
      : [...selected, row.id];
    }
    console.log(newSelected);
    setSelected(newSelected);
  };

  const isSelected = (id) => Object.values(selected).includes(id);
  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.id) : [];
    setSelected(newSelected);
  };
  // const handleCheck = (row,target) =>{
  //   console.log(row.id)
  //  const newSelected = target ? Object.values(selected).filter((selectedId) => selectedId !== row.id) : [...selected, row.id];
  //  setSelected(newSelected);
  // } 

  return (
    <Box
      sx={{ width: "100%", height: "67%", fontSize: "13px" }}
      className="table-component"
    >
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: "200px"}}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCell
                  size="small"
                  sx={{ py: 1.5, pl: 0, pr: 2, zIndex: 0, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    //checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  size="small"
                  key={column.id}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    pl: 0,
                    pr: 1,
                    fontWeight: 600,
                    zIndex: 0,
                    color: "#AAAAAA",
                    wordWrap: "break-word",
                    fontSize: "13px",
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length &&
              stableSort(data, getComparator(order, orderBy)).map(
                (row, rowIndex) => {
                  //console.log(selected);
                  var isRowSelected = isSelected(row.id);
                   //console.log(selected,"creditkey");
                   //var isRowSelected = true;
                  // if(creditkey.includes(row.id)){
                  //   var isRowSelected = true
                  //   //handleClick(row.id)
                  //   //handleCheckedData(row.id)(true)
                  // }
                  return (
                    <TableRowStyled
                      key={row.id}
                      role="checkbox"
                      aria-checked={isRowSelected}
                      tabIndex={-1}
                      selected={isRowSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      {!!checkbox && (
                        <TableCell
                          size="small"
                          sx={{
                            borderBottom: "none",
                            fontSize: "13px",
                            py: 1.5,
                            pl: 0,
                            pr: 1,
                            zIndex: 0,
                          }}
                          //onClick={(event) => handleClick(event, row.id)}
                        >
                          <Checkbox
                            // initialchecked={checkedInputData(row.id)}
                            onChange={(e) =>
                              handleClick(e.target.value,row)
                            }
                            size="small"
                            color="primary"
                            checked={isRowSelected}
                            inputProps={{
                              "aria-labelledby": `generic-table-checkbox-${row.id}`,
                            }}
                            sx={{
                              p: 0,
                              color: "#AAAAA",
                              "&.Mui-checked": {
                                color: "#CF001C",
                              },
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell
                        size="small"
                        //key={row.id}
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {row.date}
                      </TableCell>

                      <TableCell
                        size="small"
                        //key={row.id}
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                        }}
                      >
                       {row.debitName}
                      </TableCell>
                      <TableCell
                        size="small"
                        //key={row.id}
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                        }}
                      >
                       {row.creditName}
                      </TableCell>
                      <TableCell
                        size="small"
                        key={row.id}
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                        }}
                      >
                       {row.amount}
                      </TableCell>
                    </TableRowStyled>
                  );
                }
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

CreditNoteandAdvanceTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default CreditNoteandAdvanceTable;
