import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NOTICE_AND_CIRCULARS_ENDPOINT } from "../../../Routing/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from '../../../components/Loader/Loader'
import { getNoticeCircularAuditLogs } from "../../../redux/actions/SocialAndCommunicationActions/NoticeAndCircularAction";
import parse from "html-react-parser";

const AuditData = [
    { key: 'title', label: 'Subject:	' },
    { key: 'type', label: 'Type:	' },
    { key: 'description', label: 'Description:	' },
    { key: 'createdBy', label: 'Created By:	' },
    { key: 'date', label: 'Created On:	' },
    { key: 'ref_no', label: 'Reference No:	' },
    { key: 'notice_date', label: 'Notice Date:	' },
    { key: 'expiry_date', label: 'Expiry Date:	' },
    { key: 'email_send_arr', label: 'Email Notification Send To:	' },
]

const Accordion = React.memo(
    ({ data, isOpen, toggleAccordion, label, descr, desc, children }) => {

        return (
            <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
                <div
                    className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b "
                    onClick={toggleAccordion}
                >
                    <div className="flex items-center">
                        <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
                            {isOpen ? (
                                <FontAwesomeIcon icon={faChevronUp} size="xs" />
                            ) : (
                                <FontAwesomeIcon icon={faChevronDown} size="xs" />
                            )}
                        </button>
                        <div className="text-[#000000] text-sm font-semibold">{label}</div>
                    </div>
                    <div>
                        <div className="text-xs text-right text-[#888]">{desc}</div>
                        <div className="text-xs text-right text-[#888]">{descr}</div>
                    </div>
                </div>
                {isOpen && (
                    <>
                        <div className="flex justify-between w-full text-sm p-2 font-bold">
                            <div className="w-1/2">{' '}</div>
                            <div className="w-1/2">Old Value</div>
                            <div className="w-1/2">New Value</div>
                        </div>
                        {
                            AuditData?.map((item, i0) => (
                                <React.Fragment key={i0}>
                                    <div className="flex justify-between w-full text-sm p-2">
                                        <div className="w-1/2">{item?.label}</div>
                                        {(item.key !== "email_send_arr") ?
                                            (item.key !== "date" && item.key !== "notice_date" && item.key !== "expiry_date") ?
                                                <>
                                                    <div className="w-1/2">{data?.old_data?.[item.key] ? parse(data?.old_data?.[item.key]?.toString()) : '-'}</div>
                                                    <div className="w-1/2">{data?.new_data?.[item.key] ? parse(data?.new_data?.[item.key]?.toString()) : '-'}</div>
                                                </>
                                                :
                                                <>
                                                    <div className="w-1/2">{data?.old_data?.[item.key] ? moment(new Date(data?.old_data?.[item.key]))?.format("MMM DD, YYYY") : "-"}</div>
                                                    <div className="w-1/2">{data?.new_data?.[item.key] ? moment(new Date(data?.new_data?.[item.key]))?.format("MMM DD, YYYY") : "-"}</div>
                                                </>
                                            :
                                            <>
                                                <div className="w-1/2"><ul>{data?.old_data?.[item.key]?.length !== 0 ? data?.old_data?.[item.key]?.map((item, index) => <li> {index + 1 + ". " + item}</li>) : "-"}</ul></div>
                                                <div className="w-1/2"><ul>{data?.new_data?.[item.key]?.length !== 0 ? data?.new_data?.[item.key]?.map((item, index) => <li> {index + 1 + ". " + item}</li>) : "-"}</ul></div>
                                            </>
                                        }
                                    </div>
                                </React.Fragment>
                            ))
                        }
                    </>
                )}
            </div>
        );
    }
);

const NoticeAndCircularAuditLogs = () => {
    const navigate = useNavigate();
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const dispatch = useDispatch()
    const { loginData } = useSelector(state => state.loginReducer)
    const { noticeCircularAudit, loading } = useSelector((state) => state.noticeAndCircularReducer);
    const handleNavigation = (path) => () => {
        navigate(path);
    };

    const toggleAccordion = (index) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        const sendRequest = {
            societyId: loginData?.default_community?.community_id,
        }
        dispatch(getNoticeCircularAuditLogs(sendRequest))
    }, [])

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <ListHeaderBack
                    onClick={handleNavigation(NOTICE_AND_CIRCULARS_ENDPOINT)}
                    title="Notice And Circular Audit Logs"
                ></ListHeaderBack>
                <div className="border-b border-[#CCCCCC]"></div>
                <div>
                    {loading && !noticeCircularAudit ?
                        <Loader />
                        :
                        noticeCircularAudit &&
                        noticeCircularAudit?.map((item, index) => {
                            return (
                                <>
                                    <Accordion
                                        label={`Audit ${index + 1} (${item.audit})`}
                                        key={index}
                                        data={noticeCircularAudit?.[index]}
                                        isOpen={index === openAccordionIndex}
                                        toggleAccordion={() => toggleAccordion(index)}
                                        desc={`On ${moment(new Date(item?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
                                        // desc={`Modified By ${item?.user_name} On ${moment(new Date(item?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
                                        descr={`Ip Address: ${item?.IpAddress}. Session id - ${item?.modified_sessionId}`}
                                    />
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </Layout>
    );
};

export default NoticeAndCircularAuditLogs;