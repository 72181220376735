import React, { useEffect, useState } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import ButtonG from "../../../components/Button/ButtonG";
import Button from "../../../components/Button/Button";
import Layout from "../../Layout/Layout";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { addMoreHeadValidation } from "../../../validationSchema/setupRecordsSchema/invoiceSettingSchema";
import {
  addMoreHead,
  editMoreHeadDetails,
  getInvoiceHeadList,
  getMoreHeadDetails,
  deleteUttilitySlab,
} from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate, useParams } from "react-router-dom";
import AddUtilitySlabModel from "../../../components/Modal/AddUtilitySlabModal";
import { MultiSelectFM } from "../../../components/MultiSelect/MultiSelect";
import {CheckboxFM} from "../../../components/Checkbox/Checkbox";

const EditMoreHeads = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { typeId } = useParams();
 
  const { loginData } = useSelector((state) => state.loginReducer);
  const [deails, setDetails] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [addSlabModal, setaddSlabModal] = useState(false);
  const { loading, updateLoader, headDeails,headlist } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const headCells = [
    { id: "srno", label: "Sr. No.", sort: false },
    { id: "Slab type", label: "Slab type", sort: false },
    { id: "Range", label: "Range", sort: false },
    { id: "per Unit Charge", label: "per Unit Charge", sort: false },
    { id: "Action", label: "Action", sort: false },
  ];

  let fotmulas = [
    {
      value: "Per Sq.Ft.",
      label: "Per Sq.Ft.",
    },
    {
      value: "Equally Divided",
      label: "Equally Divided",
    },
    {
      value: "Per Flat",
      label: "Per Flat",
    },
  ];
  let percentagePeriodType = [
    {
      value: "na",
      label: "Not Applicable",
    },
    {
      value: "day",
      label: "Days wise",
    },
    {
      value: "month",
      label: "Month wise",
    },
  ]
  let UtilityType = [
    {
      value: "selective",
      label: "Selective",
    },
    {
      value: "incremental",
      label: "Incremental",
    },
  ]
  useEffect(() => {
    dispatch(getInvoiceHeadList({ societyId: loginData?.default_community?.community_id, typeId: typeId }))
    dispatch(
      getMoreHeadDetails({
        societyId: loginData?.default_community?.community_id,
        headId: id,
      })
    );
   
  }, []);
  
  useEffect(() => {
    if (headDeails) {
      setDetails(headDeails);
    }
  }, [headDeails]);
  useEffect(() => {
    if (deails && deails.type === 'percentage') {
      dispatch(getInvoiceHeadList({ societyId: loginData?.default_community?.community_id, typeId: typeId }))
    }
  }, []);
  const handleSave = (values) => {
    //if (deails.type === 'normal' || deails.type === 'percentage') {
      //console.log(values);
      dispatch(
        editMoreHeadDetails({
          societyId: loginData?.default_community?.community_id,
          headId: id,
          headName: values.headName,
          glCode: values.glCode,
          hsnCode: values.hsnCode,
          type: values.type,
          amount: values.amount,
          formula: values.formula,
          headDetail: values.headDetail,
          tenancyPeriod: values.tenancyPeriod,
          unitRoundOff: values.unitRoundOff,
          showUnitDetail: values.showUnitDetail,
          // onSuccessCallback: () => {
          //   dispatch(
          //     getMoreHeadDetails({
          //       societyId: loginData?.default_community?.community_id,
          //       headId: id,
          //     })
          //   );
          // },
        })
      );
    //}
  };
  const handleSlabDelete = (slabId) => {
    setDeleteId(slabId);
    dispatch(
      deleteUttilitySlab({
        societyId: loginData?.default_community?.community_id,
        headId: id,
        slabId: slabId,
        onSucessCallback: () => {
          dispatch(
            getMoreHeadDetails({
              societyId: loginData?.default_community?.community_id,
              headId: id,
            })
          );
          setDeleteId("");
        },
      })
    );
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        {loading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={{
              headName: deails?.invoice_head || "",
              glCode: deails?.gl_code || "",
              glName: deails?.gl_name || "",
              hsnCode: deails?.hsn_code || "",
              type: deails?.type || "",
              amount: deails?.amount || "",
              formula: deails?.formula || "",
              headDetail: deails?.head_detail || [],
              tenancyPeriod: deails?.tenancy_period || [],
              unitRoundOff: deails?.roundoff || 0,
              showUnitDetail: deails?.show_details || 0,
            }}
            enableReinitialize
            validationSchema={addMoreHeadValidation}
            onSubmit={handleSave}
          >
            {(formik) => {
              return (
                <Form>
                  <div className="border-b border-[#CCC]">
                    <ListHeaderBack
                      title="Edit More Heads"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <div className="flex gap-3">
                        <span className="border-l border-[#CCC]"></span>
                        {/* {formik.values.type === 'normal' ? <ButtonG label="Reset" type='reset' className="h-8" /> : <></>} */}
                        {/* {formik.values.type === "normal" || formik.values.type === "percentage" ? ( */}
                          <Button
                            label={
                              <span className="inline-block w-12">
                                {updateLoader ? (
                                  <CircularProgress
                                    sx={{ color: "white" }}
                                    size={17}
                                  />
                                ) : (
                                  "Save"
                                )}
                              </span>
                            }
                            className="h-8"
                            type="submit"
                            disabled={loading || updateLoader}
                          />
                        {/* ) : (
                          <></>
                        )} */}
                        {formik.values.type === "utility" ? (
                          <Button
                            label="Add Utility Slab"
                            className="h-8"
                            onClick={() => setaddSlabModal(true)}
                            disabled={loading || updateLoader}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </ListHeaderBack>
                  </div>
                  <div className="flex justify-between gap-4 p-2 mt-2 text-sm">

                    <table className="w-full border-separate border-spacing-2">
                      <tbody>
                        <tr className="border-b-2">
                          <td >Head Name</td>
                          <td>{formik.values.headName}</td>
                        </tr>
                        <tr className="border-b-2">
                          <td>GL Account Name</td>
                          <td>{formik.values.glName}</td>
                        </tr>
                        <tr className="border-b-2">
                          <td>Head type</td>
                          <td><span className="mb-2">{formik.values.type}{formik.values.type==="utility"?`(${formik.values.formula})`:""}</span></td>
                        </tr>
                        {formik.values.type === "normal" ? (
                          <tr className="border-b-2">
                            <td>Amount</td>
                            <td><TextInputFM
                              label=""
                              type="decimal"
                              placeholder="Enter Amount"
                              className="w-56 h-9"
                              name="amount"
                              maxLength={20}
                            /></td>
                          </tr>

                        ) : (
                          <></>
                        )}
                        {formik.values.type === "normal" ? (
                          <tr className="border-b-2">
                            <td>Formula</td>
                            <td>
                              <DropdownFM2
                                options={fotmulas.map((detail) => {
                                  return {
                                    value: detail.value,
                                    label: detail.label,
                                  };
                                })}
                                value={
                                  formik.values.type
                                    ? {
                                      label: formik.values.formula,
                                      value: formik.values.formula,
                                    }
                                    : null
                                }
                                
                                onSelect={(selectedItem) => {
                                  formik.setFieldValue(
                                    "formula",
                                    selectedItem.value
                                  );
                                }}
                                name="formula"
                                placeholder="Select Gl Account"
                                width="14rem"
                                height="36px"
                              />
                            </td>
                          </tr>

                        ) : (
                          <></>
                        )}
                        {formik.values.type === "percentage" ? (
                          <tr className="border-b-2">
                            <td>Heads</td>
                            <td>
                            <MultiSelectFM
                              name="headDetail"
                              options={
                                headlist?.filter((item) => item?.key!=id)?.map((item) => ({
                                  value: item?.key,
                                  label: item?.name,
                                })) || []
                              }
                              width="224px"
                              height={"fit-content"}
                              placeholder="Select Heads"
                              className="mb-2 text-xs"
                              defaultValue={ formik?.values?.headDetail?.map((item) => ({
                                value: item,
                                label: headlist?.find((key) => item === key?.key)?.name || "",
                              })) || []}
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "headDetail",
                                  selectedItem.map((item) => item.value)
                                );
                              }}
                              isMulti={true}
                            />
                         
                            </td>
                          </tr>

                        ) : <></>}
                        {formik.values.type === "percentage" ? (
                          <tr className="border-b-2">
                            <td>Percentage</td>
                            <td><TextInputFM
                              label=""
                              type="decimal"
                              placeholder="Enter Amount"
                              className="w-56 h-9"
                              name="amount"
                              maxLength={20}
                            /></td>
                          </tr>

                        ) : <></>}
                         {formik.values.type !== "utility" ? (
                          <tr className="border-b-2">
                            <td>Tenancy Period</td>
                            <td> 
                            <DropdownFM2
                              options={percentagePeriodType?.map((detail) => {
                                return {
                                  value: detail.value,
                                  label: detail.label
                                }
                              })}
                              value={formik.values.tenancyPeriod ? {
                                label: percentagePeriodType?.find((item) => item.value === formik.values.tenancyPeriod)?.label || "",
                                value: formik.values.tenancyPeriod,
                              } : null}
                              onSelect={(selectedItem) => {
                                formik.setFieldValue('tenancyPeriod', selectedItem.value)
                              }}
                              defaultValue={ {
                                value: formik.values.tenancyPeriod,
                                label: percentagePeriodType?.find((key) => formik.values.tenancyPeriod === key?.value)?.label || "",
                              } }
                              name="tenancyPeriod"
                              placeholder="Select Tenancy Period"
                              width="14rem"
                              height="36px"
                            />
                          </td>
                          </tr>

                        ) : <></>}
                        {formik.values.type === "utility" ? (
                          <tr className="border-b-2">
                            <td>Utility Type</td>
                            <td> 
                            <DropdownFM2
                            options={UtilityType.map((detail) => {
                              return {
                                value: detail.value,
                                label: detail.label
                              }
                            })}
                            value={formik.values.formula ? {
                              label: UtilityType?.find((item) => item.value === formik.values.formula)?.label || "",
                              value: formik.values.formula,
                            } : null}
                            onSelect={(selectedItem) => {
                              formik.setFieldValue('formula', selectedItem.value)
                            }}
                            name="formula"
                            placeholder="Select Formula"
                            width="14rem"
                            height="36px"
                          />
                          </td>
                          </tr>

                        ) : <></>}
                         {formik.values.type === "utility" ? (
                          <tr className="border-b-2">
                            <td>Round Off</td>
                            <td> 
                            <CheckboxFM
                              text=""
                              className="w-[215px] justify-self-start mt-[7.8px]  text-xs"
                              initialchecked={formik.values.unitRoundOff===1?true:false}
                              onChange={(e) =>{
                                formik.setFieldValue('unitRoundOff', e.target.checked ? 1 : 0)
                              }}
                              name="unitRoundOff"
                            />
                          </td>
                          </tr>

                        ) : <></>}
                         {formik.values.type === "utility" ? (
                          <tr className="border-b-2">
                            <td>Display Unit Details</td>
                            <td> 
                            <CheckboxFM
                              text=""
                              className="w-[215px] justify-self-start mt-[7.8px]  text-xs"
                              initialchecked={formik.values.showUnitDetail===1?true:false}
                              onChange={(e) =>{
                                formik.setFieldValue('showUnitDetail', e.target.checked ? 1 : 0)
                              }}
                              name="showUnitDetail"
                            />
                          </td>
                          </tr>

                        ) : <></>}
                      </tbody>
                    </table>

                  </div>
                  {formik.values.type === "utility" ? (
                    <Box
                      sx={{ width: "100%", height: "67%", fontSize: "13px" }}
                      className="px-5 table-component"
                    >
                      <TableContainer
                        className="border-t border-[#CCC]"
                        sx={{ minHeight: "500px" }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {headCells.map((column) => (
                                <TableCell
                                  size="small"
                                  align={column.numeric ? "right" : "left"}
                                  sortDirection={false}
                                  sx={{
                                    pl: 0,
                                    pr: 1,
                                    fontWeight: 600,
                                    zIndex: 0,
                                    color: "#AAAAAA",
                                    wordWrap: "break-word",
                                    fontSize: "13px",
                                    width: column.width || "fit-content",
                                    minWidth: column.width || "fit-content",
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {deails.slabs ? (
                              deails.slabs.map((slab, index) => {
                                return (
                                  <TableRow>
                                    <TableCell
                                      size="small"
                                      align="left"
                                      sx={{
                                        borderBottom: "none",
                                        fontSize: "13px",
                                        pl: 0,
                                        pr: 1,
                                        wordBreak: "break-word",
                                        width: "150px",
                                      }}
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      size="small"
                                      align="left"
                                      sx={{
                                        borderBottom: "none",
                                        fontSize: "13px",
                                        pl: 0,
                                        pr: 1,
                                        wordBreak: "break-word",
                                        width: "150px",
                                      }}
                                    >
                                      {slab.slab_type}
                                    </TableCell>
                                    <TableCell
                                      size="small"
                                      align="left"
                                      sx={{
                                        borderBottom: "none",
                                        fontSize: "13px",
                                        pl: 0,
                                        pr: 1,
                                        wordBreak: "break-word",
                                        width: "150px",
                                      }}
                                    >
                                      {slab.slab_range}
                                    </TableCell>
                                    <TableCell
                                      size="small"
                                      align="left"
                                      sx={{
                                        borderBottom: "none",
                                        fontSize: "13px",
                                        pl: 0,
                                        pr: 1,
                                        wordBreak: "break-word",
                                        width: "150px",
                                      }}
                                    >
                                      {slab.charge}
                                    </TableCell>
                                    <TableCell
                                      size="small"
                                      align="left"
                                      sx={{
                                        borderBottom: "none",
                                        fontSize: "13px",
                                        pl: 0,
                                        pr: 1,
                                        wordBreak: "break-word",
                                        width: "150px",
                                      }}
                                    >
                                      <Button
                                        label={
                                          <span className="inline-block w-12">
                                            {deleteId === slab.slabid ? (
                                              <CircularProgress
                                                sx={{ color: "white" }}
                                                size={17}
                                              />
                                            ) : (
                                              "Delete"
                                            )}
                                          </span>
                                        }
                                        className="h-8"
                                        onClick={() =>
                                          handleSlabDelete(slab.slabid)
                                        }
                                        disabled={loading || updateLoader}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow>
                                <TableCell></TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
      <AddUtilitySlabModel
        isOpen={addSlabModal}
        onClose={() => {
          setaddSlabModal(false);
        }}
        headId={id}
      />
    </Layout>
  );
};

export default EditMoreHeads;
