import React, { useEffect, useState } from "react";
import Layout from "../../pages/Layout/Layout";
import ListHeader from "../ListHeader/ListHeader";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import ButtonG from "../Button/ButtonG";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { getBulkManageInvoice } from "../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import { getFlatDetailsWithUserName } from "../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { BulkSchema } from "../../validationSchema/financeSchema/billingManagementSchema";
import {
  getBillPeriod,
} from "../../redux/actions/FinanceActions/BillingManagementActions/GenerateInvoiceAction";
import { getInvoiceTypeList } from "../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import moment from "moment";
import { MANAGE_BULK_CURRENT_INVOICE_ENDPOINT } from "../../Routing/routes";
import Dropdown2 from "../Dropdown/dropdown2";
import { useSearchParams } from "react-router-dom";
import ManageBulkInvoiceTable from "./ManageBulkInvoiceTable";
import Modal from "../Modal/Modal";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "60px" },
  { id: "Date", label: "Date", width: "90px" },
  { id: "Job Id", label: "Id", width: "90px" },
  { id: "Job Type", label: "Type", width: "90px" },
  { id: "Job Status", label: "Status", width: "90px" },
  // { id: "Job View", label: "Message", width: "90px" },
];

const ManageBulkInvoices = (isOpen, onClose) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);

  const { loginData } = useSelector((state) => state.loginReducer);
  const { BulkManageInvoice, loading } = useSelector(
    (state) => state.manageInvoiceReducer
  );
  const handleSaveClick = async (values, { resetForm }) => {
    let sendRequest = {
      ...values,
      societyId: loginData?.default_community?.community_id,
      onSuccessCallback: () => {
        resetForm();
      },
    };
  };
useEffect(()=>{
  dispatch(getBulkManageInvoice({
    societyid :  loginData?.default_community?.community_id,
    page: searchParams.get("page") || 1,
    limit: searchParams.get("pageLimit") || 10,
  })
)
},[searchParams])

  useEffect(() => {
    const data = { societyId: loginData?.default_community?.community_id };
    dispatch(getFlatDetailsWithUserName(data));
    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);
  useEffect(() => {
    if (typeLists) {
      setInvoiceTypeList(typeLists);
    }
  }, [typeLists]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            invoice_type: "",
            from_date: "",
            to_date: "",
          }}
          enableReinitialize
          validationSchema={BulkSchema}
          onSubmit={handleSaveClick}
        >
          {(formik, values, setFieldValue) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Bulk Invoice">
                    <Dropdown2
                      options={
                        invoiceTypeList
                          ? invoiceTypeList?.map((item) => ({
                              label: item?.invoice_title,
                              value: item?.id,
                            }))
                          : []
                      }
                      placeholder="Invoice Type"
                      className="text-[11px] ms-4"
                      width="172px"
                      height="32px"
                      onSelect={(selectedItem) => {
                        if (formik.values.from_date) {
                          dispatch(
                            getBillPeriod({
                              from_date: moment(formik.values.from_date).format(
                                "YYYY-MM-DD"
                              ),
                              societyId:
                                loginData?.default_community?.community_id,
                              invoiceType: selectedItem.value,
                            })
                          );
                        }
                        formik.setFieldValue(
                          "invoice_type",
                          selectedItem.value
                        );
                      }}
                      value={
                        formik.values.invoice_type && {
                          value: formik.values.invoice_type,
                          label: invoiceTypeList
                            ? invoiceTypeList?.find(
                                (item) =>
                                  item?.id ===
                                  parseInt(formik.values.invoice_type)
                              )?.invoice_title
                            : "",
                        }
                      }
                    />
                    <div className="border-r border-[#CCC]"></div>
                    <div>
                      <ButtonG
                        label="Clear"
                        className="h-8"
                        onClick={() =>
                          navigate(MANAGE_BULK_CURRENT_INVOICE_ENDPOINT)
                        }
                      />
                      <Button
                        label="Post"
                        className="h-8 mx-1"
                        onClick={() => setIsModalOpen(true)}
                      />
                      <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        title="Are you sure?"
                        children="All will be posted in current invoices."
                        onCancel={() => setIsModalOpen(false)}
                        onConfirm={() => {
                          setIsModalOpen(false);
                        }}
                      />
                      <Button
                        label="Delete"
                        className="h-8"
                        onClick={()=>setIsModal(true)}
                      />
                        <Modal
                        isOpen={isModal}
                        onClose={() => setIsModal(false)}
                        title="Are you sure?"
                        children="All will be Deleted from Draft invoices."
                        onCancel={() => setIsModal(false)}
                        onConfirm={() => {
                          setIsModal(false);
                        }}
                      />
                    </div>
                  </ListHeader>
                </div>
              </Form>
            );
          }}
        </Formik>

        <ManageBulkInvoiceTable 
        columns={headCells}
        loading={loading}
        checkbox={false}
        data={BulkManageInvoice?.data?.map(
          (item,index)=>({
            
           sNo: (BulkManageInvoice?.meta?.current_page -1) * 
           BulkManageInvoice?.meta?.per_page + 1  + index,
           id:item.id,
           date: item.created_at,
           type:item.job_type,
           status: item.job_status,
          })
        )}
        pagination={BulkManageInvoice?.data?.length}
        totalCount={BulkManageInvoice?.meta?.total}
        totalPages={BulkManageInvoice?.meta?.total}
        start={
          (BulkManageInvoice?.meta?.current_page -1) *
          BulkManageInvoice?.meta?.per_page +
          1
        }
        end={
          (BulkManageInvoice?.meta?.current_page -1) *
          BulkManageInvoice?.meta?.per_page +
          BulkManageInvoice?.data?.length
        }
        currentPage={Number(BulkManageInvoice?.meta?.current_page )}
        rowsPerPage={searchParams.get("pageLimit") || 10}
         />
            {!BulkManageInvoice?.data?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ManageBulkInvoices;
