import { notify } from '../../../utils/notification';
import apiEndpoints from '../../api';
import axiosInstance from '../../axiosInstance'
import {logOut} from '../../../utils/helpers/logOut'
import { errorMsg } from '../../../consts/responseMessages';
import moment from 'moment';
import axiosInstance2 from '../../axiosInstance2';
import getBrowserDetails from '../../../utils/helpers/getBrowserDetails';


export async function getFacilityListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_FACILITY_LIST_API}?society_id=${action.data.societyId}${action.data?.searchText ? `&filter_text=${encodeURIComponent(action.data.searchText)}` : ''}&page=${action.data.page || 0}${action.data?.pageLimit ? `&limit=${action.data.pageLimit}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getTimeSlotListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_TIME_SLOT_LIST_API}?facility_id=${action.data.facilityId}&frequency=${action?.data?.frequency || ""}&from_date=${action?.data?.dateOfBooking || ""}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getBookingFrequencyListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_BOOKING_FREQUENCY_LIST_API}?facility_id=${action.data.facilityId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getFacilityDetailsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_FACILITY_DETAILS_API}?id=${action.data.facilityId}&is_web_user=1`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateFacilityReq(action) {

    const BODY= {
        "id": action.data.facilityId,
        "facility_name": action.data.facilityName,
        "booking": action.data.booking,
        
        "is_chargeable": action.data.isChargeble,
        ...(action.data.chargeOn && {"charge_on": action.data.chargeOn}),
        "booking_avail_upto": action.data.bookingAvailableUpto || '',
        "booking_avail_upto_days":(action.data.bookingAvailableUpto || Math.ceil(moment(new Date(action.data.bookingAvailableUpto)).diff(new Date(), 'days', true)) >0)?  Math.ceil(moment(new Date(action.data.bookingAvailableUpto)).diff(new Date(), 'days', true)) : 0,

        "advance_booking_window": action.data.advanceBookingWindow || 0,

        "security_deposit_amnt" : action.data.securityDepositAmount || 0,
        "security_deposit_acc": action.data.securityDepositAccId,
        "facility_charge_acc": action.data.facilityChargeAccId,
        "bank_account": action.data.bankAccId,


        "is_web_user": 1,

        "calendar": action.data.bookingPerFlatCalendar || '',
        "booking_per_flat": action.data.bookingPerFlat,
        "booking_auto_approve": action.data.bookingAutoApprove,
        "unavailable_date": action.data.unavailableDates?.filter(item=> item.date)?.map(item=> item.date),
        "manager": action.data.facilityManagerId,
        "facility_manager_members": action.data.employeeId,
        "committee": action.data.managingCommitteId,
        "managing_committee_member": action.data.managingCommitteMemberId,
        "vendor_cat_id": action.data.vendorCategoryId,
        "vendor_id": action.data.vendorId,
        "vendor_concerned_person": action.data.concernedPersonId,
        "guidance": action.data.guidance,
        "instructions": action.data.description,
        "image": action.data.attachments,

        "ip": action.data.ipAddress,
        "session_id": action.data.sessionId
    }

    return axiosInstance.put(apiEndpoints.UPDATE_FACILITY_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getFacilityAuditLogstReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_FACILITY_AUDIT_LOGS_API}?id=${action.data.facilityId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function deleteFacilityReq(action) {
    const BODY= {
      }

    return axiosInstance.delete(`${apiEndpoints.DELETE_FACILITY_API}/${action.data.facilityId}/${action.data.ipAddress}/${action.data.sessionId}/1`, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateTimeSlotReq(action) {

    const BODY= {
        "facility_id": action.data.facilityId,
        "day_slot": action.data.timeSlot?.map((item, index)=>({
            ...(item.id && {"id": item.id}),
            "facility_type": item.type,
            "ticket_count": item.ticket,
            "start_time": item.startTime,
            "end_time": item.endTime,
            "frequency": item.frequency,
            "frequency_days": item.frequency==='All' ? '' : item.selectedDays.join(',')
        })),
        "hourly_slot" : action.data.timeSlotHourly?.map((item, index)=>({
            ...(item.id && {"id": item.id}),
            "facility_type" : item.type,
            "ticket_count" : item.ticket,
            "slot_limit" : item.lessThan,
            "hour" : item.hours
        }))
      }

    return axiosInstance.post(apiEndpoints.ADD_UPDATE_TIME_SLOT_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getRateChartReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_RATE_CHART_API}?facility_id=${action.data.facilityId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateRateChartReq(action) {

    const BODY= {
        "facility_id": action.data.facilityId,
        "rate_chart": action.data.rateChart?.map((item)=>({
            ...(item.id && {"id": item.id}),
            "frequency": item.frequency,
            "time_slot_id": parseInt(item.timeSlotId),
            "member_charge": parseFloat(item.chargeForMember),
            "non_member_charge": parseFloat(item.chargeForNonMember) || 0
        }))

      }

    return axiosInstance.post(apiEndpoints.ADD_UPDATE_RATE_CHART_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function checkAvailibilityReq(action) {

    const BODY= {
        "society_id": action.data.societyId,
        "facility_id": parseInt(action.data.facilityId),
        "frequency": action.data.frequency,
        "number_of_booking": action.data.noOfBooking,
        "select_week_days": action.data.weekDays,
        "from_date": action.data.fromDate || action.data.dateOfBooking,
        "to_date": action.data.toDate || action.data.dateOfBooking,
        "slot_id": action.data.slotId,
        "start_time": action.data.fromTime,
        "end_time": action.data.toTime
      }

    return axiosInstance.post(apiEndpoints.CHECK_BOOKING_AVAILIBILITY_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                //notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                //notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getAvailableTicketsReq(action) {

    const BODY= {
        "society_id": action.data.societyId,
        "facility_id": parseInt(action.data.facilityId),
        "frequency": action.data.frequency,
        "from_date": action.data.fromDate || action.data.dateOfBooking,
        "to_date": action.data.toDate || action.data.dateOfBooking,
        "slot_id": action.data.slotId,
        "start_time": action.data.fromTime,
        "end_time": action.data.toTime
      }

    return axiosInstance.post(apiEndpoints.GET_AVAILABLE_TICKETS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                //notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                //notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function addBookingReq(action) {

    const BODY= {
        "society_id": action.data.societyId,
        "facility_id": action.data.facilityId,
        "frequency": action.data.frequency,
        "number_of_booking": action.data.noOfBooking,
        ...(action.data.weekDays?.length ? {"select_week_days": action.data.weekDays} : {"select_week_days": (action.data.applicableDays).split(",")}),
        "from_date": action.data.fromDate || action.data.dateOfBooking,
        "to_date": action.data.toDate || action.data.dateOfBooking,
        "slot_id": action.data.slotId,
        "start_time": action.data.fromTime,
        "end_time": action.data.toTime,
        "member_id": action.data.memberId,
        "member_type": action.data.memberType,
        "wing_id": action.data.wingId,
        "flat_id": action.data.flatId,
        "booking_amount": action.data.bookingAmount,
        "book_and_pay": action.data.mode,
        "booking_purpose": action.data.purpose,
        "other_booking_info": action.data.other,
        "ip": action.data?.ipAddress,
        "session_id": action.data?.sessionId,
        "browser_details": getBrowserDetails(),
      }

    return axiosInstance.post(apiEndpoints.ADD_BOOKING_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


export async function changeFacilityStatusReq(action) {

    const BODY= {
        "id": action.data.facilityId,
        "status": action.data.status
      }

    return axiosInstance.put(apiEndpoints.CHANGE_FACILITY_STATUS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteTimeSlotReq(action) {
    const BODY= {
      }

    return axiosInstance.delete(`${apiEndpoints.DELETE_TIME_SLOT_API}/${action.data.timeSlotId}`, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteRateChartReq(action) {
    const BODY= {
      }

    return axiosInstance.delete(`${apiEndpoints.DELETE_RATE_CHART_API}/${action.data.rateChartId}`, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function sendMessageToAdministratorReq(action) {

    const BODY= {
        "subject": action.data.subject,
        "purpose": action.data.purpose,
        "message": action.data.message
      }

    return axiosInstance.post(apiEndpoints.SEND_MESSAGE_TO_ADMINISTRATOR_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getFacilityPaymentLinkReq(action) {
    const BODY={
        'invoice_id':action.data?.invoiceId,
        'page_id': action.data?.societyId,
        'amount': action?.data?.amount,
    }

    return axiosInstance2.post(apiEndpoints.GET_FACILTIY_ONLINE_PAYMENT_LINK_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getFacilityBookingsAuditLogsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_FACILITY_BOOKINGS_AUDIT_LOGS_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}