import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MANAGE_COMPLAINT_CATEGORY_ENDPOINT } from "../../../Routing/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from '../../../components/Loader/Loader'
import { getComplaintCategoryAuditLogs } from "../../../redux/actions/DeskActions/ComplaintCategoryAction";


const Accordion = React.memo(
    ({ data, isOpen, toggleAccordion, label, descr, desc }) => {
        const oldComplaintCategoryDetails = data?.old_category_details
        const newComplaintCategoryDetails = data?.new_category_details
        const caomplaintData = oldComplaintCategoryDetails.length > newComplaintCategoryDetails.length ? oldComplaintCategoryDetails : newComplaintCategoryDetails

        return (
            <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
                <div
                    className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b "
                    onClick={toggleAccordion}
                >
                    <div className="flex items-center">
                        <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
                            {isOpen ? (
                                <FontAwesomeIcon icon={faChevronUp} size="xs" />
                            ) : (
                                <FontAwesomeIcon icon={faChevronDown} size="xs" />
                            )}
                        </button>
                        <div className="text-[#000000] text-sm font-semibold">{label}</div>
                    </div>
                    <div>
                        <div className="text-xs text-right text-[#888]">{desc}</div>
                        <div className="text-xs text-right text-[#888]">{descr}</div>
                    </div>
                </div>
                {isOpen && (
                    <div className="p-3">
                        {caomplaintData?.map((item, i0) => (
                            <React.Fragment key={i0}>
                                <div className="flex justify-between p-2 border-b">
                                    <div className="flex justify-between w-full font-semibold text-[#222222] text-sm" key={i0}>
                                        <div className="w-1/2"></div>
                                        <div className="w-1/2">Old Data</div>
                                        <div className="w-1/2">New Data</div>
                                    </div>
                                </div>
                                {(oldComplaintCategoryDetails?.[i0]?.["title"] || newComplaintCategoryDetails?.[i0]?.["title"]) &&
                                    <>
                                        <div className="flex justify-between p-2">
                                            <div className="flex justify-between w-full text-sm" key={i0}>
                                                <div className="w-1/2">Title</div>
                                                <div className="w-1/2">
                                                    {oldComplaintCategoryDetails?.[i0]?.["title"] ? oldComplaintCategoryDetails?.[i0]?.["title"] : '-'}
                                                </div>
                                                <div className="w-1/2">
                                                    {newComplaintCategoryDetails?.[i0]?.['title'] ? newComplaintCategoryDetails?.[i0]?.["title"] : '-'}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-between p-2">
                                            <div className="flex justify-between w-full text-sm" key={i0}>
                                                <div className="w-1/2">Message Template</div>
                                                <div className="w-1/2">
                                                    {oldComplaintCategoryDetails?.[i0]?.["message_template"] ? oldComplaintCategoryDetails?.[i0]?.["message_template"] : '-'}
                                                </div>
                                                <div className="w-1/2">
                                                    {newComplaintCategoryDetails?.[i0]?.['message_template'] ? newComplaintCategoryDetails?.[i0]?.["message_template"] : '-'}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="acord-section font-semibold text-[#222222] p-2 text-sm">
                                    Sub Category
                                </div>
                                {item.sub_category.map((sub, i1) => (
                                    <>
                                        <div className="flex justify-between p-2">
                                            <div className="flex justify-between w-full text-sm" key={i0}>
                                                <div className="w-1/2">Title</div>
                                                <div className="w-1/2">
                                                    {oldComplaintCategoryDetails?.[i0]?.sub_category?.[i1]?.["sub_title"] ? oldComplaintCategoryDetails?.[i0]?.sub_category?.[i1]?.["sub_title"] : '-'}
                                                </div>
                                                <div className="w-1/2">
                                                    {newComplaintCategoryDetails?.[i0]?.sub_category?.[i1]?.['sub_title'] ? newComplaintCategoryDetails?.[i0]?.sub_category?.[i1]?.["sub_title"] : '-'}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-between p-2">
                                            <div className="flex justify-between w-full text-sm" key={i0}>
                                                <div className="w-1/2">Message Template</div>
                                                <div className="w-1/2">
                                                    {oldComplaintCategoryDetails?.[i0]?.sub_category?.[i1]?.["sub_message_template"] ? oldComplaintCategoryDetails?.[i0]?.sub_category?.[i1]?.["sub_message_template"] : '-'}
                                                </div>
                                                <div className="w-1/2">
                                                    {newComplaintCategoryDetails?.[i0]?.sub_category?.[i1]?.['sub_message_template'] ? newComplaintCategoryDetails?.[i0]?.sub_category?.[i1]?.["sub_message_template"] : '-'}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </div>
        );
    }
);

const ComplaintCategoryAuditLogs = () => {
    const navigate = useNavigate();
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const dispatch = useDispatch()
    const { loginData } = useSelector(state => state.loginReducer)
    const { loading, complaintCategoryAudit } = useSelector(state => state.complaintCategoryReducer)

    const handleNavigation = (path) => () => {
        navigate(path);
    };

    const toggleAccordion = (index) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        const sendRequest = {
            societyId: loginData?.default_community?.community_id,
        }
        dispatch(getComplaintCategoryAuditLogs(sendRequest))
    }, [])

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <ListHeaderBack
                    onClick={handleNavigation(MANAGE_COMPLAINT_CATEGORY_ENDPOINT)}
                    title="Complaint Category Audit Logs"
                ></ListHeaderBack>
                <div className="border-b border-[#CCCCCC]"></div>
                <div>

                    {
                        loading && !complaintCategoryAudit ?
                            <Loader />
                            :
                            complaintCategoryAudit &&
                            complaintCategoryAudit?.map((item, index) => {
                                return (
                                    <>
                                        <Accordion
                                            label={`Audit ${index + 1} (${item.audit})`}
                                            key={index}
                                            data={complaintCategoryAudit?.[index]}
                                            isOpen={index === openAccordionIndex}
                                            toggleAccordion={() => toggleAccordion(index)}
                                            desc={`User Type ${item?.modified_type} On ${moment(new Date(item?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
                                            // desc={`Modified By ${item?.user_name} On ${moment(new Date(item?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
                                            descr={`Ip Address: ${item?.IpAddress}. Session id - ${item?.modified_sessionId}`}
                                        />
                                    </>
                                )
                            })
                    }
                </div>
            </div>
        </Layout>
    );
};

export default ComplaintCategoryAuditLogs;