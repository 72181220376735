import React, { useEffect,useState } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import Search from "../../../../components/SearchBox/Search";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getGstReports,
} from "../../../../redux/actions/ReportActions/FinanceReportActions";
import {
  getInvoiceTypeList,
} from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import GstReportTable from "./GstReportTable";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import moment from "moment";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { GST_REPORT_ENDPOINT } from "../../../../Routing/routes";
import { GstVoucherTypeOptions } from "../../../../consts/DropDownOptions";
import { exportToExcel } from 'react-json-to-excel';

export const headCells = [
  { id: "flatNo", label: "Flat No.", width: "90px" },
  { id: "memberName", label: "Member Name", width: "100px", },
  { id: "gstin", label: "GSTN", width: "90px" },
  { id: "billType", label: "Bill Type", width: "90px" },
  { id: "voucherType", label: "Voucher Type", width: "90px" },
  { id: "billPeriod", label: "Billing Period", width: "90px" },
  { id: "billDate", label: "Bill Date", width: "90px" },
  { id: "billMonth", label: "Billing Month", width: "90px" },
  { id: "billNo", label: "Bill No.", width: "90px" },
  { id: "taxable", label: "Taxable", width: "90px" },
  { id: "nonTaxable", label: "Non-Taxable", width: "60px" },
  { id: "exempted", label: "Exempted", width: "60px" },
  { id: "cgst", label: "CGST", width: "60px" },
  { id: "sgst", label: "SGST", width: "60px" },
  { id: "igst", label: "IGST", width: "60px" },
  { id: "roundOff", label: "Round Off", width: "60px" },
  { id: "billTotal", label: "Bill Total", width: "60px" },
  { id: "gstTotal", label: "GST Total", width: "60px" },
  { id: "gstVerication", label: "GST Verification", width: "60px" },
  { id: "gstDifferent", label: "GST Difference", width: "60px" },
  // { id: "actions", label: "GST Difference", width: "60px" },
];

const GstReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const { loginData } = useSelector((state) => state.loginReducer);
  const { typeLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const { loading, gstReport } = useSelector(
    (state) => state.financeReportReducer
  );
  const { loginData } = useSelector((state) => state.loginReducer);
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("search");
      navigate({
        pathname: `${GST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };

  const handelSelectBillType = (selectedItem) => {

    if (selectedItem.value?.toString() !== searchParams.get("billType")) {
      searchParams.delete("billType");
      navigate({
        pathname: `${GST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["billType", selectedItem.value],
        ])}`,
      });
    }
  };
  const handelSelectVoucherType = (selectedItem) => {

    if (selectedItem.value?.toString() !== searchParams.get("voucherType")) {
      searchParams.delete("voucherType");
      navigate({
        pathname: `${GST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["voucherType", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (moment(selectedDate).format('YYYY-MM-DD') !== searchParams.get("startDate")) {
      searchParams.delete("startDate")
      searchParams.delete("endDate")
      navigate({
        pathname: `${GST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["startDate", moment(selectedDate).format('YYYY-MM-DD')]])}`,
      })
    }
  };

  const handleEndDate = (selectedDate) => {
    if (moment(selectedDate).format('YYYY-MM-DD') !== searchParams.get("endDate")) {
      searchParams.delete("endDate")
      navigate({
        pathname: `${GST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["endDate", moment(selectedDate).format('YYYY-MM-DD')]])}`,
      })
    }
  };

  const handleClear = () => {
    navigate(GST_REPORT_ENDPOINT);
  };

  useEffect(() => {
    dispatch(getInvoiceTypeList({
      societyId: loginData?.default_community?.community_id,
    }))
    if (!searchParams.get("startDate") || !searchParams.get("endDate")) {
      searchParams.delete("startDate")
      searchParams.delete("endDate")
      navigate({
        pathname: `${GST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment().format('YYYY-MM-DD')],
          ["startDate", moment().startOf('month').format('YYYY-MM-DD')],
        ])}`,
      });
    }
  }, []);
  useEffect(() => {
    if (typeLists) {
      setInvoiceTypeList(typeLists)
    }
   
  }, [typeLists])
  //dispatch(getFinanceMembersList({ society_registration: "MYAS387" }));
  useEffect(() => {
    if ((!searchParams.get("startDate") && !searchParams.get("endDate")) || (searchParams.get("startDate") && searchParams.get("endDate"))) {
      dispatch(
        getGstReports({
          societyId: [loginData?.default_community?.community_id],
          // society_registration: "MYAS387",
          billType: searchParams.get("billType"),
          voucherType: searchParams.get("voucherType"),
          fromDate: searchParams.get("startDate"),
          toDate: searchParams.get("endDate"),
        })
      );
    }
  }, [searchParams]);
  const excelDataProcess = (exportData) => {
    const exceldata = exportData ? exportData?.map(
      (item, index) => ({
        'Flat No': item?.unit_no,
        'Member Name': item?.member_name,
        'GSTN': item?.gst_no,
        'Bill Type': item?.invoice_type,
        'Voucher Type': item?.voucher_no,
        'Billing Period': item?.invoice_period,
        'Bill Date': item?.invoice_date,
        'Billing Month': item?.invoice_month,
        'Bill No.': item?.invoice_no,
        'Taxable': item?.taxable_amount,
        'Non-Taxable': item?.nontaxable_amount,
        'Exempted': item?.exempted_amount,
        'CGST': item?.cgst_amount,
        'SGST': item?.sgst_amount,
        'IGST': item?.igst_amount,
        'Round Off': item?.roundoff,
        'Bill Total': item?.total,
        'GST Total': item?.gst_total,
        'GST Verification': item?.calculated_gst_total,
        'GST Difference': item?.gst_different,
        'Remark': "",
      })
    ) : [];
    exportToExcel(exceldata, 'invoicepaymentexcelexport')
  }
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="GST Report">
            {/* <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
          /> */}
            {/* <span className="border-r border-[#CCC]"></span> */}
            <Dropdown2
              options={
                invoiceTypeList?.map((item) => ({
                  value: item?.id,
                  label: item?.invoice_title,
                })) || []
              }
              placeholder="Select Bill Type"
              className=""
              width="182px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectBillType(selectedItem);
              }}
              value={
                searchParams.get("billType") && {
                  value: searchParams.get("billType"),
                  label: invoiceTypeList?.find(
                    (item) => item.id == searchParams.get("billType")
                  )?.invoice_title || "",
                }
              }
            />
            <Dropdown2
              options={GstVoucherTypeOptions}
              placeholder="Select Voucher Type"
              className=""
              width="182px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectVoucherType(selectedItem);
              }}
              value={
                searchParams.get("voucherType") && {
                  value: searchParams.get("voucherType"),
                  label: GstVoucherTypeOptions?.find(
                    (item) => item.value === searchParams.get("voucherType")
                  )?.label,
                }
              }
              //   {...(formik.values.subCategory === "" && {
              //     value: null,
              //   })}
              name={"subCategory"}
            />
            <RangeDatePicker
              className="h-8 w-44"
              defaultStartValue={searchParams.get("startDate") ? new Date(searchParams.get("startDate")) : ''}
              defaultEndValue={searchParams.get("endDate") ? new Date(searchParams.get("endDate")) : ''}
              onStartDateChange={handleStartDate}
              onEndDateChange={handleEndDate}
            />
            <span className="border-r border-[#CCC]"></span>
            <button
              onClick={() => gstReport && excelDataProcess(gstReport)}
              type="button"
              className="flex items-center h-8 px-2 text-xs font-semibold bg-white border rounded-lg text-red-650 border-red-650 whitespace-nowrap"
            >
              Download Excel
            </button>
          </ListHeader>
        </div>
        <div className="ml-1">
          <GstReportTable
            loading={loading}
            data={
              gstReport?.map((item, index) => ({
                flatNo: item?.unit_no,
                memberName: item?.member_name,
                gstin: item?.gst_no,
                billType: item?.invoice_type,
                voucherType: item?.voucher_no,
                billPeriod: item?.invoice_period,
                // docType: item?.BIB_recNo,
                billDate: item?.invoice_date,
                billMonth: item?.invoice_month,
                billNo: item?.invoice_no,
                taxable: item?.taxable_amount,
                nonTaxable: item?.nontaxable_amount,
                exempted: item?.exempted_amount,
                cgst: item?.cgst_amount,
                sgst: item?.sgst_amount,
                igst: item?.igst_amount,
                roundOff: item?.roundoff,
                billTotal: item?.total,
                gstTotal: item?.gst_total,
                gstVerication: item?.calculated_gst_total,
                gstDifferent: item?.gst_different,
              })) || []
            }
            columns={headCells}
            checkbox={false}
          // pagination={eInvoiceReports?.length}
          // totalCount={eInvoiceReports?.length}
          // totalPages={eInvoiceReports?.page}
          // start={eInvoiceReports?.current * eInvoiceReports?.limit + 1}
          // end={eInvoiceReports?.current * eInvoiceReports?.limit + eInvoiceReports?.length}
          // currentPage={(eInvoiceReports?.current) + 1}
          // rowsPerPage={searchParams.get("pageLimit") || 10}
          ></GstReportTable>
        </div>
        {/* {!gstReport?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )} */}
      </div>
    </Layout>
  );
};

export default GstReport;
