import * as types from '../../index.types';

export function getSpecificDetails() {
    return {
        type: types.GET_SPECIFIC_DETAILS,
    };
}

export function updateSpecificDetails(data) {
    return {
        type: types.UPDATE_SPECIFIC_DETAILS,
        data
    };
}

export function getSpecificDetailsAuditLogs(data) {
    return {
        type: types.GET_SPECIFIC_DETAILS_AUDIT_LOGS,
        data
    };
}