import * as type from "../../actions/index.types";

export function deskReducers(
  state = {
    loading: false,
    updateLoader: false,
    loadingSettings: true,
    error: null,
    complaintEscalationList: [],
    complaintEmailSmsSettings: [],
    complaintDashboardList: null,
    complaintDashboardContentList: null,
    complaintAgingList: null,
    complaintLoggedTicket: null,
    assignedComplaintData: null,
    assignedMembersList: [],
    assignedSubReqRoles: [],
    assignedReqRoles: [],
    postCommentData: [],
    complaintStatusList: [],
    societyDetailsList: [],
    complaintDashboardReview: null,
  },
  action
) {
  switch (action.type) {
    // complaint escalaltion sms
    case type.GET_COMPLAINT_ESCALATION_LIST_SETTINGS:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_COMPLAINT_ESCALATION_LIST_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        complaintEscalationList: action.payload,
      };

    case type.GET_COMPLAINT_ESCALATION_LIST_SETTINGS_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    //   complaint email sms
    case type.GET_COMPLAINT_EMAIL_SMS_LIST:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_COMPLAINT_EMAIL_SMS_LIST_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        complaintEmailSmsSettings: action.payload,
      };

    case type.GET_COMPLAINT_EMAIL_SMS_LIST_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.UPDATE_COMPLAINT_EMAIL_SMS_LIST:
      return {
        ...state,
        updateLoader: true,
      };
    case type.UPDATE_COMPLAINT_EMAIL_SMS_LIST_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.UPDATE_COMPLAINT_EMAIL_SMS_LIST_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };
    //   complaint Dashboard reducers

    case type.GET_COMPLAINT_DASHBOARD_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_COMPLAINT_DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        complaintDashboardContentList: action.payload,
      };
    case type.GET_COMPLAINT_DASHBOARD_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        complaintDashboardContentList: null,
      };

    case type.GET_COMPLAINT_DASHBOARD_CONTENT_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_COMPLAINT_DASHBOARD_CONTENT_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        complaintDashboardList: action.payload,
      };
    case type.GET_COMPLAINT_DASHBOARD_CONTENT_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        complaintDashboardList: null,
      };

    case type.GET_COMPLAINT_LOGGED_TICKET:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_COMPLAINT_LOGGED_TICKET_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        complaintLoggedTicket: action.payload,
      };
    case type.GET_COMPLAINT_LOGGED_TICKET_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        complaintLoggedTicket: null,
      };

    //   complaint aging reducer
    case type.GET_COMPLAINT_AGING_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_COMPLAINT_AGING_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        complaintAgingList: action.payload,
      };
    case type.GET_COMPLAINT_AGING_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        complaintAgingList: null,
      };

    case type.GET_ASSIGNED_REQ_ROLES:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_ASSIGNED_REQ_ROLES_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        assignedReqRoles: action.payload,
      };

    case type.GET_ASSIGNED_REQ_ROLES_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };
    case type.GET_ASSIGNED_REQ_SUB_ROLES:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_ASSIGNED_REQ_SUB_ROLES_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        assignedSubReqRoles: action.payload,
      };

    case type.GET_ASSIGNED_REQ_SUB_ROLES_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.GET_ASSIGNED_MEMBERS_LIST_ROLES:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_ASSIGNED_MEMBERS_LIST_ROLES_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        assignedMembersList: action.payload,
      };

    case type.GET_ASSIGNED_MEMBERS_LIST_ROLES_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.GET_ASSIGNED_COMPLAINT_DATA:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_ASSIGNED_COMPLAINT_DATA_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        assignedComplaintData: action.payload,
      };

    case type.GET_ASSIGNED_COMPLAINT_DATA_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.ADD_ASSIGNED_COMPLAINT_DATA:
      return {
        ...state,
        updateLoader: true,
      };

    case type.ADD_ASSIGNED_COMPLAINT_DATA_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };

    case type.ADD_ASSIGNED_COMPLAINT_DATA_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.CHANGE_COMPLAINT_STATUS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.CHANGE_COMPLAINT_STATUS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        complaintDashboardList: {
          ...state.complaintDashboardList,
          NodeList: state.complaintDashboardList?.NodeList?.map((complaint) => {
            console.log(complaint?.id, action.payload.complaintId);
            return complaint?.id === action.payload.complaintId
              ? {
                ...complaint,
                status: action.payload.status === "I" ? "Closed" : "Open",
              }
              : complaint;
          }),
        },
      };
    case type.CHANGE_COMPLAINT_STATUS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.GET_POST_COMMENT_DATA:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_POST_COMMENT_DATA_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        postCommentData: action.payload,
      };

    case type.GET_POST_COMMENT_DATA_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.ADD_POST_COMMENT_DATA:
      return {
        ...state,
        updateLoader: true,
      };

    case type.ADD_POST_COMMENT_DATA_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        complaintDashboardList: {
          ...state.complaintDashboardList,
          complaint_list: state.complaintDashboardList.complaint_list?.map((item) => {
            if (item.id == action?.payload?.prev_data?.complaintId) {
              return {
                ...item,
                status: action?.payload?.prev_data?.status_label,
              }
            }
            return item;
          })
        }
      };

    case type.ADD_POST_COMMENT_DATA_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.GET_COMPLAINT_STATUS_DATA:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_COMPLAINT_STATUS_DATA_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        complaintStatusList: action.payload,
      };

    case type.GET_COMPLAINT_STATUS_DATA_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.GET_SOCIETY_LIST_DETAILS_DATA:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_SOCIETY_LIST_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        societyDetailsList: action.payload,
      };

    case type.GET_SOCIETY_LIST_DETAILS_DATA_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.ADD_COMPLAINT_DASHBOARD_REVIEW:
      return {
        ...state,
        loading: false,
        complaintDashboardReview: true,
      };

    case type.ADD_COMPLAINT_DASHBOARD_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        complaintDashboardReview: action.payload,
      };

    case type.ADD_COMPLAINT_DASHBOARD_REVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        complaintDashboardReview: false,
      };

    default:
      return state;
  }
}
