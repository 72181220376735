import { all, call, put, takeLatest } from 'redux-saga/effects'
import { notify } from '../../../utils/notification'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/Social&Communication/NoticeAndCircularReqs'
import { deleteImageFromFirebase, getFirebaseURL, uploadImageOnFirebase } from '../../../firebase/firebaseFunction';
import { flatsDocsPath, societyOccupantDocsPath, noticeCircularDosPath, societyOccupantProfilePath } from '../../../firebase/getFirebasePath';

function* getNoticeCircularList(action) {
    try {
        const result = yield call(req.getNoticeCircularListReq, action);
        result.data.limit = action.data.limit;
        if (result.statusCode === 200) {
            yield put({ type: types.GET_NOTICE_CIRCULAR_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_NOTICE_CIRCULAR_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* resendNoticeCircular(action) {
    try {
        const result = yield call(req.resendNoticeCircularReq, action);
        if (result.statusCode === 200) {
            notify('success', "Resent Notice Successfully");
            yield put({ type: types.RESEND_NOTICE_CIRCULAR_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.RESEND_NOTICE_CIRCULAR_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteNoticeCircular(action) {
    try {
        const resultNoitceCircularDetails = yield call(req.getNoticeCircularDetailsReq, action);
        if (resultNoitceCircularDetails?.data?.attachments?.length > 0) {
            for (let i in resultNoitceCircularDetails.data.attachments) {
                yield deleteImageFromFirebase(resultNoitceCircularDetails.data.attachments[i].attachment)
            }
        }
        const result = yield call(req.deleteNoticeCircularReq, action);
        if (result.statusCode === 200) {
            notify('success', "Notice Deleted Successfully");
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_NOTICE_CIRCULAR_SUCCESS, payload: action.data });

            if (action.data.fetchData) {
                const newNoticeCircularList = yield call(req.getNoticeCircularListReq, action)
                if (result.statusCode === 200)
                    newNoticeCircularList.data.limit = action.data.limit;
                yield put({ type: types.GET_NOTICE_CIRCULAR_LIST_SUCCESS, payload: newNoticeCircularList.data });
            }

        }
        else {
            yield put({ type: types.DELETE_NOTICE_CIRCULAR_FAILURE, message: result?.response?.data?.message });

        }
    } catch (e) {
        console.log(e)
    }
}

function* getNoticeCircularDetails(action) {
    try {
        const result = yield call(req.getNoticeCircularDetailsReq, action);
        if (result.statusCode === 200) {
            for (let data of result.data?.attachments) {
                data.attachment_url = yield getFirebaseURL(data.attachment)
            }
            yield put({ type: types.GET_NOTICE_CIRCULAR_DETAILS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_NOTICE_CIRCULAR_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* printNoticeCircular(action) {
    try {
        const result = yield call(req.printNoticeCircularReq, action);
        yield put({ type: types.PRINT_NOTICE_CIRCULAR_SUCCESS, payload: result });

    } catch (e) {
        yield put({ type: types.PRINT_NOTICE_CIRCULAR_FAILURE, message: e });
        console.log(e)
    }
}

function* postNoticeCircularComment(action) {
    try {
        const result = yield call(req.postNoticeCircularCommentReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            yield put({ type: types.POST_NOTICE_CIRCULAR_COMMENT_SUCCESS, payload: result.data });
            if (action.data.fetchData) {
                const newNoticeCircularComment = yield call(req.getNoticeCircularDetailsReq, action)
                for (let data of newNoticeCircularComment.data?.attachments) {
                    data.attachment_url = yield getFirebaseURL(data.attachment)
                }
                newNoticeCircularComment.data.limit = action.data?.limit || 0;
                yield put({ type: types.GET_NOTICE_CIRCULAR_DETAILS_SUCCESS, payload: newNoticeCircularComment.data });
            }
        } else {
            yield put({ type: types.POST_NOTICE_CIRCULAR_COMMENT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addNoticeCircular(action) {

    try {
        let data = action.data;
        if (action.data.attachments.length > 0) {
            for (let i in action.data.attachments) {
                if (typeof action.data.attachments[i]?.file !== 'string') {
                    let path = noticeCircularDosPath({ societyId: action.data.societyId, fileName: action.data.attachments[i]?.file?.name });
                    yield uploadImageOnFirebase(action.data.attachments[i]?.file, path)
                    data.attachments[i] = path;
                }
            }
        }
        action.data = data;
        delete action.data.societyId;
        const result = yield call(req.addNoticeCircularReq, action);
        if (result.statusCode === 200) {
            notify('success', "Notice Added Successfully");
            action.data?.onSuccessCallback?.()
            yield put({ type: types.ADD_NOTICE_CIRCULAR_SUCCESS, payload: result.data });

        } else {
            yield put({ type: types.ADD_NOTICE_CIRCULAR_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateNoticeCircular(action) {

    try {
        let data = action.data;
        if (action.data?.attachments?.length > 0) {
            for (let i in action.data.attachments) {
                if (typeof action.data.attachments[i]?.file !== 'string') {
                    let path = noticeCircularDosPath({ societyId: action.data.societyId, fileName: action.data.attachments[i]?.file?.name });
                    yield uploadImageOnFirebase(action.data.attachments[i]?.file, path)
                    data.attachments[i] = path;
                }
            }
        }
        data.attachments = [...data?.attachments, ...action.data?.savedAttachments]
        action.data = data;
        const result = yield call(req.updateNoticeCircularReq, action);
        if (result.statusCode === 200) {

            let resend = null;
            if (action?.data?.resend == 1) {
                resend = yield call(req.resendNoticeCircularReq, action);
            }

            if (resend?.statusCode === 200) {
                notify('success', "Notice Updated And Resent Successfully");
            }
            else {
                notify('success', "Notice Updated Successfully");
            }
            action.data?.onSuccessCallback?.()

            if (action?.data?.deleteAttachments?.length > 0) {
                for (let i in action?.data?.deleteAttachments) {
                    yield deleteImageFromFirebase(action.data.deleteAttachments[i].attachment)
                }
            }

            yield put({ type: types.UPDATE_NOTICE_CIRCULAR_SUCCESS, payload: result.data });

        } else {
            yield put({ type: types.UPDATE_NOTICE_CIRCULAR_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getNoticeCirularTypes(action) {
    try {
        const result = yield call(req.getNoticeCircularTypesReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_NOTICE_CIRCULAR_TYPES_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_NOTICE_CIRCULAR_TYPES_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getNoticeCirularManagingCommittee(action) {
    try {
        const result = yield call(req.getNoticeCircularManagingCommitteeReq, action)
        if (result.statusCode === 200) {
            yield put({ type: types.GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE_SUCCESS, payload: result.data })
        }
        else {
            yield put({ type: types.GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE_FAILURE, message: result?.response?.data?.message })

        }
    }
    catch (e) {
        console.log(e)
    }
}
function* getNoticeCircularArchiveList(action) {
    try {
        const result = yield call(req.getNoticeCircularArvhiveListReq, action)
        result.data.limit = action?.data?.limit;
        if (result.statusCode === 200) {
            yield put({ type: types.GET_NOTICE_CIRCULAR_ARCHIVE_LIST_SUCCESS, payload: result.data })
        }
        else {
            yield put({ type: types.GET_NOTICE_CIRCULAR_ARCHIVE_LIST_FAILURE, message: result?.response?.data?.message })

        }
    }
    catch (e) {
        console.log(e)
    }
}


function* deleteNoticeCircularArchive(action) {
    try {
        const resultNoitceCircularDetails = yield call(req.getNoticeCircularDetailsReq, action);
        if (resultNoitceCircularDetails?.data?.attachments?.length > 0) {
            for (let i in resultNoitceCircularDetails.data.attachments) {
                yield deleteImageFromFirebase(resultNoitceCircularDetails.data.attachments[i].attachment)
            }
        }

        const result = yield call(req.deleteNoticeCircularReq, action);
        if (result.statusCode === 200) {
            if (action.data.filter_type == "archive")
                notify('success', "Archive Deleted Successfully");
            else if (action.data.filter_type == "draft")
                notify('success', "Draft Deleted Successfully");

            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_NOTICE_CIRCULAR_ARCHIVE_SUCCESS, payload: action.data });

            if (action.data.fetchData) {
                const newNoticeCircularArchiveList = yield call(req.getNoticeCircularArvhiveListReq, action)
                if (result.statusCode === 200)
                    newNoticeCircularArchiveList.data.limit = action.data.limit;
                yield put({ type: types.GET_NOTICE_CIRCULAR_ARCHIVE_LIST_SUCCESS, payload: newNoticeCircularArchiveList.data });
            }
        }
        else {
            yield put({ type: types.GET_NOTICE_CIRCULAR_ARCHIVE_LIST_FAILURE, message: result?.response?.data?.message });

        }
    } catch (e) {
        console.log(e)
    }
}

function* addAcknowledgementNotice(action) {
    try {
        const result = yield call(req.addAcknowledgementNoticeReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_ACKNOWLEDGEMENT_NOTICE_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.ADD_ACKNOWLEDGEMENT_NOTICE_FAILURE });
        }
    } catch (e) {
        yield put({ type: types.ADD_ACKNOWLEDGEMENT_NOTICE_FAILURE });
        console.log(e)
    }
}

function* getNoticeCircularAcknowledgementList(action) {
    try {
        const result = yield call(req.getNoticeCircularAcknowledgementListReq, action)
        // result.data.limit = action?.data?.limit;
        if (result.statusCode === 200) {
            yield put({ type: types.GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST_SUCCESS, payload: result.data?.length ? result.data : [] })
        }
        else {
            yield put({ type: types.GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST_FAILURE, message: result?.response?.data?.message })

        }
    }
    catch (e) {
        console.log(e)
    }
}

function* getNoticeCircularAuditLogs(action) {
    try {
        const result = yield call(req.getNoticeCircularAuditLogsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_NOTICE_CIRCULAR_AUDIT_LOG_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_NOTICE_CIRCULAR_AUDIT_LOG_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* noticeAndCircularSaga() {
    yield takeLatest(types.GET_NOTICE_CIRCULAR_LIST, getNoticeCircularList)
    yield takeLatest(types.RESEND_NOTICE_CIRCULAR, resendNoticeCircular)
    yield takeLatest(types.DELETE_NOTICE_CIRCULAR, deleteNoticeCircular)
    yield takeLatest(types.GET_NOTICE_CIRCULAR_DETAILS, getNoticeCircularDetails)
    yield takeLatest(types.PRINT_NOTICE_CIRCULAR, printNoticeCircular)
    yield takeLatest(types.POST_NOTICE_CIRCULAR_COMMENT, postNoticeCircularComment)
    yield takeLatest(types.ADD_NOTICE_CIRCULAR, addNoticeCircular)
    yield takeLatest(types.UPDATE_NOTICE_CIRCULAR, updateNoticeCircular)
    yield takeLatest(types.GET_NOTICE_CIRCULAR_TYPES, getNoticeCirularTypes)
    yield takeLatest(types.GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE, getNoticeCirularManagingCommittee)
    yield takeLatest(types.GET_NOTICE_CIRCULAR_ARCHIVE_LIST, getNoticeCircularArchiveList)
    yield takeLatest(types.DELETE_NOTICE_CIRCULAR_ARCHIVE, deleteNoticeCircularArchive)
    yield takeLatest(types.ADD_ACKNOWLEDGEMENT_NOTICE, addAcknowledgementNotice);
    yield takeLatest(types.GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST, getNoticeCircularAcknowledgementList);
    yield takeLatest(types.GET_NOTICE_CIRCULAR_AUDIT_LOG, getNoticeCircularAuditLogs);

}