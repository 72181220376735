import {
  INVOICE_SETTINGS_TAXABLE_INVOICE_ENDPOINT,
  INVOICE_SETTINGS_DISPLAY_SETTINGS_ENDPOINT,
  INVOICE_SETTINGS_FUNCTIONAL_SETTING_ENDPOINT,
  ACCOUNT_LEDGER_MAPPING_ENDPOINT,
  APPLY_FOR_TENANCY_ENDPOINT,
  BANK_DETAILS_ENDPOINT,
  CONTACT_ENDPOINT,
  COMPLAINT_DASHBOARD_ENDPOINT,
  LOG_A_COMPLAINT_ENDPOINT,
  CREDIT_DEBIT_NOTE_CAUSE_ENDPOINT,
  EMPLOYEE_ENDPOINT,
  HOME_ENDPOINT,
  LEAVE_AND_LICENSE_ENDPOINT,
  MAINTENANCE_STAFF_LIST_ENDPOINT,
  MANAGING_COMMITTEE_ENDPOINT,
  MY_INVOICE_ENDPOINT,
  OCCUPANTS_INFO_ENDPOINT,
  SOCIETY_PROFILE_ENDPOINT,
  SOCIETY_STAFF_ENDPOINT,
  SPECIFIC_DETAILS_ENDPOINT,
  TENANTS_INFO_ENDPOINT,
  VIEW_INVITES_ENDPOINT,
  WINGS_AND_FLATS_ENDPOINT,
  MANAGE_COMPLAINT_CATEGORY_ENDPOINT,
  DUE_REMINDER_ENDPOINT,
  INVOICE_DETAILS_MAINTENANCE_AND_OTHER_CHARGES,
  MANAGE_ADVANCE_NOTE_ENDPOINT,
  DEBIT_NOTE_HISTORY_ENDPOINT,
  DELETE_RECEIPT_ENDPOINT,
  CHEQUE_DEPOSIT_SLIP_ENDPOINT,
  BULK_UPLOAD_OCCUPANTS_ENDPOINT,
  BULK_UPLOAD_TENANTS_ENDPOINT,
  BULK_UPLOAD_STAFF_PERSONNEL_ENDPOINT,
  BULK_UPLOAD_OPENING_BALANCE_ENDPOINT,
  GST_AND_INVOICE_SETTINGS_ENDPOINT,
  MANAGE_INVOICE_CURRENT_ENDPOINT,
  RECEIPTS_TRANSACTIONS_ENDPOINT,
  MEMBER_DUE_SUMMARY_ENDPOINT,
  MEMBER_BILL_DUE_DETAILS_ENDPOINT,
  OPENING_BALANCE_ENDPOINT,
  REVIEW_ENDPOINT,
  CONTENT_MANAGEMENT_TEMPLATE,
  SMS_CREDIT_PACKAGES_ENDPOINT,
  SMS_HISTORY_LIST_ENDPOINT,
  MANAGE_EMPLOYEE_ROLES_ENDPOINT,
  MANAGE_OCCUPANT_ROLES_ENDPOINT,
  MANAGE_SOCIETY_ROLES_ENDPOINT,
  MANAGE_FAMILY_ROLES_ENDPOINT,
  SOCIETY_FORMS_AND_BYLAWS_ENDPOINT,
  MANAGE_DEBIT_CURRENT_ENDPOINT,
  GENERAL_COMPLAINT_SETTINGS_ENDPOINT,
  DEBIT_NOTE_INVOICE_REPORT_ENDPOINT,
  VISITOR_DASHBOARD_ENDPOINT,
  MANAGE_PARKING_TYPE_ENDPOINT,
  SOCIETY_PARKING_CHARGES_ENDPOINT,
  VIEW_BOOKINGS_ENDPOINT,
  VEHICLE_DASHBOARD_ENDPOINT,
  ADD_FACILITY_ENDPOINT,
  ADD_TIME_SLOT_ENDPOINT,
  ADD_RATE_CHART_ENDPOINT,
  COUNTED_INVOICE_REPORT_ENDPOINT,
  ALL_FACILITY_ENDPOINT,
  ALL_DOCUMENTS_ENDPOINT,
  I_FORM_ENDPOINT,
  LIEN_MORTGAGE_REGISTER_ENDPOINT,
  COMPLIANCE_DOCUMENTS_ISSUED_ENDPOINT,
  INVOICE_LIST_ENDPOINT,
  MANAGE_CREDIT_NOTE_ENDPOINT,
  SAVED_NOTICE_ENDPOINT,
  SENT_NOTICE_ENDPOINT,
  SCHEDULE_A_MEETING_ENDPOINT,
  CREATE_MINUTES_FOR_MEETINGS_ENDPOINT,
  SAVED_MINUTES_ENDPOINT,
  J_FORM_ENDPOINT,
  SHARE_REGISTER_ENDPOINT,
  PROPERTY_REGISTER_ENDPOINT,
  NOMINATION_REGISTER_ENDPOINT,
  TRANSFER_FLAT_DOCUMENTS_ENDPOINT,
  VIEW_BILL_GENERATION_ENDPOINT,
  BILL_REPORTS_ENDPOINT,
  ACCOUNT_REPORTS_ENDPOINT,
  FINANCE_REPORTS_ENDPOINT,
  PHOTO_GALLERY_ENDPOINT,
  NOTICE_AND_CIRCULARS_ENDPOINT,
  INVOICE_SERIES_SETUP_ENDPOINT,
  INVOICE_REPORT_ENDPOINT,
  RECEIPT_REPORT_ENDPOINT,
  DEBIT_NOTE_RECEIPT_REPORT_ENDPOINT,
  SUPPLEMENTARY_BILL_REPORT_ENDPOINT,
  DELETED_INVOICE_REPORT_ENDPOINT,
  DELETED_DEBIT_NOTE_REPORT_ENDPOINT,
  INVOICE_INTEREST_REPORT_ENDPOINT,
  INTEREST_CALCULATION_REPORT_ENDPOINT,
  RECONCILIATION_REPORT_ENDPOINT,
  PRINT_MEMBER_BILL_DUE_DETAILS_ENDPOINT,
  DEBITNOTE_INTEREST_CALCULATION_REPORT_ENDPOINT,
  INVOICE_AGING_REPORT_ENDPOINT,
  EDIT_INVOICE_LIST_ENDPOINT,
  ADD_CAUSE_ENDPOINT,
  PRINT_CHEQUE_DEPOSIT_SLIP_ENDPOINT,
  SOCIETY_EMAIL_REPORT_ENDPOINT,
  MEMBER_LEDGER_REPORT_ENDPOINT,
  INVOICE_AUDIT_REPORT_ENDPOINT,
  E_INVOICE_REPORT_ENDPOINT,
  GST_REPORT_ENDPOINT,
} from "../../Routing/routes";
import * as permissions from "../../Routing/permissions";
const apiImplemented =
  window.location.origin == "https://stg-neo2.zipgrid.com" ||
  window.location.origin == "https://dev-neo2.zipgrid.com" ||
  window.location.origin == "http://localhost:3001" ||
  window.location.origin == "http://localhost:3000";
let HeaderData = [
  {
    label: "Setup & Records",
    apiImplemented: true,
    menuList: [
      {
        label: "Building",
        apiImplemented: true,
        permissionPath: [
          permissions.VIEW_SOCIETY_PROFILE_PERMISSION,
          permissions.EDIT_SPECIFIC_DETAILS_PERMISSION,
          permissions.ADD_WINGS_PERMISSION,
          permissions.ADD_BANK_DETAILS_PERMISSION,
        ],
        menuList: [
          {
            label: "Society Profile",
            link: SOCIETY_PROFILE_ENDPOINT,
            permissionPath: [permissions.VIEW_SOCIETY_PROFILE_PERMISSION],
            apiImplemented: true,
          },
          {
            label: "Manage Society Modules",
            apiImplemented: true,
            link: MANAGE_SOCIETY_ROLES_ENDPOINT,
          },
          {
            label: "Specific Details",
            link: SPECIFIC_DETAILS_ENDPOINT,
            permissionPath: [permissions.EDIT_SPECIFIC_DETAILS_PERMISSION],
            apiImplemented: true,
          },
          {
            label: "Wings & Flats",
            link: WINGS_AND_FLATS_ENDPOINT,
            apiImplemented: true,
            permissionPath: [permissions.ADD_WINGS_PERMISSION],
          },
          {
            label: "Bank Details",
            link: BANK_DETAILS_ENDPOINT,
            apiImplemented: true,
            permissionPath: [permissions?.ADD_BANK_DETAILS_PERMISSION],
          },
        ],
      },
      {
        label: "Occupants & Tenants",
        apiImplemented: true,
        // permissionPath: [
        //   permissions.VIEW_TENANTS_PERMISSION,
        //   permissions.VIEW_LEAVE_AND_LICENSE_PERMISSION,
        //   permissions.VIEW_INVITIES_PERMISSION
        // ],
        menuList: [
          {
            label: "Occupants Info",
            link: OCCUPANTS_INFO_ENDPOINT,
            apiImplemented: true,
          },
          {
            label: "Tenant Info",
            link: TENANTS_INFO_ENDPOINT,
            apiImplemented: true,
            permissionPath: [permissions.VIEW_TENANTS_PERMISSION],
          },
          {
            label: "Leave & License",
            link: LEAVE_AND_LICENSE_ENDPOINT,
            apiImplemented: true,
            permissionPath: [permissions.VIEW_LEAVE_AND_LICENSE_PERMISSION],
          },
          {
            label: "Apply for Tenancy",
            link: APPLY_FOR_TENANCY_ENDPOINT,
            apiImplemented: true,
          },
          {
            label: "View Invites",
            link: VIEW_INVITES_ENDPOINT,
            apiImplemented: true,
            permissionPath: [permissions.VIEW_INVITIES_PERMISSION],
          },
        ],
      },
      {
        label: "Staff Management",
        apiImplemented: true,
        permissionPath: [
          permissions.VIEW_STAFF_PERMISSION,
          permissions.VIEW_EMPLOYEE_PERMISSION,
          permissions.VIEW_MANAGING_COMMITTEE_PERMISSION,
        ],

        menuList: [
          {
            label: "Staff Personnel",
            link: SOCIETY_STAFF_ENDPOINT,
            apiImplemented: true,
            permissionPath: [permissions.VIEW_STAFF_PERMISSION],
          },
          {
            label: "Maintenance Agency",
            link: MAINTENANCE_STAFF_LIST_ENDPOINT,
            apiImplemented: true,
            permissionPath: [permissions.VIEW_STAFF_PERMISSION],
          },
          {
            label: "Employee Management",
            link: EMPLOYEE_ENDPOINT,
            apiImplemented: true,
            permissionPath: [permissions.VIEW_EMPLOYEE_PERMISSION],
          },
          {
            label: "Managing Committee",
            link: MANAGING_COMMITTEE_ENDPOINT,
            apiImplemented: true,
            permissionPath: [permissions.VIEW_MANAGING_COMMITTEE_PERMISSION],
          },
        ],
      },
      // {
      //   label: "Billing & Invoice Setup",
      //   apiImplemented: apiImplemented,
      //   link: apiImplemented
      //     ? INVOICE_LIST_ENDPOINT
      //     : "https://neo-archived.zipgrid.com/",
      //   // link: "https://neo-archived.zipgrid.com/",
      //   apiImplemented: true,
      // menuList: [
      //   {
      //     label: "Invoice Head Setup",
      //     link: apiImplemented
      //       ? `${ACCOUNT_LEDGER_MAPPING_ENDPOINT}/1`
      //       : "https://neo-archived.zipgrid.com/",
      //     // link: "https://neo-archived.zipgrid.com/",
      //     apiImplemented: apiImplemented,
      //   },
      //   {
      //     label: "Invoice Settings",
      //     link: apiImplemented
      //       ? INVOICE_LIST_ENDPOINT
      //       : "https://neo-archived.zipgrid.com/",
      //     // link: "https://neo-archived.zipgrid.com/",
      //     apiImplemented: true,
      //   },
      //   {
      //     label: "Taxable Format Setting",
      //     link: apiImplemented
      //       ? `${INVOICE_SETTINGS_TAXABLE_INVOICE_ENDPOINT}/1`
      //       : "https://neo-archived.zipgrid.com/",
      //     // link: "https://neo-archived.zipgrid.com/",
      //     apiImplemented: true,
      //   },
      //   {
      //     label: "Invoice Series Setup",
      //     link: apiImplemented
      //       ? `${INVOICE_SERIES_SETUP_ENDPOINT}/1`
      //       : "https://neo-archived.zipgrid.com/",
      //     // link: "https://neo-archived.zipgrid.com/",
      //     apiImplemented: true,
      //   },
      //   // {
      //   //   label: "Invoice Settings",
      //   //   // link: INVOICE_LIST_ENDPOINT,
      //   //   link: apiImplemented ? "#" : "https://neo-archived.zipgrid.com/",
      //   //   apiImplemented: apiImplemented,
      //   //   menuList: apiImplemented && [
      //   //     // {
      //   //     //   label: "Function Setting",
      //   //     //   link: apiImplemented
      //   //     //     ? INVOICE_SETTINGS_FUNCTIONAL_SETTING_ENDPOINT
      //   //     //     : "https://neo-archived.zipgrid.com/",
      //   //     //   // link: "https://neo-archived.zipgrid.com/",
      //   //     //   apiImplemented: true,
      //   //     // },

      //   //   ],
      //   // },
      //   // {
      //   //   label: "GST & NOC Settings",
      //   //   apiImplemented: apiImplemented,
      //   //   link: apiImplemented
      //   //     ? GST_AND_INVOICE_SETTINGS_ENDPOINT
      //   //     : "https://neo-archived.zipgrid.com/",
      //   //   // link: "https://neo-archived.zipgrid.com/",
      //   // },
      // ],
      // },
      {
        label: "Bulk Uploads",
        apiImplemented: true,
        // permissionPath: [
        //   permissions.BULK_UPLOAD_TENANTS_PERMISSION,
        //   permissions.BULK_UPLOAD_STAFF_PERSONNEL_PERMISSION,
        //   permissions.BULK_UPLOAD_OPENING_BALANCE_PERMISSION
        // ],
        menuList: [
          {
            label: "Bulk Upload Occupants",
            apiImplemented: true,
            link: BULK_UPLOAD_OCCUPANTS_ENDPOINT,
            permissionPath: [permissions.ADD_OCCUPANTS_PERMISSION],
          },
          {
            label: "Bulk upload Tenants",
            apiImplemented: true,
            link: BULK_UPLOAD_TENANTS_ENDPOINT,
            permissionPath: [permissions.BULK_UPLOAD_TENANTS_PERMISSION],
          },
          {
            label: "Bulk upload Staff Personnel",
            apiImplemented: true,
            link: BULK_UPLOAD_STAFF_PERSONNEL_ENDPOINT,
            permissionPath: [
              permissions.BULK_UPLOAD_STAFF_PERSONNEL_PERMISSION,
            ],
          },
          // {
          //   label: "Occupant Opening Balance Upload",
          //   apiImplemented: true,
          //   link: BULK_UPLOAD_OPENING_BALANCE_ENDPOINT,
          //   permissionPath: [
          //     permissions.BULK_UPLOAD_OPENING_BALANCE_PERMISSION,
          //   ],
          // },
        ],
      },
      {
        label: "Society Profile Setup",
        apiImplemented: true,
        menuList: [
          {
            label: "Review",
            apiImplemented: true,
            //  link: REVIEW_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
          {
            label: "Content Management Template",
            apiImplemented: true,
            link: CONTENT_MANAGEMENT_TEMPLATE,
            // link: "https://neo-archived.zipgrid.com/",
          },
          {
            label: "SMS Credit",
            apiImplemented: false,
            // link: SMS_CREDIT_PACKAGES_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
          {
            label: "Sent SMS History",
            apiImplemented: false,
            // link: SMS_HISTORY_LIST_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
        ],
      },
      {
        label: "Manage Roles & Rights",
        apiImplemented: true,
        menuList: [
          {
            label: "Manage Employee Roles",
            apiImplemented: true,
            link: MANAGE_EMPLOYEE_ROLES_ENDPOINT,
            permissionPath: [permissions.VIEW_EMPLOYEE_ROLE_PERMISSION],
          },
          {
            label: "Manage Occupant Roles",
            apiImplemented: true,
            link: MANAGE_OCCUPANT_ROLES_ENDPOINT,
            permissionPath: [permissions.VIEW_MEMBER_TITLE],
          },

          {
            label: "Manage Family Roles",
            apiImplemented: true,
            link: MANAGE_FAMILY_ROLES_ENDPOINT,
          },
        ],
      },
      {
        label: "Society Forms and Bye Laws",
        apiImplemented: true,
        link: SOCIETY_FORMS_AND_BYLAWS_ENDPOINT,
        permissionPath: [
          permissions.VIEW_SOCIETY_FORMS_AND_BYE_LAWS_PERMISSION,
        ],
      },
    ],
  },
  {
    label: "Social & Communications",
    apiImplemented: true,
    key: "missed_call_feature",
    menuList: [
      {
        label: "Lobby",
        apiImplemented: true,
        //permissionPath: [permissions.VIEW_EMPLOYEE_PERMISSION],
        menuList: [
          { label: "Society Wall", link: HOME_ENDPOINT, apiImplemented: false },
          // {
          //   label: "Employees",
          //   link: EMPLOYEE_ENDPOINT,
          //   apiImplemented: true,
          //   permissionPath: [permissions.VIEW_EMPLOYEE_PERMISSION],
          // },
          {
            label: "Important Contacts",
            // link: CONTACT_ENDPOINT,
            link: "https://neo-archived.zipgrid.com/",
            apiImplemented: false,
          },
          {
            label: "Document",
            link: ALL_DOCUMENTS_ENDPOINT,
            apiImplemented: true,
          },
        ],
      },
      {
        label: "Photo Gallery",
        apiImplemented: false,
        link: PHOTO_GALLERY_ENDPOINT,
      },
      {
        label: "Polls",
        apiImplemented: false,
        link: "https://neo-archived.zipgrid.com/",
      },
      {
        label: "Forums",
        apiImplemented: false,
        link: "https://neo-archived.zipgrid.com/",
      },
      {
        label: "Events",
        apiImplemented: false,
        menuList: [
          {
            label: "Manage Events",
            apiImplemented: false,
            link: "https://neo-archived.zipgrid.com/",
          },
          {
            label: "SMS Notification",
            apiImplemented: false,
            link: "https://neo-archived.zipgrid.com/",
          },
          {
            label: "Email Notification",
            apiImplemented: false,
            link: "https://neo-archived.zipgrid.com/",
          },
          {
            label: "Notification Template",
            apiImplemented: false,
            link: "https://neo-archived.zipgrid.com/",
          },
        ],
      },
      {
        label: "Notice & Circular",
        apiImplemented: true,
        link: NOTICE_AND_CIRCULARS_ENDPOINT,
      },
      {
        label: "Vendor List",
        apiImplemented: false,
        link: "https://neo-archived.zipgrid.com/",
      },
    ],
  },
  {
    label: "Finance",
    apiImplemented: true,
    key: "payment",
    menuList: [
      { label: "My Invoice", link: MY_INVOICE_ENDPOINT, apiImplemented: true },
      {
        label: "Settings",
        link: "",
        apiImplemented: true,
        menuList: [
          {
            label: "Billing Setup",
            link: "",
            // link: "https://neo-archived.zipgrid.com/",
            apiImplemented: true,
            menuList: [
              {
                label: "Invoice Setup",
                link: INVOICE_LIST_ENDPOINT,
                apiImplemented: true,
              },
              // {
              //   label: "Maintenance Tariff Setup",
              //   link: "",
              //   // apiImplemented
              //   //   ? `${ACCOUNT_LEDGER_MAPPING_ENDPOINT}/1`
              //   //   : "https://neo-archived.zipgrid.com/",
              //   // link: "https://neo-archived.zipgrid.com/",
              //   apiImplemented: false,
              // },
              // {
              //   label: "Utility Setup",
              //   // link: `${EDIT_INVOICE_LIST_ENDPOINT}/1`,
              //   link: "",
              //   apiImplemented: false,
              // },
              // {
              //   label: "Invoice Series",
              //   link: apiImplemented
              //     ? `${INVOICE_SERIES_SETUP_ENDPOINT}/1`
              //     : "https://neo-archived.zipgrid.com/",
              //   // link: "https://neo-archived.zipgrid.com/",
              //   apiImplemented: true,
              // },
              {
                label: "Master Tariff",
                link: INVOICE_DETAILS_MAINTENANCE_AND_OTHER_CHARGES,
                apiImplemented: true,
              },

              // { label: "Tax Invoice PDF", link: "", apiImplemented: false },
            ],
          },
          {
            label: "Debit Note Tariff",
            link: CREDIT_DEBIT_NOTE_CAUSE_ENDPOINT,
            apiImplemented: true,
          },
          {
            label: "Opening Balance",
            link: OPENING_BALANCE_ENDPOINT,
            apiImplemented: true,
          },
          {
            label: "Reminders",
            link: "",
            apiImplemented: true,
            menuList: [
              {
                label: "Due Reminders",
                link: DUE_REMINDER_ENDPOINT,
                apiImplemented: true,
              },
            ],
          },
        ],
      },
      {
        label: "Billing Management",
        link: "",
        apiImplemented: true,
        menuList: [
          {
            label: "Manage Invoice",
            link: MANAGE_INVOICE_CURRENT_ENDPOINT,
            apiImplemented: true,
          },
          {
            label: "Manage Debit Note",
            apiImplemented: true,
            link: MANAGE_DEBIT_CURRENT_ENDPOINT,
          },
          {
            label: "Manage Credit Note",
            apiImplemented: true,
            link: MANAGE_CREDIT_NOTE_ENDPOINT,
          },
          {
            label: "Manage Advance Note",
            apiImplemented: true,
            link: MANAGE_ADVANCE_NOTE_ENDPOINT,
          },
          {
            label: "Reconciliation Report",
            link: RECONCILIATION_REPORT_ENDPOINT,
            apiImplemented: true,
          },
        ],
      },

      {
        label: "Billing Reports",
        link: "",
        apiImplemented: true,
        menuList: [
          {
            label: "Invoice",
            link: "",
            apiImplemented: true,
            menuList: [
              {
                label: "Invoice Report",
                link: INVOICE_REPORT_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Counted Invoice Report",
                link: COUNTED_INVOICE_REPORT_ENDPOINT,
                apiImplemented: true,
              },
              // {
              //   label: "Supplimentary Bill Report",
              //   link: "",
              //   apiImplemented: false,
              // },
              {
                label: "Deleted Invoice Report",
                link: DELETED_INVOICE_REPORT_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Invoice Receipt Report",
                link: RECEIPTS_TRANSACTIONS_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Invoice Interest Report",
                link: INVOICE_INTEREST_REPORT_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Interest Calculation Report",
                link: INTEREST_CALCULATION_REPORT_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Invoice Aging Report",
                link: INVOICE_AGING_REPORT_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Audit Report",
                link: INVOICE_AUDIT_REPORT_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "GST Report",
                link: GST_REPORT_ENDPOINT,
                apiImplemented: true,
              },
            ],
          },
          {
            label: "Debit Note",
            link: "",
            apiImplemented: true,
            menuList: [
              {
                label: "Debit Notes",
                link: DEBIT_NOTE_HISTORY_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Debit Note Report",
                link: DEBIT_NOTE_INVOICE_REPORT_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Debit Note Collection Report",
                link: DEBIT_NOTE_RECEIPT_REPORT_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Deleted Debit Note Report",
                link: DELETED_DEBIT_NOTE_REPORT_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Debit Note Interest Calculation Report",
                link: DEBITNOTE_INTEREST_CALCULATION_REPORT_ENDPOINT,
                apiImplemented: true,
              },
            ],
          },
          {
            label: "Receipts",
            link: "",
            apiImplemented: true,
            menuList: [
              {
                label: "Receipt Report",
                link: RECEIPT_REPORT_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Cheque Deposit Slip",
                link: CHEQUE_DEPOSIT_SLIP_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Deleted Reciepts",
                link: DELETE_RECEIPT_ENDPOINT,
                apiImplemented: true,
              },
            ],
          },
          {
            label: "Statutory Fund",
            link: "",
            apiImplemented: true,
            menuList: [
              { label: "Repair Fund Report", link: "", apiImplemented: false },
              {
                label: "Sinking Fund Report",
                link: "",
                apiImplemented: false,
              },
            ],
          },
          {
            label: "Outstanding Report",
            link: "",
            apiImplemented: true,
            menuList: [
              {
                label: "Member Bill Due Details",
                link: MEMBER_BILL_DUE_DETAILS_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Member Ledger Report",
                link: MEMBER_LEDGER_REPORT_ENDPOINT,
                apiImplemented: true,
              },
              {
                label: "Member Due Summary",
                link: MEMBER_DUE_SUMMARY_ENDPOINT,
                apiImplemented: true,
              },
            ],
          },
          {
            label: "PG Settlement Report",
            link: "https://auth.easebuzz.in/easebuzz/login?next=dashboard.easebuzz.in/dashboard",
            apiImplemented: true,
            openInNewpage: true,
          },
          {
            label: "E Invoice Report",
            link: E_INVOICE_REPORT_ENDPOINT,
            apiImplemented: true,
          },
        ],
      },
      {
        label: "Accounts",
        link: `${process.env.REACT_APP_ACCOUNTS_BASE_URL}/rnmaccounts/section_login/login.php`,
        permissionPath: [
          permissions.ACCOUNT_PERMISSION,
          permissions.SINKING_FUNCTION_REPORT_PERMISSION,
          permissions.RECEIVALBE_REPORT_PERMISSION,
          permissions.INCOME_AND_EXPENDITURE_PERMISSION,
          permissions.EXPENDITURE_REPORT_PERMISSION,
          permissions.BALANCE_SHEET_REPORT_PERMISSION,
          permissions.TRIAL_BALANCE_PERMISSION,
          permissions.LEDGER_REPORT_PERMISSION,
          permissions.MEMBER_LEDGER_PERMISSION,
          permissions.FIXED_DEPOSIT_LIST_PERMISSION,
          permissions.FIXED_ASSETS_REPORT_PERMISSION,
          permissions.DELETE_SAVE_DRAFT_BRS_PERMISSION,
          permissions.JOURNAL_ENTRY_EDIT_AND_MORE_OPTION_PERMISSION,
          permissions.SHOW_MEMBER_ACCOUNT_IN_JOURNAL_ENTRY_PERMISSION,
        ],
        apiImplemented: true,
      },
    ],
    // menuList: [
    //   { label: "My Invoice", link: MY_INVOICE_ENDPOINT, apiImplemented: true },
    //   {
    //     label: "Billing Management",
    //     apiImplemented: true,
    //     menuList: [
    //       {
    //         label: "Manage Advance Note",
    //         link: MANAGE_ADVANCE_NOTE_ENDPOINT,
    //         apiImplemented: true,
    //       },
    //       {
    //         label: "Manage Invoice",
    //         apiImplemented: true,
    //         link: MANAGE_INVOICE_CURRENT_ENDPOINT,
    //       },
    //       {
    //         label: "Manage Debit Note",
    //         apiImplemented: apiImplemented,
    //         link: MANAGE_DEBIT_CURRENT_ENDPOINT,
    //         // link: "https://neo-archived.zipgrid.com/",
    //       },
    //       {
    //         label: "Manage Credit Note",
    //         apiImplemented: true,
    //         link: MANAGE_CREDIT_NOTE_ENDPOINT,
    //       },
    //     ],
    //   },
    //   {
    //     label: "Billing Report",
    //     apiImplemented: true,
    //     menuList: [
    //       {
    //         label: "Debit Notes",
    //         link: DEBIT_NOTE_HISTORY_ENDPOINT,
    //         apiImplemented: true,
    //       },
    //       {
    //         label: "Deleted Reciepts",
    //         link: DELETE_RECEIPT_ENDPOINT,
    //         apiImplemented: true,
    //       },
    //       {
    //         label: "Cheque Deposit Slip",
    //         link: CHEQUE_DEPOSIT_SLIP_ENDPOINT,
    //         apiImplemented: true,
    //       },
    //       {
    //         label: "Counted Invoice Report",
    //         link: COUNTED_INVOICE_REPORT_ENDPOINT,
    //         apiImplemented: true,
    //         openInNewpage: false,
    //       },
    //       {
    //         label: "Member Bill Due Summary",
    //         link: `${PRINT_MEMBER_BILL_DUE_DETAILS_ENDPOINT}?reportType=all&flatArea=No`,
    //         apiImplemented: true,
    //       },
    //       {
    //         label: "Receipts/Transactions",
    //         apiImplemented: true,
    //         link: RECEIPTS_TRANSACTIONS_ENDPOINT,
    //       },
    //       {
    //         label: "Member Due Summary",
    //         apiImplemented: true,
    //         link: MEMBER_DUE_SUMMARY_ENDPOINT,
    //       },
    //       {
    //         label: "Member Bill Due Details",
    //         apiImplemented: true,
    //         link: MEMBER_BILL_DUE_DETAILS_ENDPOINT,
    //       },
    //       {
    //         label: "Opening Balance",
    //         apiImplemented: false,
    //         link: OPENING_BALANCE_ENDPOINT,
    //       },
    //       {
    //         label: "PG Settlement Report",
    //         link: "https://auth.easebuzz.in/easebuzz/login?next=dashboard.easebuzz.in/dashboard",
    //         apiImplemented: true,
    //         openInNewpage: true,
    //       },
    //     ],
    //   },
    //   {
    //     label: "Accounts",
    //     link: `${process.env.REACT_APP_ACCOUNTS_BASE_URL}/rnmaccounts/section_login/login.php`,
    //     permissionPath: [
    //       permissions.ACCOUNT_PERMISSION,
    //       permissions.SINKING_FUNCTION_REPORT_PERMISSION,
    //       permissions.RECEIVALBE_REPORT_PERMISSION,
    //       permissions.INCOME_AND_EXPENDITURE_PERMISSION,
    //       permissions.EXPENDITURE_REPORT_PERMISSION,
    //       permissions.BALANCE_SHEET_REPORT_PERMISSION,
    //       permissions.TRIAL_BALANCE_PERMISSION,
    //       permissions.LEDGER_REPORT_PERMISSION,
    //       permissions.MEMBER_LEDGER_PERMISSION,
    //       permissions.FIXED_DEPOSIT_LIST_PERMISSION,
    //       permissions.FIXED_ASSETS_REPORT_PERMISSION,
    //       permissions.DELETE_SAVE_DRAFT_BRS_PERMISSION,
    //       permissions.JOURNAL_ENTRY_EDIT_AND_MORE_OPTION_PERMISSION,
    //       permissions.SHOW_MEMBER_ACCOUNT_IN_JOURNAL_ENTRY_PERMISSION,
    //     ],
    //     apiImplemented: true,
    //     openInNewpage: true,
    //   },
    //   {
    //     label: "Settings",
    //     apiImplemented: true,
    //     menuList: [
    //       {
    //         label: "Debit note Tariff Header",
    //         link: CREDIT_DEBIT_NOTE_CAUSE_ENDPOINT,
    //         apiImplemented: true,
    //       },
    //       {
    //         label: "Invoice Details",
    //         link: INVOICE_DETAILS_MAINTENANCE_AND_OTHER_CHARGES,
    //         apiImplemented: true,
    //       },
    //       {
    //         label: "Due Reminders",
    //         link: DUE_REMINDER_ENDPOINT,
    //         apiImplemented: true,
    //       },
    //     ],
    //   },
    // ],
  },
  {
    label: "Compliance",
    apiImplemented: false,
    menuList: [
      {
        label: "Registers",
        apiImplemented: false,
        menuList: [
          {
            label: "I Form",
            apiImplemented: false,
            // link: I_FORM_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
          {
            label: "J Form",
            apiImplemented: false,
            //  link: J_FORM_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
          // { label: "Asset Register", apiImplemented: false },
          {
            label: "Share Register",
            apiImplemented: false,
            // link: SHARE_REGISTER_ENDPOINT,
            link: "https://neo-archived.zipgrid.com/",
            openInNewpage: true,
          },
          {
            label: "Property Register",
            apiImplemented: false,
            // link: PROPERTY_REGISTER_ENDPOINT,
            link: "https://neo-archived.zipgrid.com/",
            openInNewpage: true,
          },
          {
            label: "Nomination Register",
            apiImplemented: false,
            // link: NOMINATION_REGISTER_ENDPOINT,
            link: "https://neo-archived.zipgrid.com/",
            openInNewpage: true,
          },
          {
            label: "Lien/Mortgage Register",
            apiImplemented: false,
            // link: LIEN_MORTGAGE_REGISTER_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
        ],
      },
      {
        label: "Meetings",
        apiImplemented: false,
        menuList: [
          {
            label: " View Saved Notices",
            apiImplemented: false,
            //  link: SAVED_NOTICE_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
          {
            label: " View Sent Notices",
            apiImplemented: false,
            // link: SENT_NOTICE_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
          {
            label: "Schedule a Meeting",
            apiImplemented: false,
            //  link: SCHEDULE_A_MEETING_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
          {
            label: "Create Minutes",
            apiImplemented: false,
            // link: CREATE_MINUTES_FOR_MEETINGS_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
          {
            label: "Saved Minutes",
            apiImplemented: false,
            // link: SAVED_MINUTES_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
          { label: "Minutes Register", apiImplemented: false },
          { label: "Track action of Minutes Register", apiImplemented: false },
          { label: "Meeting Log", apiImplemented: false },
          { label: "Minutes Log", apiImplemented: false },
        ],
      },
      {
        label: "Compliance Administration",
        apiImplemented: false,
        menuList: [
          {
            label: "Document Issue",
            apiImplemented: false,
            //  link: COMPLIANCE_DOCUMENTS_ISSUED_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
          {
            label: "Transfer Flat",
            apiImplemented: false,
            //  link: TRANSFER_FLAT_DOCUMENTS_ENDPOINT
            link: "https://neo-archived.zipgrid.com/",
          },
        ],
      },
    ],
  },
  {
    label: "Complaints",
    apiImplemented: true,
    key: "help_desk",
    permissionPath: [
      permissions.COMPLAINT_TYPE_SETTING_PERMISSION,
      permissions.LOG_A_COMPLAINT_PERMISSION,
      permissions.VIEW_COMPLAINT_CATEGORY_PERMISSION,
      permissions.VIEW_COMPLAINT_DASHBOARD_PERMISSION,
      permissions.VIEW_COMPLAINT_SETTING_PERMISSION,
      permissions.VIEW_COMPLAINT_ESCALATION_LEVEL_PERMISSION,
      permissions.COMPLAINT_EMAIL_SMS_SETTING_PERMISSION,
    ],
    menuList: [
      {
        label: "Complaint Dashboard",
        link: COMPLAINT_DASHBOARD_ENDPOINT,
        apiImplemented: true,
        permissionPath: [permissions.VIEW_COMPLAINT_DASHBOARD_PERMISSION],
      },
      {
        label: "Log a Complaint",
        link: LOG_A_COMPLAINT_ENDPOINT,
        apiImplemented: true,
        permissionPath: [permissions.LOG_A_COMPLAINT_PERMISSION],
      },
      {
        label: "Manage Complaint Category",
        link: MANAGE_COMPLAINT_CATEGORY_ENDPOINT,
        apiImplemented: true,
        permissionPath: [permissions.VIEW_COMPLAINT_CATEGORY_PERMISSION],
      },
      {
        label: "General Complaint Setting",
        link: GENERAL_COMPLAINT_SETTINGS_ENDPOINT,
        apiImplemented: true,
        permissionPath: [
          permissions.VIEW_COMPLAINT_SETTING_PERMISSION,
          permissions.VIEW_COMPLAINT_ESCALATION_LEVEL_PERMISSION,
          permissions.COMPLAINT_EMAIL_SMS_SETTING_PERMISSION,
          permissions.COMPLAINT_TYPE_SETTING_PERMISSION,
        ],
      },
    ],
  },
  {
    label: "Reports",
    apiImplemented: true,
    menuList: [
      {
        label: "Society Report",
        apiImplemented: true,
        menuList: [
          {
            label: "Member Ledger Report",
            link: MEMBER_LEDGER_REPORT_ENDPOINT,
            apiImplemented: true,
          },
          { label: "Parking Report", apiImplemented: false },
          {
            label: "Society Email Report",
            link: SOCIETY_EMAIL_REPORT_ENDPOINT,
            apiImplemented: true,
          },
          { label: "Transfer Register Report", apiImplemented: false },
          { label: "Vacant Flat Report", apiImplemented: false },
          { label: "Staff Attendance Report", apiImplemented: false },
          { label: "App Download Report", apiImplemented: false },
        ],
      },
      // {
      //   label: "Finance Report",
      //   apiImplemented: true,
      //   menuList: [
      //     {
      //       label: "Debit Note Invoice Report",
      //       link: DEBIT_NOTE_INVOICE_REPORT_ENDPOINT,
      //       apiImplemented: true,
      //     },
      //     {
      //       label: "Invoice Report",
      //       link: INVOICE_REPORT_ENDPOINT,
      //       apiImplemented: true,
      //     },
      //     {
      //       label: "Receipt Report",
      //       link: RECEIPT_REPORT_ENDPOINT,
      //       apiImplemented: true,
      //     },
      //     // {
      //     //   label: "Supplementary Bill Report",
      //     //   // link: SUPPLEMENTARY_BILL_REPORT_ENDPOINT,
      //     //   apiImplemented: false,
      //     // },
      //     {
      //       label: "Debit Note Receipt Report",
      //       link: DEBIT_NOTE_RECEIPT_REPORT_ENDPOINT,
      //       apiImplemented: true,
      //     },
      //     {
      //       label: "Deleted Invoice Report",
      //       link: DELETED_INVOICE_REPORT_ENDPOINT,
      //       apiImplemented: true,
      //     },
      //     {
      //       label: "Deleted Debit Note Report",
      //       link: DELETED_DEBIT_NOTE_REPORT_ENDPOINT,
      //       apiImplemented: true,
      //     },
      //     {
      //       label: "Invoice Interest Report",
      //       link: INVOICE_INTEREST_REPORT_ENDPOINT,
      //       apiImplemented: true,
      //     },
      //     // { label: "Sinking Fund Report", apiImplemented: false },
      //     // { label: "Repair Fund Report", apiImplemented: false },
      //     {
      //       label: "Interest Calculation Report",
      //       link: "",
      //       // link: INTEREST_CALCULATION_REPORT_ENDPOINT,
      //       apiImplemented: false,
      //     },
      //     {
      //       label: "Reconciliation Report",
      //       link: RECONCILIATION_REPORT_ENDPOINT,
      //       apiImplemented: true,
      //     },
      //     { label: "Invoice Audit Report", apiImplemented: false },
      //     {
      //       label: "Debit Note Interest Calculation Report",
      //       link: DEBITNOTE_INTEREST_CALCULATION_REPORT_ENDPOINT,
      //       apiImplemented: true,
      //     },
      //     { label: "Invoice Aging Report", apiImplemented: false },
      //   ],
      // },
      {
        label: "Old Financial Year Reports",
        apiImplemented: true,
        menuList: [
          {
            label: "Accounts Reports",
            apiImplemented: true,
            link: ACCOUNT_REPORTS_ENDPOINT,
          },
          {
            label: "Finance Reports",
            apiImplemented: true,
            link: FINANCE_REPORTS_ENDPOINT,
          },
          {
            label: "Bill Generation Reports",
            apiImplemented: true,
            link: VIEW_BILL_GENERATION_ENDPOINT,
          },
          {
            label: "Bill Reports",
            apiImplemented: true,
            link: BILL_REPORTS_ENDPOINT,
          },
        ],
      },
    ],
  },
  {
    label: "Gate Management",
    apiImplemented: true,
    key: "vms",
    menuList: [
      {
        label: "Visitors In/Out",
        apiImplemented: true,
        link: VISITOR_DASHBOARD_ENDPOINT,
      },
      {
        label: "Parking Management",
        apiImplemented: true,
        menuList: [
          {
            label: "Manage Parking Type",
            apiImplemented: true,
            link: MANAGE_PARKING_TYPE_ENDPOINT,
            permissionPath: [
              permissions.VIEW_PARKING_TYPE_IN_OCCUPANTS_PERMISSION,
            ],
          },
          {
            label: "Parking Charges",
            apiImplemented: true,
            link: SOCIETY_PARKING_CHARGES_ENDPOINT,
            permissionPath: [permissions.VIEW_PARKING_CHARGES_PERMISSION],
          },
        ],
      },
      {
        label: "Vehicles In/Out",
        apiImplemented: true,
        link: VEHICLE_DASHBOARD_ENDPOINT,
        permissionPath: [permissions.VIEW_VEHICLE_IN_OUT_PERMISSION],
      },
    ],
  },
  {
    label: "Facility Management",
    apiImplemented: true,
    key: "booking",
    menuList: [
      {
        label: "View Bookings",
        apiImplemented: true,
        link: VIEW_BOOKINGS_ENDPOINT,
        permissionPath: [
          permissions.VIEW_BOOKING_PERMISSION,
          permissions.VIEW_OWN_BOOKING_PERMISSION,
        ],
      },
      {
        label: "Book A Facility",
        apiImplemented: true,
        link: ALL_FACILITY_ENDPOINT,
        permissionPath: [permissions.VIEW_FACILITY_PERMISSION],
      },
      {
        label: "Add New Facility",
        apiImplemented: true,
        link: ADD_FACILITY_ENDPOINT,
        permissionPath: [permissions.ADD_FACILITY_PERMISSION],
      },
      {
        label: "Add Timeslot",
        apiImplemented: true,
        link: ADD_TIME_SLOT_ENDPOINT,
        permissionPath: [permissions.ADD_TIME_SLOT_PERMISSION],
      },
      {
        label: "Add Rate Chart",
        apiImplemented: true,
        link: ADD_RATE_CHART_ENDPOINT,
        permissionPath: [permissions.ADD_RATE_CHART_PERMISSION],
      },
    ],
  },

  // { label: "Chats", apiImplemented: false },
];

// if (!apiImplemented) HeaderData.splice(2, 1);
export { HeaderData };
