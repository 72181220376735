import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
// import * as types from '../../actions/FinanceReqs/'
// import * as req from '../../../axios/request/FinanceReqs/BillingReportsReqs'
import * as req from '../../../axios/request/FinanceReqs/EinvoiceReq' 
import { notify } from '../../../utils/notification';

function* getEinvoiceReport(action) {
    try {
        const result = yield call(req.getEinvoiceReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_EINVOICE_REPORTS_SUCCESS, payload: result});  
        } else {
            yield put({ type: types.GET_EINVOICE_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
export default function* EinvoiceSaga() {
    yield takeLatest(
      types.GET_EINVOICE_REPORTS,
      getEinvoiceReport
    );
  }