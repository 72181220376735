import * as types from '../../index.types';

export function getSocietyStaffList(data) {
    return {
        type: types.GET_SOCIETY_STAFF_LIST,
        data
    };
}

export function getSocietyStaffProfileList(data) {
    return {
        type: types.GET_SOCIETY_STAFF_PROFILE_LIST,
        data
    };
}

export function getSocietyStaffDetails(data) {
    return {
        type: types.GET_SOCIETY_STAFF_DETAILS,
        data
    };
}

export function addSocietyStaff(data) {
    return {
        type: types.ADD_SOCIETY_STAFF,
        data
    };
}

export function downloadSocietyStaffExcelSheet(data) {
    return {
        type: types.DOWNLOAD_SOCIETY_STAFF_EXCEL_SHEET,
        data
    };
}

export function updateSocietyStaff(data) {
    return {
        type: types.UPDATE_SOCIETY_STAFF,
        data
    };
}

export function deleteSocietyStaff(data) {
    return {
        type: types.DELETE_SOCIETY_STAFF,
        data
    };
}

export function getSocietyWingFlatList(data) {
    return {
        type: types.GET_SOCIETY_WING_FLAT_LIST,
        data
    };
}

export function printSocietyStaffIDCards(data) {
    return {
        type: types.PRINT_SOCIETY_STAFF_CARD,
        data
    };
}

export function getSocietyStaffRatingData(data) {
    return {
        type: types.GET_SOCIET_STAFF_RATING_DATA,
        data
    };
}


export function getSocietyStaffRatingParameters(data) {
    return {
        type: types.GET_SOCIET_STAFF_RATING_PARAMETERS,
        data
    };
}

export function updateSocietyStaffRating(data) {
    return {
        type: types.UPDATE_SOCIET_STAFF_RATING_DATA,
        data
    };
}

export function sendStaffCodeSmsToStaff(data) {
    return {
        type: types.SEND_STAFF_CODE_SMS_TO_STAFF,
        data
    };
}

export function getIDCardFieldsData(data) {
    return {
        type: types.GET_ID_CARD_FIELDS_DATA,
        data
    };
}


export function updateIDCardFieldsData(data) {
    return {
        type: types.UPDATE_ID_CARD_FIELDS_DATA,
        data
    };
}

export function getSocietyStaffApprovalSetting(data) {
    return {
        type: types.GET_APPROVAL_SETTINGS_DATA,
        data
    };
}

export function updateSocietyStaffApprovalSetting(data) {
    return {
        type: types.UPDATE_APPROVAL_SETTINGS_DATA,
        data
    };
}

export function updateSocietyStaffApprovalStatus(data) {
    return {
        type: types.UPDATE_APPROVAL_STATUS_DATA,
        data
    };
}

export function downloadSocietyStaffSummaryReport(data) {
    return {
        type: types.DOWNLOAD_SOCIETY_STAFF_SUMMARY_REPORT,
        data
    };
}

export function getSocietyStaffAuditLogs(data) {
    return {
        type: types.GET_SOCIETY_STAFF_AUDIT_LOG,
        data
    };
}