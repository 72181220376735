import * as type from '../../../actions/index.types'

export function specificDetailsReducer(state = {
  loading: false,
  updateLoader: false,
  specificDetailsData: null,
  specificDetailsAuditLogData: null,
  error: null
}, action) {

  switch (action.type) {
    case type.GET_SPECIFIC_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case type.GET_SPECIFIC_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        specificDetailsData: action.payload
      }
    case type.GET_SPECIFIC_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        specificDetailsData: null,
      }

    case type.UPDATE_SPECIFIC_DETAILS:
      return {
        ...state,
        updateLoader: true,
      }
    case type.UPDATE_SPECIFIC_DETAILS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      }
    case type.UPDATE_SPECIFIC_DETAILS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }

    case type.GET_SPECIFIC_DETAILS_AUDIT_LOGS:
      return {
        ...state,
        loading: true,
      }
    case type.GET_SPECIFIC_DETAILS_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        specificDetailsAuditLogData: action.payload
      }
    case type.GET_SPECIFIC_DETAILS_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        specificDetailsAuditLogData: null,

      }

    default:
      return state
  }
}