import * as type from "../../actions/index.types";

export function logaComplaintReducer(
  state = {
    loading: false,
    updateLoader: false,
    loadingSettings: true,
    error: null,
    complaintNatureList: [],
    complaintType: [],
    wingsList: [],
    flatsList: [],
    logAComplaintAuditLogData: null
  },
  action
) {
  switch (action.type) {
    case type.GET_COMPLAINT_NATURE_LIST:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_COMPLAINT_NATURE_LIST_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        complaintNatureList: action.payload,
      };

    case type.GET_COMPLAINT_NATURE_LIST_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.GET_COMPLAINT_TYPE_LIST:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_COMPLAINT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        complaintType: action.payload,
      };

    case type.GET_COMPLAINT_TYPE_LIST_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.GET_COMPLAINT_WINGS_LIST:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_COMPLAINT_WINGS_LIST_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        wingsList: action.payload,
      };

    case type.GET_COMPLAINT_WINGS_LIST_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.GET_COMPLAINT_FLATS_LIST:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_COMPLAINT_FLATS_LIST_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        flatsList: action.payload,
      };

    case type.GET_COMPLAINT_FLATS_LIST_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.ADD_LOG_A_COMPLAINT:
      return {
        ...state,
        updateLoader: true,
      };

    case type.ADD_LOG_A_COMPLAINT_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };

    case type.ADD_LOG_A_COMPLAINT_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.EDIT_LOG_A_COMPLAINT:
      return {
        ...state,
        updateLoader: true,
      };

    case type.EDIT_LOG_A_COMPLAINT_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };

    case type.EDIT_LOG_A_COMPLAINT_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };


    case type.ADD_LOG_A_COMPLAINT_AUDIT_LOG:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case type.ADD_LOG_A_COMPLAINT_AUDIT_LOG_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        logAComplaintAuditLogData: action.payload,
      };

    case type.ADD_LOG_A_COMPLAINT_AUDIT_LOG_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        logAComplaintAuditLogData: null,
      };

    default:
      return state;
  }
}
