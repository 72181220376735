import * as types from '../index.types';

export function getNoticeCircularList(data) {
    return {
        type: types.GET_NOTICE_CIRCULAR_LIST,
        data
    };
}

export function resendNoticeCircular(data) {
    return {
        type: types.RESEND_NOTICE_CIRCULAR,
        data
    };
}
export function deleteNoticeCircular(data) {
    return {
        type: types.DELETE_NOTICE_CIRCULAR,
        data
    }

}

export function getNoticeCircularDetails(data) {
    return {
        type: types.GET_NOTICE_CIRCULAR_DETAILS,
        data
    }
}

export function printNoticeCircular(data) {
    return {
        type: types.PRINT_NOTICE_CIRCULAR,
        data
    }

}

export function postNoticeCircularComment(data) {
    return {
        type: types.POST_NOTICE_CIRCULAR_COMMENT,
        data
    }
}

export function addNoticeCircular(data) {
    return {
        type: types.ADD_NOTICE_CIRCULAR,
        data
    };
}
export function updateNoticeCircular(data) {
    return {
        type: types.UPDATE_NOTICE_CIRCULAR,
        data
    };
}
export function getNoticeCircularTypes(data) {
    return {
        type: types.GET_NOTICE_CIRCULAR_TYPES,
        data
    };
}

export function getNoticeCircularManagingCommittee(data) {
    return {
        type: types.GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE,
        data
    }
}
export function getNoticeCircularArchiveList(data) {
    return {
        type: types.GET_NOTICE_CIRCULAR_ARCHIVE_LIST,
        data
    }
}
export function deleteNoticeCircularArchive(data) {
    return {
        type: types.DELETE_NOTICE_CIRCULAR_ARCHIVE,
        data
    }

}

export function addAcknowledgementNotice(data) {
    return {
        type: types.ADD_ACKNOWLEDGEMENT_NOTICE,
        data
    }
}

export function getNoticeCircularAcknowledgementList(data) {
    return {
        type: types.GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST,
        data
    }
}

export function getNoticeCircularAuditLogs(data) {
    return {
        type: types.GET_NOTICE_CIRCULAR_AUDIT_LOG,
        data
    }
}