import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getReceiptDetails } from '../../../redux/actions/FinanceActions/BillingReportsAction';
import Layout from '../../Layout/Layout';
import { useSelector } from 'react-redux';
import ListHeaderBack from '../../../components/ListHeader/ListHeaderBack';
import moment from 'moment';
import { toWords } from 'number-to-words'
import Loader from '../../../components/Loader/Loader';
import { getFlatDetails } from '../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction';
import { validateDate } from '../../../utils/helpers/universalFunctions';

const invoiceDetails = [
    { "key": "propertytax", "label": "Property Tax" },
    { "key": "watercharges", "label": "Water Charges" },
    { "key": "electriccharges", "label": "Common Electricity Charges" },
    { "key": "repairnmaintenance", "label": "Repairs & Maintenance Fund" },
    { "key": "liftmaintenance", "label": "Expenses on Lift Maintenance" },
    { "key": "sinkingfund", "label": "Sinking Fund Contribution" },
    { "key": "housekeeping", "label": "Service Charges- House Keeping" },
    { "key": "officecharges", "label": "Service Charges- Office & Genral Ch" },
    { "key": "printingcharges", "label": "Service Charges- Printing & Stationery" },
    { "key": "parkingcharges", "label": "Parking Charges" },
    { "key": "defaultedcharges", "label": "Interest on the defaulted Charges" },
    { "key": "loancharges", "label": "Repayment of Loan and Interest" },
    { "key": "nonoccupancycharges", "label": "Non Occupancy Charges" },
    { "key": "insurancecharge", "label": "Insurance Charges" },
    { "key": "leaserent", "label": "Lease Rent" },
    { "key": "agriculturaltax", "label": "Non Agricultural Tax" },
    { "key": "otherchargesfn", "label": "Other Charges- Function Ch." },
    { "key": "othercharges", "label": "Other Charges-" },
    { "key": "repairfund", "label": "Major Repair Fund" },
    { "key": "paintingfund", "label": "Painting Fund" },
    { "key": "servicetax", "label": "SGST" },
    { "key": "Sb_Cess", "label": "CGST" },
    { "key": "Kk_Cess", "label": "IGST" },
    { "key": "servicecharge", "label": "Service Charge" },
    { "key": "securitycharges", "label": "Security Charges" },
    { "key": "type", "label": "Invoice" },
    { "key": "watertax", "label": "Water Tax" },
    { "key": "othertax", "label": "Other Tax" },
    { "key": "debitnote", "label": "Debit Note" },
    { "key": "chequereturncharges", "label": "Cheque Return Charges" },
    { "key": "other1", "label": "Other1" },
    { "key": "other2", "label": "Other2" },
    { "key": "other3", "label": "Other3" },
    { "key": "other4", "label": "Other4" },
    { "key": "roundoff", "label": "roundoff" }
]

const arrears = [
    { "key": "Principal Arrears", "label": "Principal Arrears" },
    { "key": "Current Period Interest", "label": "Current Period Interest" },
    { "key": "Debitnote Arrears", "label": "Debitnote Arrears" },
    { "key": "Interest Arrears", "label": "Interest Arrears" },
    { "key": "Past Advances & Dues", "label": "Past Advances & Dues" },
]

function PrintReceiptTransactions() {
    const { id } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { loginData } = useSelector(state => state.loginReducer)
    const { loading, receiptDetails } = useSelector((state) => state.billingReportsReducer)
    const { flatsData } = useSelector(state => state.wingsReducer)

    useEffect(() => {
        dispatch(getReceiptDetails({
            societyId: loginData?.default_community?.community_id,
            id
        }))
    }, [])

    useEffect(() => {
        if (receiptDetails) {
            dispatch(getFlatDetails({ societyId: loginData?.default_community?.community_id, wingId: receiptDetails?.societyInvoice?.wing_no }))
        }
    }, [receiptDetails])

    useEffect(() => {
        if (!loading && receiptDetails) {
            window.print()
        }
    }, [loading]);

    

    return (
        <div className="mx-5 my-8">
            {
                loading && !flatsData ?
                    <Loader />
                    :

                    (
                        <div dangerouslySetInnerHTML={{ __html: receiptDetails }} />
                    )

            }
        </div >
    )
}

export default PrintReceiptTransactions;