import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import { logOut } from '../../../../utils/helpers/logOut'
import getBrowserDetails from '../../../../utils/helpers/getBrowserDetails'
import { errorMsg } from '../../../../consts/responseMessages';

export async function getEmployeeListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_EMPLOYEE_LIST_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addEmployeeReq(action) {
    const BODY = {
        "society_id": action.data?.societyId,
        "emp_type_id": action.data?.employeeTypeId,
        "emp_name": action.data?.fullName,
        "phone": action.data?.mobileNo,
        "mobile_country_code": action.data?.mobileCountryCode,
        "username": action.data?.userName,
        "email": action.data?.email,
        "address": action.data?.address,
        "password": action.data?.password,
        "confirm_password": action.data?.confirmPassword
    }

    return axiosInstance.post(apiEndpoints.ADD_EMPLOYEE_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getEmployeeTypeListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_EMPLOYEE_TYPE_LIST_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function changeEmployeeStatusReq(action) {

    const BODY = {
        "emp_detail_id": action.data.employeeDetailsId,
        "status": action.data?.status
    }

    return axiosInstance.put(apiEndpoints.UPDATE_EMPLOYEE_STATUS_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteEmployeeReq(action) {
    const BODY = {
        "emp_detail_id": action.data?.employeeDetailsId
    }

    return axiosInstance.delete(apiEndpoints.DELETE_EMPLOYEE_API, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getEmployeeAuditLogsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_EMPLOYEE_AUDIT_LOGS_API}?emp_id=${action.data.employeeId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getEmployeeDetailsReq(action) {
    return axiosInstance.get(`${apiEndpoints.VIEW_EMPLOYEE_API}?emp_id=${action.data.employeeId}&society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateEmployeeDetailsReq(action) {

    const BODY = {
        "emp_id": action.data?.employeeId,
        "emp_detail_id": action.data?.employeeDetailsId,
        "emp_type_id": action.data?.employeeTypeId,
        "emp_name": action.data?.fullName,
        "phone": action.data?.mobileNo,
        "mobile_country_code": action.data?.mobileCountryCode,
        "username": action.data?.userName,
        "email": action.data?.email,
        "address": action.data?.address,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ip": action.data?.ipAddress
    }

    return axiosInstance.put(apiEndpoints.UPDATE_EMPLOYEE_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getEmployeeUserNameListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_EMPLOYEEE_USERNAMES_LIST_API}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getEmployeeNameListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_EMPLOYEEE_NAMES_LIST_API}?society_id=${action.data?.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getExistingEmployeeReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_EXISTING_EMPLOYEEE_LIST_API}?search_text=${encodeURIComponent(action.data.searchText)}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addFromExistingEmployeeReq(action) {
    const BODY = {
        "society_id": action.data?.societyId,
        "emp_type_id": action.data?.employeeTypeId,
        "emp_id": action.data?.employeeId
    }

    return axiosInstance.post(apiEndpoints.ADD_EXISTING_EMPLOYEEE_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getEmployeeLoginReportReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_EMPLOYEEE_LOGIN_REPORT_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}${action.data.startDate ? `&from_date=${action.data.startDate}` : ''}${action.data.endDate ? `&to_date=${action.data.endDate}` : ''}${action.data.empId ? `&emp_id=${action.data.empId}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function printEmployeeLoginReportReq(action) {
    return axiosInstance.get(`${apiEndpoints.PRINT_EMPLOYEEE_LOGIN_REPORT_API}?society_id=${action.data.societyId}${action.data.startDate ? `&from_date=${action.data.startDate}` : ''}${action.data.endDate ? `&to_date=${action.data.endDate}` : ''}${action.data.empId ? `&emp_id=${action.data.empId}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function downloadEmployeeSheetReq(action) {
    console.log(action)
    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_EMPLOYEEE_LOGIN_REPORT_API}?society_id=${action.data?.societyId}${action.data?.startDate ? `&from_date=${action.data.startDate}` : ''}${action.data?.endDate ? `&to_date=${action.data.endDate}` : ''}${action.data?.empId ? `&emp_id=${action.data.empId}` : ''}`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}


export async function getEmployeeReportRecordReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_EMPLOYEEE_REPORT_RECORDS_API}?society_id=${action.data.societyId}&session_id=${encodeURIComponent(action.data.sessionId)}&ip=${encodeURIComponent(action.data.ipAddress)}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}${action.data.startDate ? `&from_date=${action.data.startDate}` : ''}${action.data.endDate ? `&to_date=${action.data.endDate}` : ''}${action.data.empId ? `&emp_id=${action.data.empId}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}