import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { EInvoiceStatusOptions } from "../../../../consts/DropDownOptions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useRef } from "react";
import * as XLSX from 'xlsx'
import { E_INVOICE_REPORT_ENDPOINT } from "../../../../Routing/routes";
import EinvoiceTable from "./EinvoiceTable";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import moment from "moment";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getEinvoiceReport } from "../../../../redux/actions/FinanceActions/EinvoiceActions";
import { getInvoiceTypeList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import ButtonG from "../../../../components/Button/ButtonG";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "60px" },
  {
    id: "irn",
    label: "IRN",
    width: "200px",
  },
  { id: "ackNo", label: "Ack No.", width: "90px" },
  { id: "ackDate", label: "Ack Date", width: "90px" },
  { id: "docNo", label: "Doc No.", width: "90px" },
  { id: "docDate", label: "Doc Date", width: "90px" },
  { id: "docType", label: "Doc Type", width: "90px" },
  { id: "invValue", label: "Inv Value", width: "90px" },
  { id: "recipientGstin", label: "Recipient GSTIN", width: "90px" },
  { id: "status", label: "Status", width: "90px" },
];

const EInvoiceReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const [selected, setSelected] = useState([]);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, EinvoiceReport, error } = useSelector(
    (state) => state.EinvoiceReducer
  );
  console.log("data", EinvoiceReport);
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);
  const societyId = loginData?.default_community?.community_id;
  useEffect(() => {
    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);
  useEffect(() => {
    if (typeLists) {
      setInvoiceTypeList(typeLists);
    }
  }, [typeLists]);
  const handleStatusType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("status");
    navigate({
      pathname: `${E_INVOICE_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["status", selectedItem],
      ])}`,
    });
  };
  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");
    if (selectedItem.value?.toString() !== searchParams.get("billType")) {
      searchParams.delete("billType");
      navigate({
        pathname: `${E_INVOICE_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["billType", selectedItem],
        ])}`,
      });
    }
  };
  const handleClear = ()=>{
    navigate(E_INVOICE_REPORT_ENDPOINT)
  }
  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${E_INVOICE_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${E_INVOICE_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };
  const tableRef = useRef(null)
  useEffect(() => {
    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );

  }, []);
  const handleDownloadExcel = () => {
    const data = 
      EinvoiceReport?.data?.map((item, index) => ({
        "S. No":
          EinvoiceReport?.paginate?.current_page *
            EinvoiceReport?.paginate?.limit +
          1 +
          index,
        "IRN": item?.irn,
        "Ack No": item?.ack_id,
        "Ack Date": item?.ack_date
          ? moment(new Date(item?.ack_date))?.format("DD-MM-YYYY")
          : "-",
        "Doc No": item?.invoice_no,
        "Doc Date": item?.invoiceDate
          ? moment(new Date(item?.invoiceDate))?.format("DD-MM-YYYY")
          : "-",
        "Doc Type": item?.inv_type,
        "Invoice Value": item?.amount,
        "Recipient GSTIN": item?.GST,
        "Status": item?.einvoice_status,
      })) || [];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "EInvoice Report");
    XLSX.writeFile(workbook, "EInvoice_Report.xlsx");
  };
  const getTableData = () => {
    dispatch(
      getEinvoiceReport({
        societyId,
        page: searchParams.get("page") || 0,
        status: searchParams.get("status") || "",
        billType: searchParams.get("billType") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        limit: searchParams.get("PageLimit") || 10,
      })
    );
  };
  useEffect(() => {
    getTableData();
  }, [searchParams]);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="EInvoice Report">
            <span className="border-r border-[#CCC]"></span>
            <Dropdown2
              options={
                invoiceTypeList
                  ? [
                      { label: "All", value: "" }, 
                      ...invoiceTypeList.map((item) => ({
                        label: item?.invoice_title,
                        value: item?.id,
                      })),
                    ]
                  : []
              }
              placeholder="Select Bill Type"
              className=""
              width="182px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectBillType(selectedItem.value || ""); 
              }}
              value={
                searchParams.get("billType")
                  ? {
                      value: searchParams.get("billType"),
                      label:
                        searchParams.get("billType") === ""
                          ? "All"
                          : invoiceTypeList?.find(
                              (item) =>
                                item?.id ===
                                parseInt(searchParams.get("billType"))
                            )?.invoice_title || "All",
                    }
                  : { label: "All", value: "" }
              }
            />
            <Dropdown2
              options={EInvoiceStatusOptions}
              placeholder="Select IRN Status Type"
              className=""
              width="182px"
              height="32px"
              onSelect={(selectedItem) => {
                handleStatusType(selectedItem.value);
              }}
              value={
                searchParams.get("status") && {
                  value: searchParams.get("status"),
                  label: EInvoiceStatusOptions?.find(
                    (item) => item.value == searchParams.get("status")
                  )?.label,
                }
              }
            />
            <span className="border-r border-[#CCC]"></span>
            <RangeDatePicker
              className="w-44 h-8"
              defaultStartValue={
                searchParams.get("startDate")
                  ? new Date(searchParams.get("startDate"))
                  : ""
              }
              defaultEndValue={
                searchParams.get("endDate")
                  ? new Date(searchParams.get("endDate"))
                  : ""
              }
              onStartDateChange={handleStartDate}
              onEndDateChange={handleEndDate}
            />
            <ButtonG
             label="Clear"
             className="h-8"
             onClick={handleClear}
            />
            <button
              onClick={handleDownloadExcel}
              className="text-red-650 font-semibold border border-red-650 bg-white text-xs h-8 px-2 items-center rounded-lg"
            > 
              Download Excel
            </button>
          </ListHeader>
        </div>
        <div className="ml-1">
          <EinvoiceTable
            loading={loading}
            data={
              EinvoiceReport?.data?.map((item, index) => ({
                sNo:
                  EinvoiceReport?.paginate?.current_page *
                    EinvoiceReport?.paginate?.limit +
                  1 +
                  index,
                irn: item?.irn,
                ackNo: item?.ack_id,
                ackDate: item?.ack_date
                  ? moment(new Date(item?.ack_date))?.format("DD-MM-YYYY")
                  : "-",
                docNo: item?.invoice_no,
                docDate: item?.invoiceDate
                  ? moment(new Date(item?.invoiceDate))?.format("DD-MM-YYYY")
                  : "-",
                docType: item?.inv_type,
                invValue: item?.amount,
                recipientGstin: item?.GST,
                status: item?.einvoice_status,
              })) || []
            }
            columns={headCells}
            checkbox={false}
            pagination={EinvoiceReport?.data?.length}
            totalCount={EinvoiceReport?.paginate?.total_pages}
            totalPages={EinvoiceReport?.paginate?.total_pages}
            start={
              EinvoiceReport?.paginate?.current_page *
                EinvoiceReport?.paginate?.limit +
              1
            }
            end={
              EinvoiceReport?.paginate?.current_page *
                EinvoiceReport?.paginate?.limit +
              EinvoiceReport?.data?.length
            }
            currentPage={Number(EinvoiceReport?.paginate?.current_page)}
            rowsPerPage={searchParams.get("PageLimit") || 10}
            getTableData={getTableData}
          ></EinvoiceTable>
        </div>
        {!EinvoiceReport?.data?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default EInvoiceReport;
