import React from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate } from "react-router";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import { FieldArray, Form, Formik } from "formik";
import { AddComplaintCategoryValidation } from "../../../validationSchema/deskSchema/logaTicketSchema";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addComplaintCategory } from "../../../redux/actions/DeskActions/ComplaintCategoryAction";
import { CircularProgress } from "@mui/material";
import { DeleteIcon } from "../../../assets";

function AddEditComplaintCategory({ action }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ipAddress } = useSelector(state => state.ipAddressReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { updateLoader } = useSelector(
    (state) => state.complaintCategoryReducer
  );

  const handleSaveClick = async (values, { resetForm }) => {
    let sendRequest = {
      ...values,
      ipAddress,
      sessionId: loginData?.session_id,
      society_id: loginData?.default_community?.community_id,
      onSuccesCallback: () => {
        resetForm();
      },
    };
    dispatch(addComplaintCategory(sendRequest));
  };

  const handleDelete = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
  }

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            categoryName: "",
            title: "",
            msgTemplate: "",
            subCategory: [{ subTitle: '', subMessageTemplate: '' }],
          }}
          enableReinitialize
          validationSchema={AddComplaintCategoryValidation}
          onSubmit={handleSaveClick}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack
                    title="Add Complaint Category"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <div className="border-r border-[#CCC]"></div>
                    <ButtonG
                      label="Cancel"
                      className="h-8"
                      type={`button`}
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    <Button
                      label={
                        <span className="inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Add"
                          )}
                        </span>
                      }
                      className="h-8 px-4"
                      type={`submit`}
                    />
                  </ListHeaderBack>
                </div>
                <div className="p-1 py-3 gap-2 m-1">
                  <div className="w-6/12 text-sm">
                    <TextInputFM
                      label={"Complaint Category:"}
                      placeholder={"Enter Category"}
                      className={"w-64"}
                      name={`title`}
                    />
                    <TextAreaFM
                      label={"Message Template:"}
                      placeholder={"Enter"}
                      className={"w-64"}
                      row={9}
                      name={`msgTemplate`}
                    />
                  </div>
                  <div className="border-b border-[#CCC] mb-4"> </div>
                  <div className="flex flex-wrap gap-3">
                    <FieldArray
                      name="subCategory"
                      render={(arrayHelpers) => (
                        <>
                          {formik.values.subCategory &&
                            formik.values.subCategory.map((item, index) => (
                              <div key={index} className="w-[49%] flex-wrap">
                                <div className="border rounded-lg mt-2">
                                  <div className="flex justify-between items-center bg-[#FDF2F3] border-b border-[#CCC] py-2 px-2">
                                    <div className="">
                                      Add SubCategory: {index + 1}
                                    </div>
                                    {index > 0 &&
                                      <DeleteIcon className="fill-red-650" onClick={() => { handleDelete(arrayHelpers, index) }} />
                                    }
                                  </div>
                                  <div className="mt-4 p-2">
                                    <TextInputFM
                                      label={"Sub Category"}
                                      placeholder={"Enter SubCategory"}
                                      className={"w-64"}
                                      name={`subCategory.${index}.subTitle`}
                                    />
                                    <TextAreaFM
                                      label={"Sub Message Template:"}
                                      placeholder={"Enter"}
                                      className={"w-64"}
                                      row={6}
                                      name={`subCategory.${index}.subMessageTemplate`}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          <div className="flex w-[49%] mt-4">
                            <button
                              type="button"
                              label="Add More"
                              onClick={() =>
                                arrayHelpers.push({
                                  subTitle: "",
                                  subMessageTemplate: "",
                                })
                              }
                              className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-full h-[155px] p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center"
                            >
                              Add SubCategory
                            </button>
                          </div>
                        </>
                      )}
                    ></FieldArray>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
}

export default AddEditComplaintCategory;
