import * as type from "../../actions/index.types";

export function financeReportReducer(
    state = {
        loading: false,
        loadingCategories: false,
        updateLoader: false,
        uploadLoader: false,
        billTypeList: null,
        debitNoteInvoiceReport: [],
        invoiceReport: [],
        receiptReport: [],
        supplementaryBillReport: [],
        debitNoteReceiptReport: [],
        deletedInvoiceReport: [],
        deletedDebitNoteReport: [],
        invoiceInterestReport: [],
        prevInvoiceInterestReport: [],
        interestCalculationtReport: [],
        reconciliationReport: [],
        debitNoteInterestCalculationtReport: [],
        invoiceAgingReport: [],
        invoiceAuditReport: [],
        error: null
    },
    action
) {
    switch (action.type) {

        case type.GET_DEBIT_NOTE_INVOICE_REPORTS:
            return action?.data?.export == 1 ? { ...state } : {
                ...state,
                loading: true,
                error: null,
            };
        case type.GET_DEBIT_NOTE_INVOICE_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                debitNoteInvoiceReport: action.payload,
            };
        case type.GET_DEBIT_NOTE_INVOICE_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                debitNoteInvoiceReport: [],
            };

        case type.GET_INVOICE_REPORTS:
            return action?.data?.export == 1 ?
                { ...state }
                : {
                    ...state,
                    loading: true,
                    error: null,
                };
        case type.GET_INVOICE_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                invoiceReport: action.payload,
            };
        case type.GET_INVOICE_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                invoiceReport: [],
            };


        case type.GET_RECEIPT_REPORTS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case type.GET_RECEIPT_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                receiptReport: action.payload,
            };
        case type.GET_RECEIPT_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                receiptReport: [],
            };

        case type.GET_SUPPLEMNTARY_BILL_REPORTS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case type.GET_SUPPLEMNTARY_BILL_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                supplementaryBillReport: action.payload,
            };
        case type.GET_SUPPLEMNTARY_BILL_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                supplementaryBillReport: [],
            };

        case type.GET_DEBIT_NOTE_RECEIPT_REPORTS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case type.GET_DEBIT_NOTE_RECEIPT_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                debitNoteReceiptReport: action.payload,
            };
        case type.GET_DEBIT_NOTE_RECEIPT_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                debitNoteReceiptReport: [],
            };



        case type.GET_DELETED_INVOICE_REPORTS:
            return action?.data?.export == 1 ?
                {
                    ...state,

                } : {
                    ...state,
                    loading: true,
                    error: null,
                }
        case type.GET_DELETED_INVOICE_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                deletedInvoiceReport: action.payload,
            };
        case type.GET_DELETED_INVOICE_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                deletedInvoiceReport: [],
            };

        case type.GET_DELETED_DEBIT_NOTE_REPORTS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case type.GET_DELETED_DEBIT_NOTE_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                deletedDebitNoteReport: action.payload,
            };
        case type.GET_DELETED_DEBIT_NOTE_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                deletedDebitNoteReport: [],
            };

        case type.GET_INVOICE_INTEREST_REPORTS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case type.GET_INVOICE_INTEREST_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                invoiceInterestReport: action.payload,
            };
        case type.GET_INVOICE_INTEREST_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                invoiceInterestReport: [],
            };


        case type.GET_PREV_INVOICE_INTEREST_REPORTS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case type.GET_PREV_INVOICE_INTEREST_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                prevInvoiceInterestReport: action.payload,
            };
        case type.GET_PREV_INVOICE_INTEREST_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                prevInvoiceInterestReport: [],
            };


        case type.GET_INTEREST_CALCULATION_REPORTS:
            return action?.data?.export == 1 ?
                {
                    ...state,

                } : {
                    ...state,
                    loading: true,
                    error: null,
                }

        case type.GET_INTEREST_CALCULATION_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                interestCalculationtReport: action.payload,
            };
        case type.GET_INTEREST_CALCULATION_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                interestCalculationtReport: [],
            };

        case type.GET_RECONCILIATION_REPORTS:
            return action?.data?.export == 1 ? { ...state } :
                {
                    ...state,
                    loading: true,
                    error: null,
                };
        case type.GET_RECONCILIATION_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                reconciliationReport: action.payload,
            };
        case type.GET_RECONCILIATION_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                reconciliationReport: [],
            };

        case type.GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS:
            return action?.data?.export == 1 ?
                {
                    ...state,

                } : {
                    ...state,
                    loading: true,
                    error: null,
                }
        case type.GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                debitNoteInterestCalculationtReport: action.payload,
            };
        case type.GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                debitNoteInterestCalculationtReport: [],
            };


        case type.GET_INVOICE_AGING_REPORTS:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case type.GET_INVOICE_AGING_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                invoiceAgingReport: action.payload,
            };
        case type.GET_INVOICE_AGING_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                invoiceAgingReport: [],
            };


        case type.GET_INVOICE_AUDIT_REPORTS:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case type.GET_INVOICE_AUDIT_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                invoiceAuditReport: action.payload,
            };
        case type.GET_INVOICE_AUDIT_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                invoiceAuditReport: [],
            };

        case type.GET_GST_REPORTS:
            return {
                ...state,
                loading: true,
                error: null,
                gstReport: [],
            }
        case type.GET_GST_REPORTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                gstReport: action.payload,
            };
        case type.GET_GST_REPORTS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                gstReport: [],
            };


        default:
            return state;
    }
}
