const apiEndpoints = {
  //---------Auth Module---------
  LOGIN_API: "/api/society/zipone/auth/login",
  RELOGIN_API: "/api/society/zipone/auth/relogin",
  SOCIETY_FORGOT_PASSWORD_API: "/api/society/zipone/auth/societyForgotPassword",
  PASSWORD_RESET_API: "/api/society/zipone/auth/passwordReset",
  LOGOUT_API: "/api/society/zipone/auth/logout",

  //1. -----Setup Records Module------
  //1.1---------Building Module------------
  //1.1.1 ---------Society Details Module---------
  GET_SOCIETY_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getSocietyDetails",
  SOCIETY_PROFILE_CHANGE_PASSWORD_API: "/api/society/zipone/setupAndRecords/building/getSocietyDetails/changePassword",
  SOCIETY_PROFILE_UPDATE_SOCIETY_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getSocietyDetails/updateSocietyDatails",
  SOCIETY_PROFILE_GET_AUDITLOGS_API: "/api/society/zipone/setupAndRecords/building/getAuditLogs",
  SOCIETY_PROFILE_GET_CURRENCY_LIST_API: "/api/society/zipone/setupAndRecords/building/getSocietyDetails/getCurrencyProjectsList",
  SOCIETY_PROFILE_GET_PROJECT_LIST_API: "/api/society/zipone/setupAndRecords/building/getSocietyDetails/getCurrencyProjectsList",
  SOCIETY_PROFILE_GET_COUNTRY_STATES_CITIES_LIST_API: "/api/society/zipone/setupAndRecords/building/getSocietyDetails/getCountryStatesCitiesList",

  //1.1.2 ---------Society Details Module---------
  GET_SPECIFIC_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getSocietySpecificDetails",
  UPDATE_SPECIFIC_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getSocietySpecificDetails/update",
  GET_SPECIFIC_DETAILS_AUDIT_LOGS_API: "/api/society/zipone/setupAndRecords/building/getAuditLogs",

  //1.1.3 ---------Wings & Flats Module---------
  GET_WINGS_AND_FLATS_API: "/api/society/zipone/setupAndRecords/building/getSocietyWingDetails",
  ADD_WINGS_AND_FLATS_API: "/api/society/zipone/setupAndRecords/building/getSocietyWingDetails/addNewWings",
  DOWNLOAD_WINGS_EXCEL_API: "/api/society/zipone/setupAndRecords/building/getSocietyWingDetails/downloadExcel",
  GET_WINGS_AUDIT_LOGS_API: "/api/society/zipone/setupAndRecords/building/getAuditLogs",

  //Flat
  GET_FLATS_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getWingFlatDetails",
  ADD_FLATS_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getWingFlatDetails/addNewFlats",
  UPLOAD_FLATS_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getWingFlatDetails/newManageFlats/excelUpload",

  //Bill Tariff
  GET_BILL_TARIFF_API: "/api/society/zipone/setupAndRecords/building/getWingFlatDetails",
  UPLOAD_BILL_TARIFF_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getWingFlatDetails/flatsBillTarrif/excelUpload",
  UPDATE_BILL_TARIFF_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getWingFlatDetails/updateBillTariff",

  DOWNLOAD_FLATS_AND_BILL_TARIFF_EXCEL_API: "/api/society/zipone/setupAndRecords/building/getWingFlatDetails/getWingFlatDetaInExcel",

  //utility reading
  GET_HEAD_LIST_API: "/api/society/zipone/setupAndRecords/building/getUtilityReadings/getUtilityInvoiceHeads",
  GET_UTILITY_READINGS_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getWingFlatDetails/getUtilityReadings",
  UPDATE_UTILITY_READINGS_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getWingFlatDetails/updateUtilityReadings",
  UPLOAD_UTILITY_READINGS_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getWingFlatDetails/uploadUtilityReadingExcel",

  //1.1.4 ---------Bank Details Module---------
  GET_BANK_DETAILS_API: "/api/society/zipone/setupAndRecords/building/getSocietyBankDetails",
  ADD_BANK_DETIALS_API: "/api/society/zipone/setupAndRecords/building/getSocietyBankDetails/addNewSocietyBank",
  GET_BANK_DEATLS_AND_TYPE_API: "/api/society/zipone/setupAndRecords/building/getSocietyBankDetails/getBankTypeNameDetails",
  GET_BANK_DETAILS_AUDIT_LOGS_API: "/api/society/zipone/setupAndRecords/building/getAuditLogs",

  GET_BANK_NAME_LIST_API: "/api/society/zipone/setupAndRecords/building/getBankDetails",
  ADD_BANK_API: "/api/society/zipone/setupAndRecords/building/getSocietyBankDetails/addNewBankForSociety",

  DELETE_BUILDING_DETAILS_API: "/api/society/zipone/setupAndRecords/building/deleteBuildingDetails",

  //1.2---------Ocupants & Tenants Module------------
  //1.2.1 ---------Occupants Module--------------------
  GET_TITLE_LIST_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getOccupantsTitles",
  GET_OCCUPANTS_LIST_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getOccupantsDetails",
  SEND_EMAIL_TO_OCCUPANTS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getOccupantsDetails/sendEmailToOccupant",
  DOWNLOAD_OCCUPANTS_EXCEL_API: "/api/society/zipone/setupAndRecords/occupantsTenants/occupants/downloadExcel",
  CHANGE_OCCUPANTS_STATUS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getOccupantsDetails/changeOccupantStatus",
  CHANGE_OCCUPANTS_STATUS_IN_BULK_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getOccupantsDetails/updateOccupantsStatus",
  GET_MEMBER_PRIVACY_DETAILS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getOccupantsDetails/getMemberPrivacyDetail",
  UPDATE_MEMBER_PRIVACY_DETAILS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getOccupantsDetails/updateMemberPrivacyDetail",
  DELETE_OCCUPANT_API: "/api/society/zipone/setupAndRecords/occupantsTenants/deleteOccupant",
  GET_OCCUPANT_AUDIT_LOGS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getOccupantAuditLogs",
  ADD_OCCUPANT_API: "/api/society/zipone/setupAndRecords/occupantsTenants/addNewOccupant",
  GET_EXISTING_OCCUPANT_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getExistingOccupant",
  ADD_EXISTING_OCCUPANT_API: "/api/society/zipone/setupAndRecords/occupantsTenants/addExistingOccupant",
  GET_ARCHIVED_OCCUPANTS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getArchivedOccupants",
  GET_OCCUPANT_DETAILS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getOccupantProfile",
  UPDATE_OCCUPANT_DETAILS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/updateOccupantProfile",
  UPLOAD_FLAT_DOCUMENTS_API: "/api/society-members/zipone/family/uploadFlatDocs",
  GET_MORTGAGE_DETAILS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getOccupantsDetails/getMortgageDetails",
  UPDATE_MORTGAGE_DETAILS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getOccupantsDetails/addUpdateMortgageData",
  ADD_FAMILY_MEMBER_API: "/api/society-members/zipone/family/addFamilyMember",
  DELETE_FAMILY_MEMBER_API: "/api/society-members/zipone/family/deleteFamilyMember",
  UPDATE_FAMILY_MEMBER_API: "/api/society-members/zipone/family/updateMemberDetail",
  ADD_FAMILY_MEMBER_THROUGH_EXCEL_API: "/api/society-members/zipone/family/addFamilyMemberThroughExcel",
  UPDATE_FAMILY_MEMBER_THROUGH_EXCEL_API: "/api/society-members/zipone/family/updateFamilyMemberThroughExcel",
  DOWNLOAD_FAMILY_MEMBER_EXCEL_SAMPLE_API: "/api/society-members/zipone/family/downloadFlatFamilyMembers",
  GET_FLAT_DOCUMENTS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getFlatDocs",
  UPDATE_FLAT_DOCUMENTS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/addUpdateFlatDocs",
  VIEW_NOMINEE_DETAILS_API: "/api/society-members/zipone/family/nomineeList",
  ADD_UPDATE_NOMINEE_DETAILS_API: "/api/society-members/zipone/family/web/addUpdateNominee",
  DELETE_NOMINEE_DETAILS_API: "/api/society-members/zipone/family/deleteNominee",
  ADD_UPDATE_VEHICLE_DETAILS_API: "/api/society-members/zipone/vehicle/web/addUpdateVehicle",
  DELETE_VEHICLE_DETAILS_API: "/api/society-members/zipone/vehicle/deleteVehicle",
  GET_VEHICLE_DETAILS_API: "/api/society-members/zipone/vehicle/list",

  //1.2.2 ---------Tenants Module--------------------
  GET_TENANTS_LIST_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getTenantsDetails",
  DOWNLOAD_TENANTS_EXCEL_API: "/api/society/zipone/setupAndRecords/occupantsTenants/downloadTenantsList",
  ADD_TENANT_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getTenantsDetails/addNewTenant",
  DELETE_TENANT_API: "/api/society/zipone/setupAndRecords/occupantsTenants/archiveTenant",
  DELETE_TENANTS_IN_BULK_API: "/api/society/zipone/setupAndRecords/occupantsTenants/deleteBulkTenants",
  CHANGE_TENANT_STATUS_IN_BULK_API: "/api/society/zipone/setupAndRecords/occupantsTenants/updateTenantsStatus",
  CHANGE_TENANT_STATUS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/changeTenantStatus",
  UPLOAD_TENANTS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/uploadTenantData",
  SEND_EMAIL_TO_TENANTS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/sendEmailToTeanants",
  GET_TENANT_DETAILS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getSpecificTenantDetails",
  UPDATE_TENANT_DETAILS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getTenantsDetails/updateTenantDatail",
  GET_EXISTING_TENANTS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getExistingTenant",
  ADD_EXISTING_TENANTS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/addExistingTenant",
  GET_TENANTS_AUDIT_LOGS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getTenantsAuditLogs",
  GET_ARCHIVED_TENANTS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getArchivedTenantsList",
  ARCHIVE_TENANT_API: "/api/society/zipone/setupAndRecords/occupantsTenants/archiveTenant",
  GET_MANAGING_COMITTEE_TYPES_LIST_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getSocietyManagingCommitteeTypes",
  GET_EMPLOYEE_TYPES_LIST_IN_TENANTS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getSocietyEmployeeTypes",
  GET_TENANCY_EXPIRY_REMINDER_DATA_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getTenancyExpiryReminderData",
  UPDATE_TENANCY_EXPIRY_REMINDER_API: "/api/society/zipone/setupAndRecords/occupantsTenants/updateTenancyExpiryReminder",
  GET_ACCESS_SETTING_DATA_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getRoleOfOccupantTenantEmployee",
  UPDATE_ACCESS_SETTING_API: "/api/society/zipone/setupAndRecords/occupantsTenants/updateTenantAccess",
  RENEW_FORM_API: "/api/society/zipone/setupAndRecords/occupantsTenants/renewTenantDetail",
  RENT_PAYMENT_ONLINE_REGISTRATION_LIST_API: "/api/society/zipone/setupAndRecords/occupantsTenants/onlineRentRegistrationList",
  GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getRentPaymentOnlineRegistrationData",
  UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_API: "/api/society/zipone/setupAndRecords/occupantsTenants/updateRentPaymantOnlineRegistration",
  DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_API: "/api/society/zipone/setupAndRecords/occupantsTenants/deleteRentPaymantOnlineRegistration",
  PRINT_TENANT_LIST_API: "/api/society/zipone/setupAndRecords/occupantsTenants/printTenantsList",

  //1.2.3 ---------Leave & Licence--------------------
  DOWNLOAD_LEAVE_AND_LICENSE_API: "/api/society/zipone/setupAndRecords/occupantsTenants/downloadLeaveAndLicenseList",
  GET_LEAVE_AND_LICENSE_LIST_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getLeaveAndLicenseList",

  //1.2.4 ---------Apply for tenancy--------------------
  APPLY_FOR_TENANCY_API: "/api/society/zipone/setupAndRecords/occupantsTenants/applyForTenancy",

  //1.2.5 ---------View Invities--------------------
  GET_INVITED_OCCUPANTS_AND_TENANTS_LIST_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getInvitedOccupatsTenantsList",
  SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/resentInviteEmail",

  //1.3---------Staff Management Module------------
  //1.3.1 --------- Staff Personnel Module--------------------
  GET_SOCIETY_STAFF_LIST_API: "/api/society-members/zipone/staff/getSocietyStaffList",
  VIEW_SOCIETY_STAFF_API: "/api/society-members/zipone/staff/viewStaffMemberDetail",
  GET_SOCIETY_STAFF_PROFILE_LIST_API: "/api/society-members/zipone/staff/staffTypeList",
  ADD_SOCIETY_STAFF_API: "/api/society-members/zipone/staff/addSocietyStaff",
  DOWNLOAD_STAFF_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/bulkUpload/downloadStaffList",
  UPDATE_SOCIETY_STAFF_API: "/api/society-members/zipone/staff/updateSocietyStaff",
  DELETE_SOCIETY_STAFF_API: "/api/society-members/zipone/staff/deleteSocietyStaff",
  GET_SOCIETY_WING_FLAT_LIST_API: "/api/society-members/zipone/staff/getWingFlats",
  PRINT_SOCIETY_STAFF_CARD_API: "/api/society-members/zipone/staff/printStaffIdCard",
  GET_SOCIET_STAFF_RATING_PARAMETERS_API: "/api/society-members/zipone/staff/ratingParameter",
  UPDATE_SOCIET_STAFF_RATING_DATA_API: "/api/society-members/zipone/staff/postRating",
  GET_SOCIET_STAFF_RATING_DATA_API: "/api/society-members/zipone/staff/viewRating",
  SEND_STAFF_CODE_SMS_TO_STAFF_API: "/api/society-members/zipone/staff/sendStaffCodeSmsToStaff",
  GET_ID_CARD_FIELDS_DATA_API: "/api/society-members/zipone/staff/getStaffIdCardFields",
  UPDATE_ID_CARD_FIELDS_DATA_API: "/api/society-members/zipone/staff/addUpdateStaffIdCardFields",
  GET_APPROVAL_SETTINGS_DATA_API: "/api/society-members/zipone/staff/viewStaffApprovalSetting",
  UPDATE_APPROVAL_SETTINGS_DATA_API: "/api/society-members/zipone/staff/setApprovalSetting",
  UPDATE_APPROVAL_STATUS_DATA_API: "/api/society-members/zipone/staff/updateSocietyStaffApprovalStatus",
  DOWNLOAD_SOCIETY_STAFF_SUMMARY_REPORT_API: "/api/society-members/zipone/staff/downloadSummaryReport",
  GET_SOCIETY_STAFF_AUDIT_LOG_API: "/api/society-members/zipone/staff/getSocietyStaffAuditLogs",

  //1.3.2 --------- Maintenance Agency Module--------------------
  GET_MAINTENANCE_STAFF_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/maintenanceAgency/getStaffList",
  GET_AGENCY_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/maintenanceAgency/getAgencyList",
  ADD_UPDATE_MAINTENANCE_STAFF_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/maintenanceAgency/addUpdateAgencyStaff",
  GET_MAINTENANCE_STAFF_DETAILS_API: "/api/society/zipone/setupAndRecords/staffManagement/maintenanceAgency/getAgencyStaffDetails",
  DELETE_MAINTENANCE_STAFF_OR_AGENCY_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/maintenanceAgency/deleteAgencyOrStaff",
  GET_AGENCY_CATEGORY_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/maintenanceAgency/getAgencyCategoryList",
  GET_AGENCY_DETAILS_API: "/api/society/zipone/setupAndRecords/staffManagement/maintenanceAgency/getAgencyDetails",
  ADD_UPDATE_AGENCY_API: "/api/society/zipone/setupAndRecords/staffManagement/maintenanceAgency/addUpdateAgency",

  //1.3.3 --------- Employee Management Module--------------------
  GET_EMPLOYEE_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/getSocietyEmployeeList",
  GET_EMPLOYEE_TYPE_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/getEmployeetypes",
  UPDATE_EMPLOYEE_STATUS_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/updateEmployeeStatus",
  DELETE_EMPLOYEE_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/deleteEmployee",
  VIEW_EMPLOYEE_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/getSocietyEmployeeDetail",
  GET_EMPLOYEE_AUDIT_LOGS_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/getEmployeeAuditLogs",
  ADD_EMPLOYEE_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/addNewEmployeeData",
  UPDATE_EMPLOYEE_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/updateEmployeeData",
  GET_EMPLOYEEE_USERNAMES_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/getEmployeeUsernames",
  GET_EXISTING_EMPLOYEEE_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/getExistingEmployee",
  ADD_EXISTING_EMPLOYEEE_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/addExistingEmployee",
  GET_EMPLOYEEE_NAMES_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/getEmployeeNameList",
  GET_EMPLOYEEE_LOGIN_REPORT_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/getEmployeeLoginReport",
  DOWNLOAD_EMPLOYEEE_LOGIN_REPORT_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/downloadEmployeeLoginReport",
  PRINT_EMPLOYEEE_LOGIN_REPORT_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/printEmployeeLoginReport",
  GET_EMPLOYEEE_REPORT_RECORDS_API: "/api/society/zipone/setupAndRecords/staffManagement/employee/getEmployeeRecordsReport",

  //1.3.4 --------- Managing Committee Module--------------------
  GET_MANAGING_COMMITTIEE_DETAILED_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/getManagingCommitteeList",
  ADD_MANAGING_COMMITTIEE_API: "/api/society/zipone/setupAndRecords/staffManagement/addNewManagingCommittee",
  GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE_API: "/api/society/zipone/setupAndRecords/staffManagement/getOccupatsForManagingCommittee",
  GET_ALL_COMMITTEE_AUDIT_LOG_API: "/api/society/zipone/setupAndRecords/staffManagement/getAllCommittiesAuditLogs",
  GET_MANAGING_COMMITTIEE_AUDIT_LOGS_API: "/api/society/zipone/setupAndRecords/staffManagement/getManagingCommittiesAuditLogs",
  GET_MANAGING_COMMITTIEE_DETAILS_API: "/api/society/zipone/setupAndRecords/staffManagement/viewManagingCommitteeDetails",
  GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER_API: "/api/society/zipone/setupAndRecords/staffManagement/getArchivedCommitteeMembersList",
  UPDATE_MANAGING_COMMITTIEE_API: "/api/society/zipone/setupAndRecords/staffManagement/updateManagingCommittee",
  GET_MANAGING_COMMITTIEE_ACCESS_SETTING_API: "/api/society/zipone/setupAndRecords/staffManagement/getManagingCommitteeAccess",
  UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING_API: "/api/society/zipone/setupAndRecords/staffManagement/updateManagingCommitteeAccess",
  DELETE_MANAGING_COMMITTIEE_API: "/api/society/zipone/setupAndRecords/staffManagement/deleteManagingCommittee",
  RESIGN_MANAGING_COMMITTIEE_API: "/api/society/zipone/setupAndRecords/staffManagement/resignManagingCommittee",
  RETIRE_MANAGING_COMMITTIEE_API: "/api/society/zipone/setupAndRecords/staffManagement/retireFromManagingCommittee",
  VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER_API: "/api/society/zipone/setupAndRecords/staffManagement/viewCommitteeMemberDetails",
  DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER_API: "/api/society/zipone/setupAndRecords/staffManagement/deleteManagingCommitteeMember",
  APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION_API: "/api/society/zipone/setupAndRecords/staffManagement/approveRejectMResignation",
  APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT_API: "/api/society/zipone/setupAndRecords/staffManagement/approveMRetirement",
  REAPPOINT_MANAGING_COMMITTEE_MEMBER_API: "/api/society/zipone/setupAndRecords/staffManagement/reappointCommitteeMember",

  //1.4---------Billing And Invoice Setup Module------------
  //1.4.1---------- Invoice Head Setup Module -------------
  GET_INVOICE_HEAD_SETTING_API: "/api/auth/getHeadDetailList",
  UPDATE_INVOICE_HEAD_SETTING_API: "/api/auth/saveHeadDetailList",
  GET_GL_ACCOUNT_LIST_API: "/api/auth/getGLAccountList",
  ADD_MORE_HEAD_API: "/api/auth/addMoreGLAccount",
  EDIT_MORE_HEAD_API: "/api/auth/editMoreGLAccount",
  DELETE_MORE_HEAD_API: "/api/auth/deleteMoreGLAccount",
  GET_MORE_HEAD_DETAILS_API: "/api/auth/getMoreHeadDetails",
  DELETE_UTILITY_SLAB_API: "/api/auth/deleteUtilitySlab",
  ADD_UTILITY_SLAB_API: "/api/auth/addUtilitySlabs",
  GET_INVOICE_FUNCTIONAL_SETTING_API: "/api/auth/functionalSetting",
  SAVE_INVOICE_FUNCTIONAL_SETTING_API: "/api/auth/saveFunctionalSetting",
  GET_INVOICE_SERIES_LIST_API: "/api/auth/getInvoiceSeriesList",
  GET_TAXABLE_FORMAT_LIST_API: "/api/auth/getTaxableFormat",
  DELETE_INVOICE_SERIES_API: "/api/auth/deleteInvoiceSeries",
  ADD_INVOICE_SERIES_API: "/api/auth/addInvoiceSeries",
  DELETE_TAXABLE_MAIN_HEAD_API: "/api/auth/daleteTaxableMainHead",
  ADD_TAXABLE_MAIN_HEAD_API: "/api/auth/addEditTaxableMainHead",
  ADD_TAXABLE_SUB_HEAD_API: "/api/auth/addTaxableSubHead",
  ACTION_TAXABLE_SUB_HEAD_API: "/api/auth/actionTaxableSubHead",
  CHANGE_ORDER_TAXABLE_HEAD_API: "/api/auth/changeOrderTaxableHead",
  GET_INVOICE_TYPE_LIST_API: "/api/auth/getInvoiceTypeList",
  ADD_INVOICE_TYPE_API: "/api/auth/addInvoiceType",
  DELETE_INVOICE_TYPE_API: "/api/auth/deleteInvoicetype",
  GET_INVOICE_HEAD_LIST_API: "/api/auth/getInvoiceHeadList",
  GET_BANK_DETAIL_LIST_API: "/api/auth/getBankDetails",
  GET_CREDIT_NOTE_SETTINGS_DETAIL_API: "/api/auth/creditNoteSettingDetails",
  GET_MEMBER_LEDGER_LIST_API: "/api/auth/memberAccountList",
  //1.4.3 --------- GST and NOC Setting--------------------
  GET_GST_NOC_SETTING_API: "/api/auth/taxSettings",
  UPDATE_GST_NOC_SETTING_API: "/api/auth/saveTaxSettings",
  GET_MASTER_HEAD_DETAILS_API: "/api/auth/masterTariffDetail",
  UPDATE_MASTER_HEAD_DETAILS_API: "/api/auth/updateMasterTariff",

  //1.5---------Bulk Upload Module------------
  DOWNLOAD_EXCEL_FORMAT_API: "/api/society/zipone/setupAndRecords/occupantsTenants/bulkUpload/downloadSampleExcel",
  UPLOAD_STAFF_EXCEL_SHEET_API: "/api/society/zipone/setupAndRecords/staffManagement/bulkUpload/uploadstaffPersonnelsData",
  UPLOAD_NEW_OCCUPANT_EXCEL_SHEET_API: "/api/society/zipone/setupAndRecords/occupantsTenants/uploadNewOccupantsData",
  UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET_API: "/api/society/zipone/setupAndRecords/occupantsTenants/bulkUpdateOldOccupantsData",
  UPLOAD_OCCUPANT_OPENING_BALANCE_SHEET_API: "/api/auth/bulkUploadOpeningBalance",
  DOWNLOAD_OCCUPANT_OPENING_BALANCE_SHEET_SAMPLE_API: "/api/auth/downloadOpeningBalanceExcel",

  //1.6---------Society Profile Setup Module------------
  //1.6.2 ---------Content Management Template Module---------
  // GET_CONTENT_MANAGEMENT_TEMPLATE_LIST_API: "/api/society/zipone/setupAndRecords/profileSetup/getCmsTemplateContentList",
  // GET_CONTENT_MANAGEMENT_TEMPLATE_DETAILS_API: "/api/society/zipone/setupAndRecords/profileSetup/viewCmsTemplateContent",
  // GET_CONTENT_MANAGEMENT_TEMPLATE_AUDIT_LOGS_API: "/api/society/zipone/setupAndRecords/profileSetup/getCmsTemplateAuditLogs",
  // UPDATE_CONTENT_MANAGEMENT_TEMPLATE_API: "/api/society/zipone/setupAndRecords/profileSetup/updateCmsTemplateContent",

  //1.7---------Manage Rights and Roles Module------------
  GET_EMPLOYEE_ROLE_LIST_API: "/api/society/zipone/setupAndRecords/manageRoleRight/getEmployeeRoleList",
  GET_EMPLOYEE_ROLE_DETAILS_API: "/api/society/zipone/setupAndRecords/manageRoleRight/getEmployeeRoleDetails",
  ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS_API: "/api/society/zipone/setupAndRecords/manageRoleRight/addUpdateEmployeeRoleDetails",
  GET_OCCUPANT_ROLE_LIST_API: "/api/society/zipone/setupAndRecords/manageRoleRight/getOccupantsRoleList",
  GET_OCCUPANTS_ROLE_DETAILS_API: "/api/society/zipone/setupAndRecords/manageRoleRight/getOccupantRoleDetails",
  ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS_API: "/api/society/zipone/setupAndRecords/manageRoleRight/addUpdateOccupantRoleDetails",
  GET_FAMILY_ROLE_LIST_API: "/api/society/zipone/setupAndRecords/manageRoleRight/getFamilyRoleList",
  GET_FAMILY_ROLE_DETAILS_API: "/api/society/zipone/setupAndRecords/manageRoleRight/getFamilyRoleDetails",
  ADD_AND_UPDATE_FAMILY_ROLE_DETAILS_API: "/api/society/zipone/setupAndRecords/manageRoleRight/addUpdateFamilyRoleDetails",
  GET_ROLE_RIGHT_SETTINGS_API: "/api/society/zipone/setupAndRecords/manageRoleRight/getRoleRightSettings",
  UPDATE_ROLE_RIGHT_STATUS_API: "/api/society/zipone/setupAndRecords/manageRoleRight/updateRoleRightsStatus",
  DELETE_RIGHTS_AND_ROLES_API: "/api/society/zipone/setupAndRecords/manageRoleRight/deleteRoleRightsDetails",
  GET_SOCIETY_ACCESS_SETTINGS_API: "/api/society/zipone/setupAndRecords/manageRoleRight/getSocietyAccessSetting",
  UPDATE_SOCIETY_ACCESS_SETTINGS_API: "/api/society/zipone/setupAndRecords/manageRoleRight/updateSocietyAccessSetting",

  //1.8---------Society Forms and Bye Laws Module------------
  GET_SOCIETY_FORMS_AND_BYE_LAWS_API: "/api/society/directories/byesAndLaws",

  //2. -----Social and Communication------
  //2.1---------Lobby------------
  //2.1.3 ---------Important Contacts---------
  GET_IMPORTANT_CONTACTS_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/getImportantContactList",
  GET_CONTACTS_CATEGORY_LIST_API: "/api/society/zipone/setupAndRecords/staffManagement/getContactCategoryList",

  //2.6----------Notice And Circular ----------
  GET_NOTICE_CIRCULAR_LIST_API: "/api/notice/zipone/noticeList",
  RESEND_NOTICE_CIRCULAR_API: "/api/notice/zipone/resendNotice",
  DELETE_NOTICE_CIRCULAR_API: "/api/notice/zipone/deleteNotice/",
  GET_NOTICE_CIRCULAR_DETAILS_API: "/api/notice/zipone/noticeDetail",
  PRINT_NOTICE_CIRCULAR: "/api/notice/zipone/printNotice",
  POST_NOTICE_CIRCULAR_COMMENT_API: "/api/notice/zipone/addCommentOnNotice",
  Add_NOTICE_CIRCULAR_API: "/api/notice/zipone/addNotice",
  UPDATE_NOTICE_CIRCULAR_API: "/api/notice/zipone/editNotice",
  GET_NOTICE_CIRCULAR_TYPES_API: "/api/notice/zipone/noticeTypeList",
  GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE_API: "/api/society-members/neighbour/nighboursList",
  ADD_ACKNOWLEDGEMENT_NOTICE_API: "/api/notice/zipone/addAcknowledgement",
  GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_API: "/api/notice/zipone/acknowledgementList",
  GET_NOTICE_CIRCULAR_AUDIT_LOG_API: "/api/notice/zipone/getNoticeAuditLogs",


  //3. -----Finance------
  //3.1---------My Invoice------------
  GET_MY_INVOICE_API: "/api/auth/invoices",
  MY_INVOICE_STATUS_DATE_FILTERS_API: "/api/auth/invoiceStatusList",
  SINGLE_INVOICE_API: "/api/auth/singleInvoiceV1",
  MY_INVOICE_PAYMENT_LIST_API: "/api/auth/invoicePaymentList",
  // MY_INVOICE_PRINT_INVOICE_API: "/api/auth/residenceInvoicesPrintV1",
  MY_INVOICE_PRINT_INVOICE_API: "/api/auth/residenceInvoicesPrint",
  MY_INVOICE_RECEIPT_API: "/api/auth/residencePaymentPrintNew",
  // MY_INVOICE_RECEIPT_API: "/api/auth/residencePaymentPrint",

  //3.3---------Billing Report------------
  //3.3.1 ---------Debit Notes---------
  GET_DEBIT_NOTES_REPORT_API: "/api/auth/debitNoteHistory",
  UPLOAD_DEBIT_NOTES_CURRENT_EXCEL_API: "/api/auth/debitnoteExcelDetails",
  DOWNLOAD_DEBIT_NOTES_REPORT_API: "/api/auth/debitNoteHistoryExcel",

  //3.3.2 ---------Deleted Reciepts---------
  GET_DELETED_RECEIPT_API: "/api/auth/reportDeletedReceipt",
  GET_RECIEPT_DETAILS_VIEW_API: "/api/auth/receiptdetails",

  GET_RECIEPT_DETAILS_API: "/api/auth/residencePaymentPrintNew",

  GET_RECEIPTS_LIST_API: "/api/auth/receiptsReport",
  GET_INCOMPLETE_TRANSACTION_API: "/api/auth/incomplete-transaction-report",
  GET_INCOMPLETE_TRANSACTION_DETAILS_API: "/api/auth/incomplete-transaction-receipt",

  //3.3.3 ---------Cheque Deposit Sip---------
  GET_CHEQUE_DEPOSIT_SLIP_REPORT_API: "/api/auth/chequeDespoiteSlipsReport",

  //3.2---------Billing Management------------
  //3.2.1 ---------Manage Advance notes---------
  GET_ADVANCE_NOTE_LIST_API: "/api/auth/advanceNoteList",
  ADD_ADVANCE_NOTE_API: "/api/auth/addAdvanceNote",
  UPDATE_ADVANCE_NOTE_API: "/api/auth/updateAdvanceNote",
  DELETE_ADVANCE_NOTE_API: "/api/auth/deleteAdvanceNote",
  GET_ADVANCE_NOTE_DETAILS_API: "/api/auth/singleAdvanceNote",
  PRINT_ADVANCE_NOTE_RECIEPT_API: "/api/auth/residenceAdvanceReceiptPrintV1",
  // PRINT_ADVANCE_NOTE_RECIEPT_API: "/api/auth/residenceAdvanceReceiptPrint",
  GET_NON_MEMBER_LIST_API: "/api/auth/nonMemberDebitAccounts",
  UPLOAD_ADVANCE_NOTE_EXCEL_API: "/api/auth/uploadAdvanceExcel",
  SAVE_ADVANCE_NOTE_EXCEL_API: "/api/auth/saveAdvanceExcel",

  //3.2.2 ---------Manage Invoice---------
  MANAGE_CURRENT_INVOICE_API: "/api/auth/manageInvoices",
  MANAGE_DRAFT_INVOICE_API: "/api/auth/draftInvoices",
  MANAGE_PREVIOUS_INVOICE_API: "/api/auth/previousInvoices",
  INVOICE_ACTIONS_LIST_API: "/api/auth/invoiceActionList",
  ADJUST_PAYMENT_DETAILS_API: "/api/auth/adjustPaymentDetail",
  ADJUST_PAYMENT_GET_AMOUNT_API: "/api/auth/adjustPaymentGetAmount",
  ADJUSTMENT_PAYMENT_SAVE_API: "/api/auth/adjustPaymentsave",
  UPDATE_INVOICE_REMARKS_API: "/api/auth/updateInvoiceRemarks",
  DELETE_MANAGE_INVOICE_API: "/api/auth/deleteInvoice",
  REJECT_MANAGE_INVOCIE_API: "/api/auth/rejectInvoicePayment",

  DOWNLOAD_PAYMENT_EXCEL_API: "/api/auth/downloadSocietyPaymentExcel",
  DETAIL_SOCIETY_PAYMENT_API: "/api/auth/detailSocietyPaymentExcel",
  UPLOAD_PAYMENT_EXCEL_API: "/api/auth/saveSocietyPaymentExcel",

  GET_EDIT_INVOICE_API: "/api/auth/getInvoicesDetail",
  UPDATE_INVOICE_API: "/api/auth/updateInvoicesDetail",

  VERIFY_POST_PAYMENT_DETAILS_API: "/api/auth/verifyPostPaymentDetail",
  VERIFY_POST_PAYMENT_UPDATE_API: "/api/auth/verifyPostPaymentUpdate",
  BULK_VERIFY_POST_PAYMENT_UPDATE_API: "/api/auth/bulkPaymentVerify",

  SEND_INVOICE_REMINDER_EMAIL_SMS: "/api/auth/sendInvoiceReminderEmailSms",
  SEND_RESEND_RECEIPT_EMAIL_SMS: "/api/auth/resendInvoiceReceiptEmailSms",
  SEND_INVOICE_EMAIL_SMS: "/api/auth/resendInvoiceEmailSms",

  DOWNLOAD_INVOICE_DRAFT_INVOICE_API: "/api/auth/downloadInvoiceDraftExcel",
  // generateInvocie
  GENERATE_INVOICE_API: "/api/auth/generateInvoices",
  INVOCIE_GENERATOR_LIST_API: "/api/auth/invoicegenereterlist",
  GET_BILL_PERIOD_API: "/api/auth/getBillPeriod",
  GET_CREDIT_NOTE_ADJUSTMENT_API: "/api/auth/getCreditnoteAdjustment",
  GET_DEBIT_NOTE_ADJUSTMENT_API: "/api/auth/getDebitnoteAdjustment",
  GET_FLAT_LIST_OCCUPANTS_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getWingFlatListWithOccupantName",
  DOWNLOAD_INVOICE_EXCEL_API: "/api/auth/downloadInvoiceExcel",
  UPLOAD_INVOICE_EXCEL_FORMAT_API: "/api/auth/detailsInvoiceExcel",

  //3.2.3 ---------Manage Debit notes---------
  MANAGE_DEBIT_NOTE_CURRENT_API: "/api/auth/currentDebitNoteList",
  MANAGE_DEBIT_NOTE_ACTION_LIST_API: "/api/auth/debitNoteActionsList",
  BULK_VERIFY_POST_MANAGE_DEBIT_NOTE_UPDATE_API: "/api/auth/postDebitNotePayment",
  RESEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_API: "/api/auth/debitNoteResendInvoiceEmailSms",
  BULK_MAKE_PAYMENT_MANAGE_DEBIT_NOTE_SAVE_API: "/api/auth/saveDebitNotePaymentExcel",
  DEBIT_NOTE_RECEIPT_PRINT_API: "/api/auth/debitNotePaymentPrint",
  RESEND_DEBIT_NOTE_RECEIPT_EMAIL_SMS_API: "/api/auth/debitNoteResendReceiptEmailSms",
  DELETE_DEBIT_NOTE_RECEIPT_API: "/api/auth/deleteDebitNoteReceipt",

  SINGLE_DEBIT_NOTE_API: "/api/auth/singleDebitNoteV1",
  REJECT_SINGLE_RECEIPT_DEBIT_NOTE_API: "/api/auth/deleteSingleDebitNoteReceipt",
  EDIT_SINGLE_DEBIT_NOTE_DETAILS_API: "/api/auth/getDebitNoteDetails",
  EDIT_SINGLE_DEBIT_NOTE_SAVE_API: "/api/auth/updateDebitNote",
  ADJUST_PAYMENT_DETAILS_DEBIT_NOTE_API: "/api/auth/adjustmentDetailDebitNote",
  ADJUST_PAYMENT_SAVE_DEBIT_NOTE_API: "/api/auth/debitNoteAdjustment",
  MAKE_PAYMENT_DETAILS_DEBIT_NOTE_API: "/api/auth/debitNotePaymentDetails",
  MAKE_PAYMENT_SAVE_DEBIT_NOTE_API: "/api/auth/debitNoteMakePayment",
  SEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_API: "/api/auth/debitNoteSendDueReminderEmailSms",
  DELETE_DEBIT_NOTE_CURRENT_API: "/api/auth/deleteDebitNote",

  UPLOAD_DEBIT_NOTE_CURRENT_EXCEL_API: "/api/auth/debitnoteExcelDetails",
  DOWNLOAD_DEBIT_NOTE_CURRENT_EXCEL_API: "/api/auth/debitnoteExcelDownload",
  UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT_API: "/api/auth/detailDebitNotePaymentExcel",
  DOWNLOAD_DEBIT_NOTE_CURRENT_PAYMENT_API: "/api/auth/debitNotePaymentExcelDownload",

  MANAGE_DEBIT_NOTE_PREVIOUS_API: "/api/auth/previousDebitNoteList",
  MANAGE_DEBIT_NOTE_DRAFT_API: "/api/auth/draftDebitNoteList",
  DEBIT_NOTE_INVOICE_PRINT_API: "/api/auth/debitNoteInvoicePrintNew",
  // DEBIT_NOTE_INVOICE_PRINT_API: "/api/auth/debitNoteInvoicePrint",
  // DEBIT_NOTE_PAYMENT_PRINT_API: "",
  // DEBIT_NOTE_ACTIONS_LIST_API: "/api/auth/debitNoteActionsList",
  // SINGLE_DEBIT_NOTE_API: "/api/auth/singleDebitNote",
  DEBIT_NOTE_TARRIF_LIST_API: "/api/auth/singleDebitnoteGenerater",
  DEBIT_NOTE_FLAT_WING_WISE_LIST_API: "/api/auth/flatWingWise",
  DEBIT_NOTE_ARREAR_DETAILS_API: "/api/auth/debitnoteArearDetail",
  // DEBIT_NOTE_PREVIOUS_DATE_DETAILS_API: "/api/auth/getPreviousBillDate",
  ADD_DEBIT_NOTE_API: "/api/auth/debitnoteGenerate",

  //3.2.4 ---------Manage Credit notes---------
  GET_CREDIT_NOTE_LIST_API: "/api/auth/creditNoteList",
  ADD_CREDIT_NOTE_API: "/api/auth/addCreditNote",
  UPDATE_CREDIT_NOTE_API: "/api/auth/updateCreditNote",
  DELETE_CREDIT_NOTE_API: "/api/auth/deleteCreditNote",
  GET_CREDIT_NOTE_DETAILS_API: "/api/auth/singleCreditNote",
  PRINT_CREDIT_NOTE_INVOICE_API: "/api/auth/creditInvoicePrintV1",
  // PRINT_CREDIT_NOTE_INVOICE_API: "/api/auth/creditInvoicePrint",
  GET_FLAT_WISE_INVOICE_LIST_API: "/api/auth/flatWingWise",
  DOWNLOAD_CREDIT_NOTE_EXCEL_SAMPLE_API: "/api/auth/downloadCreditNoteExcel",
  DOWNLOAD_ADVANCE_EXCEL_SAMPLE_API: "/api/auth/downloadAdvanceExcel",
  UPLOAD_CREDIT_NOTE_EXCEL_API: "/api/auth/uploadCreditNoteExcel",
  SAVE_CREDIT_NOTE_EXCEL_API: "/api/auth/saveCreditNoteExcel",

  //3.3---------Billing Report------------

  //3.3.6 ---------Member Due Summary---------
  GET_MEMBER_DUE_SUMMARY_API: "/api/auth/membersDueSummaryReport",

  //3.3.7 ---------Member Bill Due Details---------
  GET_MEMBER_BILL_DUE_REPORT_API: "/api/auth/membersBillDueReport",

  //3.3.8 ---------MEMBER BILL DUE DETAILS---------
  GET_OPENING_BALANCE_DATA_API: "/api/auth/getOpeningBalancesList",

  //3.3.1 ---------Counted Invoice Report---------
  GET_COUNTED_INVOICE_REPORT_API: "/api/auth/countedInvoiceReport",
  //3.5---------Setting------------
  //3.5.1 ---------Credit/Debit cause---------
  GET_TARIFF_HEADER_LIST_API: "/api/auth/tariffHeaderList",
  GET_TARIFF_HEADER_DETAILS_API: "/api/auth/tariffHeaderSingle",
  GET_TARIFF_HEADER_AUDIT_LOGS_API: "/api/auth/tariffHeaderViewLog",
  GET_TARIFF_HEADER_ALL_AUDIT_LOGS_API: "/api/auth/tariffHeaderAllLogs",
  UPDATE_TARIFF_HEADER_API: "/api/auth/updateTariffHeader",
  ADD_TARIFF_HEADER_API: "/api/auth/addTariffHeader",
  GET_GL_MAPPING_LIST_API: "/api/auth/tariffGlMappingList",
  GET_DEBIT_NOTE_SETTINGS_API: "/api/auth/debitNoteSettingDetail",
  UPDATE_DEBIT_NOTE_SETTINGS_API: "/api/auth/debitNoteSettingSave",
  GET_ACCOUNT_LEDGER_HEADER_LIST_API: "/api/auth/tariffGlMappingList",

  //3.5.2 ---------Credit/Debit cause---------


  //3.5.3 ---------Due Reminder---------
  GET_DUE_REMINDER_DETAILS_API: "/api/auth/reminderDetail",
  UPDATE_DUE_REMINDER_DETAILS_API: "/api/auth/addReminder",
  DELETE_DUE_REMINDER_API: "/api/auth/deleteReminder",
  GET_DEBIT_NOTE_DUE_REMINDER_DETAILS_API: "/api/auth/debitNoteReminder",
  UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS_API: "/api/auth/saveDebitNoteReminder",
  GET_FD_MATURITY_DUE_REMINDER_DETAILS_API: "/api/auth/fdDueReminder",
  UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS_API: "/api/auth/saveFdDueReminder",
  GET_MEMBER_DUE_REMINDER_DETAILS_API: "/api/auth/memberDueReminder",
  UPDATE_MEMBER_DUE_REMINDER_DETAILS_API: "/api/auth/saveMemberDueReminder",
  GET_NOMINEE_DUE_REMINDER_DETAILS_API: "/api/auth/nomineeReminder",
  UPDATE_NOMINEE_DUE_REMINDER_DETAILS_API: "/api/auth/saveNomineeReminder",
  GET_MEMBER_LIST_ACCORDING_TO_ROLE_API: "/api/auth/getMember",

  //4. -----Compliance------
  //4.1---------Registers------------
  GET_MEMBER_LIST_FORMAT_I_API: "/api/society/zipone/compliance/registers/getMemberListFormatI",
  GET_MEMBER_REGISTER_DETAILS_API: "/api/society/zipone/compliance/registers/getMemberRegisterDetails",
  GET_MEMBER_LIST_FORMAT_J_API: "/api/society/zipone/compliance/registers/getMemberListFormatJ",
  GET_MEMBER_SHARE_REGISTER_LIST_API: "/api/society/zipone/compliance/registers/getMemberShareRegisterList",
  GET_MEMBER_MORTGAGE_REGISTER_LIST_API: "/api/society/zipone/compliance/registers/getMemberMortgageRegisterList",
  GET_MEMBER_MORTGAGE_REGISTER_DETAILS_API: "/api/society/zipone/compliance/registers/getMemberMortgageRegisterDetails",
  GET_NOMINATION_REGISTER_LIST_API: "/api/society/zipone/compliance/registers/getNominationRegisterList",
  GET_PROPERTY_REGISTER_DATA_API: "/api/society/zipone/compliance/registers/getPropertyRegisterData",

  //4.2---------Meetings------------
  GET_NOTICE_LIST_API: "/api/society/zipone/compliance/meetings/getNoticeList",
  GET_SAVED_NOTICE_DETAILS_API: "/api/society/zipone/compliance/meetings/viewSavedNotice",
  UPDATE_SAVED_NOTICE_API: "/api/society/zipone/compliance/meetings/updateSavedNotice",
  DELETE_SAVED_NOTICE_API: "/api/society/zipone/compliance/meetings/deleteSavedNotice",

  GET_SAVED_NOTICE_AUDIT_API: "/api/society/zipone/compliance/meetings/viewSavedNotice",


  //5.-----------Desk Module ----------------
  // -----------------Complaint Dashboard -------------
  COMPLAINT_DASHBOARD_CONTENT_API: "/api/complaints/zipone/dashboardStuff",
  COMPLAINT_DASHBOARD_LIST_API: "/api/complaints/zipone/dashboardComplaintList",
  COMPLAINT_AGING_LIST_API: "/api/complaints/zipone/complaintAging",
  VIEW_COMPLAINT_LOGGED_DETAILS_API: "/api/complaints/zipone/getLoggedTicketDetail",
  GET_ASSIGNED_COMPLAINT_DASHBOARD_API: "/api/complaints/zipone/getAssignedDetailForComplaint",
  ADD_ASSIGNED_COMPLAINT_API: "/api/complaints/zipone/assignComplaint",
  GET_REQ_SUB_ROLES_API: "/api/complaints/zipone/getRequiredSubRoles",
  GET_REQ_ROLES_API: "/api/complaints/zipone/getRequiredRoles",
  ASSIGNED_USER_LIST_ACC_VENDORS_API: "/api/complaints/zipone/getUserListAccordingToSubRoles",
  CHANGE_COMPLAINT_STATUS_API: "/api/complaints/zipone/changeComplaintStatus",
  DOWNLOAD_COMPLAINT_EXCEL_API: "/api/complaints/zipone/getAllcomplaintsInExcel",
  VIEW_POST_COMMENT_API: "/api/complaints/zipone/viewCommentAndAttachments",
  ADD_POST_COMMENT_API: "/api/complaints/zipone/addCommentAndStatus",
  GET_COMPLAINT_STATUS_API: "/api/complaints/zipone/getComplaintStatusTypes",
  GET_SOCIETY_LIST_API: "/api/complaints/zipone/getSocitiesForAssigningComplaint",
  ADD_COMPLAINT_DASHBOARD_REVIEW_API: "/api/complaints/zipone/addReviewRatingAndComment",
  // --------------Log A Complaint ------------------
  ADD_LOG_A_COMPLAINT_API: "/api/complaints/zipone/logTicket",
  ADD_LOG_A_COMPLAINT_AUDIT_LOG_API: "/api/complaints/zipone/getLoggedTicketAuditLogs",
  EDIT_COMPLAINT_API: "/api/complaints/zipone/editLoggedTicket",
  COMPLAINT_NATURE_LIST_API: "/api/complaints/zipone/getComplaintNatureList",
  COMPLAINT_TYPE_API: "/api/complaints/zipone/getComplaintTypeList",
  COMPLAINT_GET_WING_LIST_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getWingList",
  COMPLAINT_GET_FLAT_ID_BY_WING_ID_API: "/api/society/zipone/setupAndRecords/occupantsTenants/getFlatListByWingId",

  // -----------Manage Complaint Category---------
  COMPLAINT_CATEGORY_LIST_API: "/api/complaints/category/getComplaintCategoryList",
  COMPLAINT_CATEGORY_DETAILS_LIST_API: "/api/complaints/category/getComplaintCategoryDetails",
  DELETE_COMPLAINT_CATEGORY_API: "/api/complaints/category/deleteComplaintCategory",
  EDIT_COMPLAINT_CATEGORY_API: "/api/complaints/category/editComplaintCategory",
  ADD_COMPLAINT_CATEGORY_API: "/api/complaints/category/addComplaintCategory",
  GET_COMPLAINT_CATEGORY_AUDIT_LOG_API: "/api/complaints/category/getComplaintCategoryAuditLogs",
  
  //     api/universal/changeStatus

  DOCUMENTS_LIST_API: "/api/complaints/folder/getFolderList",
  GET_DOCUMENT_FILE_LIST_API: "/api/complaints/file/getFileList",
  GET_DOCUMENT_FILE_DETAILS_LIST_API: "/api/complaints/file/getFileDetailById?id=1536",
  GET_COMPLAINT_OCCUPANTS_LIST_API: "/api/complaints/file/getOccupantsList",
  ADD_FOLDER_API: "/api/complaints/folder/addFolder",
  ADD_DOCUMENT_API: "/api/complaints/file/addDocument",
  EDIT_DOCUMENT_API: "/api/complaints/file/editDocument",
  DELETE_DOCUMENT_API: "/api/complaints/file/deleteDocument",

  // -----------email and sms setting -----------
  VIEW_EMAIL_AND_SMS_SETTINGS_API: "/api/complaints/zipone/emailSmsSetting",
  EDIT_EMAIL_AND_SMS_SETTINGS_API: "/api/complaints/zipone/editEmailSmsSetting",

  // complaint escalation settings
  VIEW_ESCALATION_SETTINGS_API: "/api/complaints/zipone/viewEscalationSetting",
  EDIT_ESCALATION_SETTINGS_API: "/api/complaints/zipone/editEscalationSetting",

  // complaint setting

  GET_COMPLAINT_SETTINGS_API: "/api/complaints/zipone/setting",
  UPDATE_COMPLAINT_SETTING_API: "/api/complaints/zipone/updateSetting",
  VIEW_COMPLAINT_TYPE_SETTING_API: "/api/complaints/zipone/getComplaintTypeCommunitySetting",
  UPDATE_COMPLAINT_TYPE_SETTING_API: "/api/complaints/zipone/changeComplaintTypeCommunitySetting",

  //6. -----Club------
  //6.1---------View Booking------------
  GET_BOOKING_LIST_API: "/api/zipone/facility/book/bookingList",
  GET_BOOKING_STATUS_LIST_API: "/api/zipone/facility/book/bookingStatusList",
  GET_BOOKING_DETAILS_API: "/api/zipone/facility/book/bookingDetails",
  MAKE_BOOKING_ADJUSTMENT_API: "/api/zipone/facility/book/makeBookingAdjustment",
  GET_FACILITY_REPORT_API: "/api/zipone/facility/getFacilityReport",
  GET_ALL_FACILITY_AUDIT_LOG_API: "/api/zipone/facility/getFacilityAuditLogs",
  CHANGE_BOOKING_STATUS_API: "/api/zipone/facility/book/changeBookingStatus",
  DOWNLOAD_FACILITY_REPORT_EXCEL_API: "/api/zipone/facility/downloadFacilityReport",
  GET_BOOKING_GL_MAPPING_LIST_API: "/api/zipone/facility/book/getGlAccountListitng",
  GET_BOOKING_BANK_ACCOUNT_LIST_API: "/api/zipone/facility/book/getGlBankList",
  GET_FACILTIY_ONLINE_PAYMENT_LINK_API: "/api/facilities-online-payment-link",
  MAKE_BOOKING_PAYMENT_API: "/api/auth/makePaymentInvoice",
  GET_BOOKING_INVOICE_API: "/api/auth/invoicePaymentDetails",

  //6.2---------Facility------------
  GET_FACILITY_LIST_API: "/api/zipone/facility/facilityList",
  GET_TIME_SLOT_LIST_API: "/api/zipone/facility/timeSlot/viewTimeSlot",
  GET_BOOKING_FREQUENCY_LIST_API: "/api/zipone/facility/book/getFrequencyList",
  GET_AVAILABLE_TICKETS_API: "/api/zipone/facility/book/getAvailableTickets",
  GET_FACILITY_DETAILS_API: "/api/zipone/facility/facilityDetailsById",
  UPDATE_FACILITY_API: "/api/zipone/facility/updateFacility",
  GET_FACILITY_AUDIT_LOGS_API: "/api/zipone/facility/getFacilityAuditLogsById",
  DELETE_FACILITY_API: "/api/zipone/facility/deleteFacilityById",
  ADD_UPDATE_TIME_SLOT_API: "/api/zipone/facility/timeSlot/addUpdateTimeSlot",
  GET_RATE_CHART_API: "/api/zipone/facility/rateChart/getRateChart",
  ADD_UPDATE_RATE_CHART_API: "/api/zipone/facility/rateChart/addUpdateRateChart",
  ADD_BOOKING_API: "/api/zipone/facility/book/addBooking",
  GET_FACILITY_BOOKINGS_AUDIT_LOGS_API: "/api/zipone/facility/book/getFacilityBookingsAuditLogs",
  CHECK_BOOKING_AVAILIBILITY_API: "/api/zipone/facility/book/checkAvailibility",
  CHANGE_FACILITY_STATUS_API: "/api/zipone/facility/changeFacilityStatus",
  DELETE_TIME_SLOT_API: "/api/zipone/facility/timeSlot/deleteTimeSlot",
  DELETE_RATE_CHART_API: "/api/zipone/facility/rateChart/deleteRateChart",
  SEND_MESSAGE_TO_ADMINISTRATOR_API: "/api/universal/sendEmailToAdmin",

  //6.3---------Add Facility------------
  GET_ROLE_LIST_API: "/api/complaints/zipone/getRequiredSubRoles",
  GET_ROLE_WISE_LIST_API: "/api/complaints/zipone/getUserListAccordingToSubRoles",
  GET_VENDOR_CONCERNED_PERSONS_LIST_API: "/api/complaints/zipone/getVendorsConcernedPerson",
  ADD_FACILITY_API: "/api/zipone/facility/addFacility",

  //6.4---------Add TimeSlot------------
  GET_TIME_SLOT_TYPE_LIST_API: "/api/zipone/facility/timeSlot/getFacilityTypeList",
  ADD_TIME_SLOT_TYPE_API: "/api/zipone/facility/timeSlot/addFacilityType",
  UPDATE_TIME_SLOT_TYPE_API: "/api/zipone/facility/timeSlot/editFacilityType",
  DELETE_TIME_SLOT_TYPE_API: "/api/zipone/facility/timeSlot/deleteFacilityType",

  //6.5---------Add Rate-Chart------------

  //7. -----Gate Management------
  //7.1---------Visitor & vehicle in and out------------
  GET_FLAT_MEMBER_LIST_API: "/api/prop-tech/zip-gate/gate/admin/getFlatOwnersData",
  GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA_API: "/api/prop-tech/zip-gate/gate/admin/visitorsManagementDashboard",
  GET_VISITOR_VEHICLE_LOG_API: "/api/prop-tech/zip-gate/gate/admin/getVisitorVehiclesLogs",
  DOWNLOAD_VISITOR_VEHICLE_LOG_API: "/api/prop-tech/zip-gate/gate/admin/downloadVisitorVehiclesLogs",
  GET_SOCIETY_ENTRY_GATE_API: "/api/prop-tech/zip-gate/gate/admin/getSocietyEntryGates",
  ADD_UPDATE_SOCIETY_ENTRY_GATE_API: "/api/prop-tech/zip-gate/gate/admin/addUpdateEntryGate",
  DELETE_SOCIETY_ENTRY_GATE_API: "/api/prop-tech/zip-gate/gate/admin/deleteEntryGate",
  GET_GATE_ACCESS_DATA_API: "/api/prop-tech/zip-gate/gate/admin/getGateAccessData",
  ADD_UPDATE_GATE_ACCESS_DATA_API: "/api/prop-tech/zip-gate/gate/admin/addUpdateGateAccessData",
  GET_VISITOR_LOG_API: "/api/prop-tech/zip-gate/gate/admin/getVisitorLogs",
  DOWNLOAD_VISITOR_LOG_API: "/api/prop-tech/zip-gate/gate/admin/downloadVisitorLogs",
  GET_FREQUENT_VISITOR_LIST_API: "/api/prop-tech/zip-gate/gate/admin/getFrequentVisitors",
  GET_EXPECTED_VISITOR_LIST_API: "/api/prop-tech/zip-gate/gate/admin/getExpectedtVisitors",
  UPDATE_VISITOR_STATUS_API: "/api/prop-tech/zip-gate/gate/admin/updateVisitorStatus",
  GET_STAFF_DETAILS_BY_STAFF_CODE_API: "/api/prop-tech/zip-gate/gate/admin/getStaffDetailsByStaffCode",
  GET_VISITOR_PURPOSE_LIST_API: "/api/prop-tech/zip-gate/gate/admin/getVisitorPurposeList",
  DELETE_VISITOR_PURPOSE_LIST_API: "/api/prop-tech/zip-gate/gate/admin/deleteVisitorPurpose",
  GET_VISITORS_DATA_API: "/api/prop-tech/zip-gate/gate/admin/getVisitorsData",
  SEND_OTP_TO_VISITOR_API: "/api/prop-tech/zip-gate/gate/admin/sendOtpTovisitor",
  ADD_NEW_VISITOR_API: "/api/prop-tech/zip-gate/gate/admin/addNewVisitor",
  STAFF_CHECKIN_API: "/api/prop-tech/zip-gate/gate/admin/staffCheckIn",
  GET_VISITOR_PASS_DATA_API: "/api/prop-tech/zip-gate/gate/admin/printVisitorLog",

  //7.2---------Parking Management------------
  GET_PARKING_TYPE_LIST_API: "/api/society/zipone/gateManagement/parkingManagement/getParkingTypeList",
  GET_PARKING_TYPE_DETAILS_API: "/api/society/zipone/gateManagement/parkingManagement/getParkingTypeDetail",
  ADD_UPDATE_PARKING_TYPE_DETAILS_API: "/api/society/zipone/gateManagement/parkingManagement/addUpdateParkingTypeData",
  DELETE_PARKING_TYPE_DETAILS_API: "/api/society/zipone/gateManagement/parkingManagement/deleteParkingTypeData",
  GET_PARKING_CHARGE_LIST_API: "/api/society/zipone/gateManagement/parkingManagement/getParkingChargesList",
  ADD_UPDATE_PARKING_CHARGE_LIST_API: "/api/society/zipone/gateManagement/parkingManagement/addUpdateParkingCharges",
  GET_PARKING_CHARGE_AUDIT_LOGS_API: "/api/society/zipone/gateManagement/parkingManagement/getParkingChangesAuditLogs",

  // -------------------Reports ------------------------

  // -------------------Society Reports ------------------------
  GET_SOCIETY_EMAIL_REPORTS_API: "/api/auth/society-reports/society-email",
  GET_MEMBER_LEDGER_REPORTS_API: "/api/auth/memberLedgerReport",

  // ----------------Finance Reports--------------------------

  // ---------------Debit Note Invoice Report---------------------
  DEBIT_NOTE_INVOICE_REPORT_API: "/api/auth/finance-reports/debit-note-invoices",

  // ---------------Invoice Report---------------------
  INVOICE_REPORT_API: "/api/auth/finance-reports/invoice-report",

  // ---------------Receipt Report---------------------
  RECEIPT_REPORT_API: "/api/auth/finance-reports/invoice-receipt-report",
  DOWNLOAD_RECEIPT_REPORT_API: "/api/auth/finance-reports/invoice-receipt-report",

  // ---------------Supplementary Bill Report---------------------
  SUPPLEMENTARY_BILL_REPORT_API: "/api/auth/finance-reports/supplementary-bill-report",

  // ---------------Debit Note Receipt Report---------------------
  DEBIT_NOTE_RECEIPT_REPORT_API: "/api/auth/finance-reports/debit-note-receipt-report",
  DOWNLOAD_DEBIT_NOTE_RECEIPT_REPORT_API: "/api/auth/finance-reports/debit-note-receipt-report",

  // ---------------Deleted Invoice Report---------------------
  DELETED_INVOICE_REPORT_API: "/api/auth/finance-reports/deleted-invoice-report",

  // ---------------Deleted Debit Note Report---------------------
  DELETED_DEBIT_NOTE_REPORT_API: "/api/auth/finance-reports/deleted-debit-note-report",
  DOWNLOAD_DELETED_DEBIT_NOTE_REPORT_API: "/api/auth/finance-reports/deleted-debit-note-report",

   // ---------------Bulk Invoice Report---------------------
   BULK_ASYNC_JOBS_ALL_API: "/api/auth/asyncJobs",


  // ---------------Invoice Interest Report---------------------
  INVOICE_INTEREST_REPORT_API: "/api/auth/finance-reports/invoice-interest-report",

  // --------------- Interest Calculation Report---------------------
  GET_INTEREST_CALCULATION_REPORT_API: "/api/auth/finance-reports/interest-calculation-report",

  // --------------- Reconciliation Report---------------------
  GET_RECONCILIATION_REPORT_API: "/api/auth/finance-reports/reconcilliation-report",

  // --------------- Debit Note Interest Calculation Report---------------------
  GET_DEBITNOTE_INTEREST_CALCULATION_REPORT_API: "/api/auth/finance-reports/debitnote-interest-calculation-reportV1",
  DOWNLOAD_DEBITNOTE_INTEREST_CALCULATION_REPORT_API: "/api/auth/incomplete-transaction-report",

  //---------------- Invoice Aging Report ---------------------
  GET_INVOICE_AGING_REPORT: "/api/auth/finance-reports/invoice-aging-report",

  //----------------- Invoice Audit Report -------------------------------
  GET_INVOICE_AUDIT_REPORT: "/api/auth/finance-reports/invoice-audit-report",

  DELETE_FINANCE_REPORT_EXCEL_API: "/api/auth/delete-file-bucket",

   // --------------E Invoice  Report -----------------------
  E_INVOICE_REPORT_API : "/api/auth/einvoiceReport",

  // --------------Gst  Report -----------------------
  GST_REPORT_API: "/api/auth/gstReport",


  // --------------Old Financial Reports -----------------------

  // -------------Bill Generation Reports------------------

  VIEW_FINANCE_BILL_API: "/api/arc/billGeneration/viewFinanceBill",
  DOWNLOAD_FINANCE_BILL_API: "/api/arc/billGeneration/downloadFinanceBill",
  FINANCE_MEMBER_LIST_API: "/api/arc/billGeneration/financeMemberList",
  BILL_TYPE_LIST_API: "/api/arc/billGeneration/billTypeList",

  // -------------BILL Reports --------------------------

  MEMBER_BILL_REPORTS_API: "/api/arc/billReport/bill/memberBill",
  SOCIETY_BILL_REPORTS_API: "/api/arc/billReport/bill/societyBill",
  INTEREST_STATEMENT_REPORTS_API: "/api/arc/billReport/bill/interestStatement",
  E_INVOICE_REPORTS_API: "/api/arc/billReport/bill/eInvoiceReport",

  // -------------------- Finance Reports -------------------------

  OUTSTANDING_STATEMENT_API: "/api/arc/financeReport/outstandingStatement",
  OUTSTANDING_STATEMENT_CSV_DOWNLOAD_API: "/api/arc/financeReport/csv/outstandingStatement",

  // -------------------- Accounts Reports -------------------------
  GROUP_TRAIL_BALANCE_API: "/api/arc/accountReport/groupTrialBalance",
  DOWNLOAD_CSV_GROUP_TRAIL_BALANCE_API: "/api/arc/accountReport/csv/groupTrialBalance",
  DOWNLOAD_PDF_GROUP_TRAIL_BALANCE_API: "/api/arc/accountReport/pdf/groupTrialBalance",
  OPENING_BALANCE_API: "/api/arc/accountReport/openingBal",
  DOWNLOAD_CSV_OPENING_BALANCE_API: "/api/arc/accountReport/csv/openingBal",
  DOWNLOAD_PDF_OPENING_BALANCE_API: "/api/arc/accountReport/pdf/openingBal",
  RECEIPT_PAYMENT_API: "/api/arc/accountReport/receiptPayment",
  DOWNLOAD_CSV_RECEIPT_PAYMENT_API: "/api/arc/accountReport/csv/receiptPayment",
  DOWNLOAD_PDF_RECEIPT_PAYMENT_API: "/api/arc/accountReport/pdf/receiptPayment",
  CASH_BANK_BOOK_API: "/api/arc/accountReport/cashBankBook",
  DOWNLOAD_CSV_CASH_BANK_BOOK_API: "/api/arc/accountReport/csv/cashBankBook",
  DOWNLOAD_PDF_CASH_BANK_BOOK_API: "/api/arc/accountReport/pdf/cashBankBook",
  JOURNALS_API: "/api/arc/accountReport/journals",
  DOWNLOAD_CSV_JOURNALS_API: "/api/arc/accountReport/csv/journals",
  DOWNLOAD_PDF_JOURNALS_API: "/api/arc/accountReport/pdf/journals",
  CHART_OF_ACCOUNT_BOOK_DATA_API: "/api/arc/accountReport/chartOfAccountBookData",
  LEDGERS_API: "/api/arc/accountReport/ledgers",
  DOWNLOAD_CSV_LEDGERS_API: "/api/arc/accountReport/csv/ledgers",
  DOWNLOAD_PDF_LEDGERS_API: "/api/arc/accountReport/pdf/ledgers",
  // ALL_MEMBER_LEDGER_API: "/api/arc/accountReport/reports/csv/allMemberLedger",
  DOWNLOAD_CSV_ALL_MEMBERS_REPORT_API: "/api/arc/accountReport/reports/csv/allMemberLedger",

  GET_CONTENT_MANAGEMENT_TEMPLATE_LIST_API: "api/auth/contentManagementList",
  VIEW_CONTENT_MANAGEMENT_TEMPLATE_API: "/api/auth/viewContentManagement",
  UPDATE_CONTENT_MANAGEMENT_TEMPLATE_API: "/api/auth/updateContentManagement",
  AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE_API: "/api/auth/auditLogContentManagement",

};

export default apiEndpoints;
