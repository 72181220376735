import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification' 
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/BuildingReqs/SpecificDetailsReqs'

function* getSpecificDetails(action) {
    try {
        const result = yield call(req.getSpecificDetailsReq);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SPECIFIC_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_SPECIFIC_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateSpecificDetails(action) {
    try {
        const result = yield call(req.updateSpecificDetailsReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            yield put({ type: types.UPDATE_SPECIFIC_DETAILS_SUCCESS, payload: result.data});  
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.UPDATE_SPECIFIC_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSpecificDetailsAuditLogs(action) {
    try {
        const result = yield call(req.getSpecificDetailsAuditLogsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SPECIFIC_DETAILS_AUDIT_LOGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_SPECIFIC_DETAILS_AUDIT_LOGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* specificDetailsSaga() {
    yield takeLatest(types.GET_SPECIFIC_DETAILS, getSpecificDetails);
    yield takeLatest(types.UPDATE_SPECIFIC_DETAILS, updateSpecificDetails);
    yield takeLatest(types.GET_SPECIFIC_DETAILS_AUDIT_LOGS, getSpecificDetailsAuditLogs);
}