import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import {logOut} from '../../../../utils/helpers/logOut'
import getBrowserDetails from '../../../../utils/helpers/getBrowserDetails'
import { errorMsg } from '../../../../consts/responseMessages';

export async function getSpecificDetailsReq(action) {

    return axiosInstance.get(apiEndpoints.GET_SPECIFIC_DETAILS_API)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateSpecificDetailsReq(action) {
    const BODY = {
        "action": action.data?.action,
        "specific_id": action.data?.id || 0,
        "reg_no": action.data?.registrationNo,
        "date_of_reg": action.data?.dateOfRegistration,
        "act": action.data?.act,
        "total_build_up_area": action.data?.buildUpArea,
        "construction_cost_per_unit": action.data?.constructionCosttPerUnit,
        "area_of_plot": parseFloat(action.data?.areaOfPlot),
        "build_up_option": parseInt(action.data.areaType),
        "construction_unit": action.data?.constructionUnit,
        "value_per_share": action.data?.valuePerShare,
        "total_water_inlets": parseFloat(action.data?.noOfWaterInlets),
        "ip":action.data?.ipAddress,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId
      }

      return axiosInstance.put(apiEndpoints.UPDATE_SPECIFIC_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getSpecificDetailsAuditLogsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_SPECIFIC_DETAILS_AUDIT_LOGS_API}?flag=society_specific${action.data.societyId ? `&society_id=${action.data.societyId}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors
        })
}