import { notify } from '../../../utils/notification';
import apiEndpoints from '../../api';
import axiosInstance2 from '../../axiosInstance2'
import { logOut } from '../../../utils/helpers/logOut'
import { errorMsg } from '../../../consts/responseMessages';



export async function getDebitNotesReportReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('transactionid', action.data?.transactionId);
    bodyFormData.append('modeofpayment', action.data?.modeOfPayment);
    bodyFormData.append('invoice_type', action.data?.InvoiceType);
    bodyFormData.append('from_date', action.data?.fromDate);
    bodyFormData.append('to_date', action.data?.toDate);
    bodyFormData.append('wing_no', action.data?.wingId);
    bodyFormData.append('flat_id', action.data?.flatId);
    bodyFormData.append('search_by', action.data?.searchBy);
    bodyFormData.append('page', action.data?.page);
    bodyFormData.append('limit', action.data?.pageLimit);


    return axiosInstance2.post(apiEndpoints.GET_DEBIT_NOTES_REPORT_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function downloadDebitNotesReportReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('transactionid', action.data?.transactionId);
    bodyFormData.append('modeofpayment', action.data?.modeOfPayment);
    bodyFormData.append('invoice_type', action.data?.InvoiceType);
    bodyFormData.append('from_date', action.data?.fromDate);
    bodyFormData.append('to_date', action.data?.toDate);
    bodyFormData.append('wing_no', action.data?.wingId);
    bodyFormData.append('flat_id', action.data?.flatId);
    bodyFormData.append('search_by', action.data?.searchBy);
    bodyFormData.append('page', isNaN(parseInt(action.data?.page) - 1) ? 'all' : parseInt(action.data?.page) - 1);
    bodyFormData.append('limit', action.data?.pageLimit);


    return axiosInstance2.post(`${apiEndpoints.DOWNLOAD_DEBIT_NOTES_REPORT_API}`, bodyFormData, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getDeletedReceiptReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('search', action.data?.searchText);
    bodyFormData.append('page', action.data?.page);
    bodyFormData.append('limit', action.data?.pageLimit)
    bodyFormData.append('invoice_type', action.data?.invoiceType)

    return axiosInstance2.post(apiEndpoints.GET_DELETED_RECEIPT_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getReceiptDetailsReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('receiptid', action.data?.id);

    const BODY = {
        "id" : action.data?.societyId,
        "receiptid" : [action.data?.id]
    }

    return axiosInstance2.post(apiEndpoints.GET_RECIEPT_DETAILS_API, BODY)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


export async function getReceiptDetailsViewReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('receiptid', action.data?.id);

    const BODY = {
        "id" : action.data?.societyId,
        "receiptid" : [action.data?.id]
    }

    return axiosInstance2.post(apiEndpoints.GET_RECIEPT_DETAILS_VIEW_API, BODY)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getChequeDepositSlipReportReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('backname', action.data?.bankId);
    bodyFormData.append('accountnum', action.data?.accountNo);
    bodyFormData.append('branchname', action.data?.branchName);
    bodyFormData.append('chqwingid', action.data?.wingId);
    bodyFormData.append('chqflatid', action.data?.flatId);
    bodyFormData.append('chq_member_name', action.data?.memberName);
    bodyFormData.append('searchby', action.data?.dateType);
    bodyFormData.append('srchdate_from', action.data?.fromDate);
    bodyFormData.append('srchdate_to', action.data?.toDate);
    bodyFormData.append('export', action.data?.export);


    return axiosInstance2.post(apiEndpoints.GET_CHEQUE_DEPOSIT_SLIP_REPORT_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getCountedInvoiceReportReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('export', action.data?.export);

    return axiosInstance2.get(`${apiEndpoints.GET_COUNTED_INVOICE_REPORT_API}?id=${action.data.societyId}&export=${action.data?.export}`)
        // return axiosInstance2.post(apiEndpoints.GET_COUNTED_INVOICE_REPORT_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getReceiptListReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('trans_no', action.data?.transactionId)
    bodyFormData.append('payment', action.data?.modeOfPayment)
    bodyFormData.append('searchby', action.data?.searchBy)
    bodyFormData.append('from_date', action.data?.fromDate)
    bodyFormData.append('to_date', action.data?.toDate)
    bodyFormData.append('invoice_type', action.data?.InvoiceType)
    bodyFormData.append('page', action.data?.page);
    bodyFormData.append('limit', action.data?.pageLimit)
    bodyFormData.append('export', action.data?.export)

    return axiosInstance2.post(apiEndpoints.GET_RECEIPTS_LIST_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getIncompleteTransactionReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('txnid', action.data?.transactionId)
    bodyFormData.append('payment', action.data?.modeOfPayment)
    bodyFormData.append('from_date', action.data?.fromDate)
    bodyFormData.append('to_date', action.data?.toDate)
    bodyFormData.append('invoice_type', action.data?.InvoiceType)
    bodyFormData.append('page', action.data?.page);
    bodyFormData.append('limit', action.data?.pageLimit)

    return axiosInstance2.post(apiEndpoints.GET_INCOMPLETE_TRANSACTION_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getIncompleteTransactionDetailsReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('receiptid', action.data?.id);

    return axiosInstance2.post(apiEndpoints.GET_INCOMPLETE_TRANSACTION_DETAILS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getMemberDueSummaryReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('dueReportType', action.data?.reportType);
    bodyFormData.append('hdrwingno', action.data?.wingId);
    bodyFormData.append('hdrdueflatno', action.data?.flatId);
    bodyFormData.append('show_flat_area', action.data?.flatArea);
    bodyFormData.append('hdrmembername', action.data?.memberName);
    bodyFormData.append('hdrto_date', action.data?.toDate);
    bodyFormData.append('export', action.data?.export);


    return axiosInstance2.get(`${apiEndpoints.GET_MEMBER_DUE_SUMMARY_API}?id=${action.data?.societyId}&dueReportType=${action.data?.reportType}&hdrwingno=${action.data?.wingId}&hdrdueflatno=${action.data?.flatId}&show_flat_area=${action.data?.flatArea}&hdrmembername=${action.data?.memberName}&invoice_type=${action.data?.invoiceType}&hdrto_date=${action.data?.toDate}&export=${action.data?.export}`)
        // return axiosInstance2.get(`${apiEndpoints.GET_MEMBER_DUE_SUMMARY_API}?id=${action.data?.societyId}&dueReportType=${action.data?.reportType}&wingno=${action.data?.wingId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getMemberBillDueReportReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('dueReportType', action.data?.reportType);
    bodyFormData.append('invoice_type', action.data?.invoiceType);
    bodyFormData.append('hdrwingno', action.data?.wingId);
    bodyFormData.append('show_flat_area', action.data?.flatArea);
    bodyFormData.append('hdrdueflatno', action.data?.flatId);
    bodyFormData.append('hdrmembername', action.data?.memberName);
    bodyFormData.append('hdrto_date', action.data?.toDate);
    bodyFormData.append('aging_days', action.data?.age);
    bodyFormData.append('export', action.data?.export);


    return axiosInstance2.get(`${apiEndpoints.GET_MEMBER_BILL_DUE_REPORT_API}?id=${action.data?.societyId}&dueReportType=${action.data?.reportType}&hdrwingno=${action.data?.wingId}&hdrdueflatno=${action.data?.flatId}&show_flat_area=${action.data?.flatArea}&hdrmembername=${action.data?.memberName}&invoice_type=${action.data?.invoiceType}&hdrto_date=${action.data?.toDate}&aging_days=${action.data?.age}&export=${action.data?.export}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

export async function getOpeningBalanceDataReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('typeid', action.data?.invoiceType);


    return axiosInstance2.get(`${apiEndpoints.GET_OPENING_BALANCE_DATA_API}?id=${action.data.societyId}&typeid=${action.data?.invoiceType}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


export async function deleteBillingReportExcelReq(action) {
    const BODY =
    {
        file_name: action?.data?.download_url
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.DELETE_FINANCE_REPORT_EXCEL_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}