import * as Yup from "yup";

Yup.addMethod(Yup.array, "checkUnique", function (propertyName, message) {
  return this.test("unique", message, function (list) {
    const uniqueSet = new Set();
    const { path } = this;
    list.forEach((item, index) => {
      if (uniqueSet.has(item[`${propertyName}`])) {
        throw this.createError({
          path: `${path}[${index}].${propertyName}`,
          message,
        });
        // return false;
      } else {
        uniqueSet.add(item[`${propertyName}`]);
      }
    });
    return true;
  });
});

//society profile
export const societyChangePasswordValidation = () => {
  return Yup.object({
    oldPassword: Yup.string().required("*Old password is required"),
    newPassword: Yup.string()
      .required("*New password is required")
      .matches(/^[\S]+$/, "*Password cannot contain spaces")
      .matches(/^[^_]+$/, "*Password cannot contain underscores")
      .min(8, "*Password must be at least 8 characters long")
      .max(255, "*Password must be no more than 255 characters long")
      .matches(/[a-z]/, "*Password must contain at least 1 lowercase letter.")
      .matches(/[A-Z]/, "*Password must contain at least 1 uppercase letter.")
      .matches(/[0-9]/, "*Password should contain altleast 1 digit.")
      .matches(
        /[!@#$%^&*]/,
        "*Password should contain altleast 1 special character."
      ),
    confirmPassword: Yup.string()
      .required("*Confirm password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });
};

export const EditSocietyProfileValidation = () => {
  return Yup.object({
    //profile info
    societyName: Yup.string().required("*Society Name is required"),
    profileURL: Yup.string(),
    societyNameForSMS: Yup.string().required("*Required"),
    panNumber: Yup.string().
      test("not-only-zeros", "*Invalid PAN number", (value) => {
        if (value)
          return /[a-zA-Z]{5}\d{4}[a-zA-Z]{1}/.test(value);
        else return true
      })
      .min(10, "*PAN No must be of 10 digits"),
    // .required("*Pan Number is required")
    tanNumber: Yup.string(),
    spintlyOrganisationID: Yup.string(),
    spintlyDeviceID: Yup.number().typeError("*Must be number"),
    checkInTheNameOf: Yup.string(),
    currencyName: Yup.string(),
    //about
    description: Yup.string(),
    clubTimming: Yup.string(),
    //basic info
    builderName: Yup.string(),
    complexType: Yup.string(),
    area: Yup.string().required("*Area is required"),
    constructionStatus: Yup.string(),
    country: Yup.string().required("*Required"),
    state: Yup.string().required("*Required"),
    city: Yup.string(),
    pinCode: Yup.number().typeError("*Must be number").required("*Required"),
    //association info
    associationName: Yup.string(),
    gstNo: Yup.string()
    // .test(
    //   "not-only-zeros",
    //   "*Invalid GST number",
    //   (value) => {
    //     return !/^0/.test(value);
    //   }
    // ),
      .test('not-only-zeros', '*Invalid GST Registration number', value => {
        value = value || ''
        return /(^$)|(^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$)/.test(value);
      }),
    isRegistered: Yup.string(),
    projectName: Yup.string(),
    propertyTaxNo: Yup.string(),
    localAuthRegesNo: Yup.string(),
    //contact
    address: Yup.string().required("*Address is required"),
    website: Yup.string(),
    phone: Yup.string()
      .matches(/^[+]{1}[0-9]+[-]{1}[0-9]+$/, "Format should be +91-XXXXXXXX13").max(16, 'Number can contain max 12 digits')
      .required("*Required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/,
        "*Please enter valid email address"
      )
      .required("*Please enter email address"),
    supportedEmail: Yup.string(),
    otherDetails: Yup.string(),
    clientId: Yup.string(),
    eInvoiceUsername: Yup.string(),
    gstHeroLogin: Yup.string(),
    gstUsername: Yup.string(),
    tdsUsername: Yup.string(),
    einvoiceUsernamecred: Yup.string(),
    incomeTaxUsername: Yup.string(),
    clientSecret: Yup.string()
    // .required("*New password is required")
    // .matches(/^[\S]+$/, "*Password cannot contain spaces")
    // .matches(/^[^_]+$/, "*Password cannot contain underscores")
    // .min(8, "*Should contain at least 8 characters")
    // .max(255, "*Should not more than 255 characters")
    // .matches(/[a-z]/, "*Should contain at least 1 lowercase")
    // .matches(/[A-Z]/, "*Should contain at least 1 uppercase")
    // .matches(/[0-9]/, "*Should contain altleast 1 digit")
    // .matches(/[!@#$%^&*]/, "*Should have atleast 1 special char")
    ,
    eInvoicePassword: Yup.string()
    // .required("*New password is required")
    // .matches(/^[\S]+$/, "*Password cannot contain spaces")
    // .matches(/^[^_]+$/, "*Password cannot contain underscores")
    // .min(8, "*Should contain at least 8 characters")
    // .max(255, "*Should not more than 255 characters")
    // .matches(/[a-z]/, "*Should contain at least 1 lowercase")
    // .matches(/[A-Z]/, "*Should contain at least 1 uppercase")
    // .matches(/[0-9]/, "*Should contain altleast 1 digit")
    // .matches(/[!@#$%^&*]/, "*Should have atleast 1 special char")
    ,
    gstHeroPassword: Yup.string()
    // .required("*New password is required")
    // .matches(/^[\S]+$/, "*Password cannot contain spaces")
    // .matches(/^[^_]+$/, "*Password cannot contain underscores")
    // .min(8, "*Should contain at least 8 characters")
    // .max(255, "*Should not more than 255 characters")
    // .matches(/[a-z]/, "*Should contain at least 1 lowercase")
    // .matches(/[A-Z]/, "*Should contain at least 1 uppercase")
    // .matches(/[0-9]/, "*Should contain altleast 1 digit")
    // .matches(/[!@#$%^&*]/, "*Should have atleast 1 special char")
    ,
    gstPassword: Yup.string()
    // .required("*New password is required")
    // .matches(/^[\S]+$/, "*Password cannot contain spaces")
    // .matches(/^[^_]+$/, "*Password cannot contain underscores")
    // .min(8, "*Should contain at least 8 characters")
    // .max(255, "*Should not more than 255 characters")
    // .matches(/[a-z]/, "*Should contain at least 1 lowercase")
    // .matches(/[A-Z]/, "*Should contain at least 1 uppercase")
    // .matches(/[0-9]/, "*Should contain altleast 1 digit")
    // .matches(/[!@#$%^&*]/, "*Should have atleast 1 special char")
    ,
    tdsPassword: Yup.string()
    // .required("*New password is required")
    // .matches(/^[\S]+$/, "*Password cannot contain spaces")
    // .matches(/^[^_]+$/, "*Password cannot contain underscores")
    // .min(8, "*Should contain at least 8 characters")
    // .max(255, "*Should not more than 255 characters")
    // .matches(/[a-z]/, "*Should contain at least 1 lowercase")
    // .matches(/[A-Z]/, "*Should contain at least 1 uppercase")
    // .matches(/[0-9]/, "*Should contain altleast 1 digit")
    // .matches(/[!@#$%^&*]/, "*Should have atleast 1 special char")
    ,
    einvoicePasswordcred: Yup.string()
    // .required("*New password is required")
    // .matches(/^[\S]+$/, "*Password cannot contain spaces")
    // .matches(/^[^_]+$/, "*Password cannot contain underscores")
    // .min(8, "*Should contain at least 8 characters")
    // .max(255, "*Should not more than 255 characters")
    // .matches(/[a-z]/, "*Should contain at least 1 lowercase")
    // .matches(/[A-Z]/, "*Should contain at least 1 uppercase")
    // .matches(/[0-9]/, "*Should contain altleast 1 digit")
    // .matches(/[!@#$%^&*]/, "*Should have atleast 1 special char")
    ,
    incomeTaxPassword: Yup.string()
    // .required("*New password is required")
    // .matches(/^[\S]+$/, "*Password cannot contain spaces")
    // .matches(/^[^_]+$/, "*Password cannot contain underscores")
    // .min(8, "*Should contain at least 8 characters")
    // .max(255, "*Should not more than 255 characters")
    // .matches(/[a-z]/, "*Should contain at least 1 lowercase")
    // .matches(/[A-Z]/, "*Should contain at least 1 uppercase")
    // .matches(/[0-9]/, "*Should contain altleast 1 digit")
    // .matches(/[!@#$%^&*]/, "*Should have atleast 1 special char")
    ,
  });
};

//specific details
export const updateSpecificDetailsValidation = () => {
  return Yup.object({
    registrationNo: Yup.string().required("*Registration Number is required"),
    dateOfRegistration: Yup.date().required(
      "*Date of Registration is required"
    ),
    act: Yup.string().required("*Act is required"),
    buildUpArea: Yup.number().typeError("*Must be number"),
    // .required("*Required"),
    areaOfPlot: Yup.number().typeError("*Must be number"),
    // .required("*Required"),
    constructionCosttPerUnit: Yup.string(),
    // .required("*Required"),
    constructionUnit: Yup.string().required("*Required"),
    valuePerShare: Yup.number()
      .typeError("*Must be number"),
    // .required("*Required"),
    noOfWaterInlets: Yup.number().typeError("*Must be number"),
    // .required("*Required"),
    areaType: Yup.string(),
  });
};

export const wingsAndFlatValidation = () => {
  return Yup.object({
    wings: Yup.array()
      .of(
        Yup.object().shape({
          wingNo: Yup.string().required("*Wing number required"),
          wingTitle: Yup.string(),
          numberOfFloors: Yup.number()
            .typeError("*Must be number")
            .required("*Number of floors is required"),
          numberOfUnits: Yup.string(),
        })
      )
      .checkUnique("wingNo", "*Wing number must be unique"),
  });
};

export const FlatValidation = () => {
  return Yup.object({
    flats: Yup.array()
      .of(
        Yup.object().shape({
          flatNo: Yup.string().required("*Required"),
          area: Yup.number().typeError("*Must be number").required("*Required"),
          numberOfFloors: Yup.string().required("*Required"),
          virtualAccNo: Yup.string(),
          flatStatus: Yup.string(),
        })
      )
      .checkUnique("flatNo", "*Flat number must be unique"),
  });
};

export const BillTariffValidation = () => {
  return Yup.object({
    billTariff: Yup.array().of(
      Yup.object().shape({
        flatNo: Yup.string(),
        floorNo: Yup.string().nullable(),
        waterInletes: Yup.string(),
        annualPropertyTax: Yup.string(),
        annualWaterTax: Yup.string(),
        annualOccupancyTax: Yup.string(),
        annualOtherTax: Yup.string(),
        other1: Yup.string(),
        other2: Yup.string(),
        other3: Yup.string(),
        other4: Yup.string(),
      })
    ),
  });
};

export const UtilityReadingValidation = () => {
  return Yup.object({
    utilityReading: Yup.array().of(
      Yup.object().shape({
        fixedCharge: Yup.number().typeError("*Must be number"),
        lastMonthReading: Yup.number().typeError("*Must be number"),
        currentMonthReading: Yup.number().typeError("*Must be number").required("*Required")
          .test('lest-then-last-month-reading', '*Enter more than last month reading', function (value) {
            if (this.parent.lastMonthReading > value) {
              return false
            }
            else return true
          }),
        units: Yup.number().typeError("*Must be number"),
      })
    ),
  });
};

export const BankDetailsValidation = () => {
  return Yup.object({
    bankData: Yup.array().of(
      Yup.object().shape({
        details: Yup.string().required("*Required"),
        detailsId: Yup.string(),
        bankName: Yup.string().required("*Required"),
        bankNameId: Yup.string(),
        accountType: Yup.string().required("*Required"),
        accountTypeId: Yup.string(),
        accountNumber: Yup.string()
          // .required("*Required")
          .test("not-only-zeros", "*Invalid account number", (value) => {
            if (value) {
              return !/^[0]+$/.test(value) && value !== "0"; 
            }
            return true;
          }
        ),
        // .min(11, "Bank Number must be atleast of 11 digits"),
        ifscCode: Yup.string()
          .required("*Required")
          .test("not-only-zeros", "*Invalid account number", (value) => {
            return !/^0/.test(value);
          })
        // .min(11, "IFSC Code must be of 11 chararcter")
        ,
        micrCode: Yup.string()
          .typeError("*Must be number")
          // .required("*Required")
          // .min(9, "MICR Code must be of 9 digits")
          .test("not-only-zeros", "*Invalid account number", (value) => {
            return !/^0/.test(value);
          })
        // .min(9, "MICR Code must be of 9 digits")
        ,
        chequeReturnCharges: Yup.string()
        // .required("*Required"),
      })
    ),
  });
};

export const BankNameValidation = () => {
  return Yup.object({
    bankName: Yup.string().required("*Bank Name is required"),
  });
};
