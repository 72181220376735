import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate, useParams } from "react-router";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import { TextArea, TextAreaFM } from "../../../components/InputFields/TextArea";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  addPostComment,
  getComplaintStatusList,
  getPostComment,
} from "../../../redux/actions/DeskActions/DeskAction";
import { FieldArray, Form, Formik } from "formik";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import Loader from "../../../components/Loader/Loader";
import { validateDate } from "../../../utils/helpers/universalFunctions";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { Link } from "react-router-dom";
import { UploadButtonFM } from "../../../components/Button/UploadButton";
import { PostCommentValidation } from "../../../validationSchema/deskSchema/logaTicketSchema";
import parse from "html-react-parser";

function PostComment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const [savedAttachment, setSavedAttachment] = useState([]);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, postCommentData, complaintStatusList } = useSelector(
    (state) => state.deskReducers
  );

  const handleSaveClick = (values, { resetForm }) => {
    let sendRequest = {
      ...values,
      ipAddress,
      societyId: loginData?.default_community?.community_id,
      sessionId: loginData?.session_id,
      onSuccessCallback: () => {
        resetForm();
      },
    };
    for (let i in values.attachments) {
      if (values.attachments[i].file == "") {
        values.attachments.splice(i);
      }
    }
    sendRequest.deleteAttachments = postCommentData?.attachments?.filter(
      (item, index) => !savedAttachment.includes(item.attachment)
    );
    sendRequest.savedAttachments = savedAttachment;

    dispatch(addPostComment(sendRequest));
  };

  useEffect(() => {
    let pay = { societyId: loginData?.default_community?.community_id };
    dispatch(getComplaintStatusList());
    dispatch(getPostComment({ ...pay, complaintId: id }));
  }, []);

  useEffect(() => {
    setSavedAttachment(
      postCommentData?.attachments?.map((item) => item.attachment)
    );
  }, [postCommentData]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            complaintId: postCommentData?.id,
            status: complaintStatusList?.filter(
              (item) => item.key === postCommentData?.status
            )?.[0]?.value,
            comment: "",
            emailAssignee: 0,
            emailOwner: 0,
            attachments: [{ file: "" }],
            cc: "",
            bcc: "",
          }}
          enableReinitialize
          validationSchema={PostCommentValidation}
          onSubmit={handleSaveClick}
        >
          {(formik) => {
            return (
              <Form>
                <>
                  <div className="border-b border-[#CCC]">
                    <ListHeaderBack
                      title="Post Comments"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <ButtonG
                        label="Cancel"
                        className="h-8"
                        onClick={() => {
                          navigate(-1);
                        }}
                        type={`button`}
                      />
                      <Button
                        label="Post"
                        className="h-8 px-4"
                        type={`submit`}
                      />
                    </ListHeaderBack>{" "}
                  </div>
                  <div className="p-2 gap-2">
                    {loading && id !== postCommentData?.id ? (
                      <Loader />
                    ) : (
                      <table className="table-auto w-full text-[#222222] text-sm">
                        <tbody>
                          <tr>
                            <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                              Ticket ID:
                            </td>
                            <td className="py-2">
                              {postCommentData?.ticket || ""}
                            </td>
                          </tr>
                          <tr>
                            <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                              Open For:
                            </td>
                            <td className="py-2">
                              {postCommentData?.days !== undefined &&
                              postCommentData?.hours !== undefined &&
                              postCommentData?.minutes !== undefined
                                ? `${postCommentData?.days || 0} Days, ${
                                    postCommentData?.hours || 0
                                  } Hrs, ${postCommentData?.minutes || 0} mins`
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                              Status:
                            </td>
                            <td className="py-2">
                              <DropdownFM2
                                options={
                                  complaintStatusList
                                    ?.filter((item) => {
                                      if (postCommentData?.status == "Open")
                                        return item.key !== "Reopen";
                                      return true;
                                    })
                                    .map((item) => ({
                                      label: item.key,
                                      value: item.value,
                                    })) || []
                                }
                                placeholder="Select Type"
                                className=""
                                width="208px"
                                height="36px"
                                onSelect={(selectedItem) => {
                                  formik.setFieldValue(
                                    "status",
                                    selectedItem.value
                                  );
                                }}
                                name={"status"}
                                value={complaintStatusList
                                  ?.filter(
                                    (item) =>
                                      item.value === formik.values.status
                                  )
                                  .map((item) => ({
                                    label: item.key,
                                    value: item.value,
                                  }))}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                              Logged By:
                            </td>
                            <td>{postCommentData?.full_name}</td>
                          </tr>
                          <tr>
                            <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                              Flat_No:
                            </td>
                            <td>{postCommentData?.wing_flat}</td>
                          </tr>
                          <tr>
                            <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                              Category:
                            </td>
                            <td>{postCommentData?.complaint_category}</td>
                          </tr>
                          {/* <tr>
                            <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                              Severity:
                            </td>
                            <td>{postCommentData?.severity || "-"}</td>
                          </tr> */}
                          <tr>
                            <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                              Description:
                            </td>
                            <td className="py-2">
                              {postCommentData?.description
                                ? parse(postCommentData?.description)
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                              Comments:
                            </td>
                            <td className="pb-4">
                              <div className="flex">
                                {postCommentData?.comments &&
                                postCommentData?.comments?.length > 0 ? (
                                  <div className="grid grid-col-6 gap-3 w-full">
                                    {postCommentData?.comments?.map(
                                      (comment, index) => (
                                        <div
                                          key={index}
                                          className="py-2 border-dashed border-b border-[#CCC] flex flex-row justify-between"
                                        >
                                          <div className="flex gap-3">
                                            <p> - </p>
                                            <p className=" text-[#AAA]">
                                              {" "}
                                              {comment.comments || "-"}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  "-"
                                )}

                                <div className="">
                                  <div className="grid grid-col-6 gap-3 w-full">
                                    {postCommentData?.comments?.map(
                                      (comment, index) => (
                                        <div
                                          key={index}
                                          className="py-2 text-[#AAA] whitespace-nowrap border-dashed border-b border-[#CCC]"
                                        >
                                          <div className="flex gap-3">
                                            <p> Status: </p>
                                            <p className="font-bold">
                                              {complaintStatusList
                                                ?.filter(
                                                  (item) =>
                                                    item.value ===
                                                    comment.status_update
                                                )
                                                .map((item) => {
                                                  return item.key;
                                                })}
                                            </p>
                                          </div>
                                          <div className="flex gap-3">
                                            <p> Posted By: </p>
                                            <p className="font-bold">
                                              {" "}
                                              {comment.comment_by}{" "}
                                            </p>
                                          </div>

                                          <div className="flex gap-3">
                                            <p> Posted On: </p>
                                            <p className=" text-[#AAA]">
                                              {comment.add_comment_date &&
                                              comment.add_comment_date !==
                                                "0000-00-00 00:00:00"
                                                ? new Date(
                                                    comment.add_comment_date
                                                  ).toLocaleString("en-GB", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    timeZone: "UTC",
                                                  })
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                              Attachment:
                            </td>
                            <td>
                              <div className="pb-4">
                                {savedAttachment?.length &&
                                savedAttachment?.length > 0 ? (
                                  postCommentData?.attachments?.map(
                                    (attach, index) => {
                                      {
                                        return savedAttachment.includes(
                                          attach?.attachment
                                        ) ? (
                                          <div
                                            key={index}
                                            className="whitespace-nowrap border-dashed border-b border-[#CCC]"
                                          >
                                            <div className="flex ">
                                              <div className="grid grid-col-6 gap-3 w-full py-2">
                                                <span key={index}>
                                                  {index + 1 + ". "}
                                                  <Link
                                                    target="_blank"
                                                    className="ms-1 underline text-xs text-blue-500"
                                                    to={attach?.attachment_url}
                                                  >
                                                    View Uploaded Doc
                                                  </Link>
                                                </span>
                                              </div>

                                              <div className="">
                                                <div className="grid grid-col-6 gap-3 w-full">
                                                  <div className="py-2 text-[#AAA] whitespace-nowrap">
                                                    <div className="flex gap-3">
                                                      <p> Status: </p>
                                                      <p className="font-bold">
                                                        {complaintStatusList
                                                          ?.filter(
                                                            (item) =>
                                                              item.value ===
                                                              attach.status
                                                          )
                                                          .map((item) => {
                                                            return item.key;
                                                          })}
                                                      </p>
                                                    </div>
                                                    <div className="flex gap-3">
                                                      <p> Posted On: </p>
                                                      <p className=" text-[#AAA]">
                                                        {attach.created_at &&
                                                        attach.created_at !==
                                                          "0000-00-00 00:00:00"
                                                          ? new Date(
                                                              attach.created_at
                                                            ).toLocaleString(
                                                              "en-GB",
                                                              {
                                                                day: "2-digit",
                                                                month:
                                                                  "2-digit",
                                                                year: "numeric",
                                                                hour: "numeric",
                                                                minute:
                                                                  "numeric",
                                                                timeZone:
                                                                  "Asia/Kolkata",
                                                              }
                                                            )
                                                          : "-"}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        );
                                      }
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={2}></td>
                                  </tr>
                                )}
                              </div>
                              <FieldArray
                                name="attachments"
                                render={(arrayHelpers) => (
                                  <>
                                    {formik.values.attachments.map(
                                      (item, index) => (
                                        <tr key={index}>
                                          <td>
                                            <div className="w-52  mb-1 d-flex">
                                              <UploadButtonFM
                                                showFileName={true}
                                                name={`attachments.${index}.file`}
                                                accept={
                                                  "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                                }
                                                onSelect={(e) =>
                                                  formik.setFieldValue(
                                                    `attachments.${index}.file`,
                                                    e
                                                  )
                                                }
                                                fileName={
                                                  formik.values?.attachments[
                                                    index
                                                  ]?.file?.name
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td className=" align-top">
                                            {index !==
                                              formik.values.attachments
                                                ?.length -
                                                1 && (
                                              <Button
                                                label="Delete"
                                                type="button"
                                                className="h-8 px-4"
                                                onClick={() => {
                                                  arrayHelpers.remove(index);
                                                }}
                                              />
                                            )}

                                            {index ==
                                              formik.values.attachments
                                                ?.length -
                                                1 &&
                                              !formik.values.attachments.some(
                                                (e) => e.file == ""
                                              ) &&
                                              formik.values?.attachments
                                                ?.length +
                                                savedAttachment?.length <=
                                                4 && (
                                                <Button
                                                  label="Add"
                                                  type="button"
                                                  className="h-8 px-4"
                                                  onClick={() => {
                                                    arrayHelpers.push({
                                                      file: "",
                                                    });
                                                  }}
                                                />
                                              )}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </>
                                )}
                              ></FieldArray>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="border-t-2 my-3"></div>
                  <table className="table-auto w-full">
                    <tbody>
                      <tr className="text-[#222222] text-sm">
                        <td className="px-2.5 w-44 py-2 text-[#AAAAAA] font-bold align-top">
                          Assigned To:
                        </td>
                        <td className="py-2">
                          {postCommentData?.assigned_to || ""}
                        </td>
                      </tr>
                      <tr className="text-[#222222] text-sm">
                        <td className="px-2.5 w-44 py-2 text-[#AAAAAA] font-bold align-top">
                          Serviced By:
                        </td>
                        <td className="py-2">
                          {postCommentData?.serviced_by || ""}
                        </td>
                      </tr>
                      <tr className="text-[#222222] text-sm">
                        <td className="px-2.5 w-44 py-2 text-[#AAAAAA] font-bold align-top">
                          Last Updated On:
                        </td>
                        <td className="py-2">
                          {postCommentData?.last_update_on &&
                          postCommentData?.last_update_on !==
                            "0000-00-00 00:00:00"
                            ? new Date(
                                postCommentData?.last_update_on
                              ).toLocaleString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                timeZone: "UTC",
                              })
                            : "-"}
                        </td>
                      </tr>
                      <tr className="text-[#222222] text-sm">
                        <td className="px-2.5 w-44 py-2 text-[#AAAAAA] font-bold align-top">
                          Write Your Comment Here
                        </td>
                        <td className="py-2">
                          <TextAreaFM
                            placeholder={"Enter"}
                            className={"w-52"}
                            row={4}
                            name="comment"
                            onChange={(e) =>
                              formik.setFieldValue("comment", e.target.value)
                            }
                            value={formik.values.comment}
                          />
                        </td>
                      </tr>
                      <tr className="text-[#222222] text-sm">
                        <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                          Email to
                        </td>
                        <td className="py-2">
                          <div>
                            <Checkbox
                              text={"Assignee"}
                              className={"w-28"}
                              initialchecked={formik.values.emailAssignee === 1}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `emailAssignee`,
                                  e.target.checked ? 1 : 0
                                );
                              }}
                            />
                            <Checkbox
                              text={"Owner"}
                              className={"w-28"}
                              initialchecked={formik.values.emailOwner === 1}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `emailOwner`,
                                  e.target.checked ? 1 : 0
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-[#222222] text-sm">
                        <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                          Cc
                        </td>
                        <td>
                          <TextInputFM
                            placeholder={""}
                            className={"w-52"}
                            name={`cc`}
                          />
                        </td>
                      </tr>
                      <tr className="text-[#222222] text-sm">
                        <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                          Bcc
                        </td>
                        <td>
                          <TextInputFM
                            placeholder={""}
                            className={"w-52"}
                            name={`bcc`}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
}

export default PostComment;
