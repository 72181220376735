import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../actions/index.types";
import * as req from "../../../axios/request/DeskReqs/LogAComplaintReqs/LogAComplaintReqs";
import { notify } from "../../../utils/notification";
import { complaintTicketDocPath } from "../../../firebase/getFirebasePath";
import { uploadImageOnFirebase } from "../../../firebase/firebaseFunction";



function* getComplaintNatureList(action) {
    try {
        const result = yield call(req.getComplaintNatureListReq);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_COMPLAINT_NATURE_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_COMPLAINT_NATURE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getComplaintTypeList(action) {
    try {
        const result = yield call(req.getComplaintTypeListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_COMPLAINT_TYPE_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_COMPLAINT_TYPE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getWingsList(action) {
    try {
        const result = yield call(req.getWingsListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_COMPLAINT_WINGS_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_COMPLAINT_WINGS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getFlatsNo(action) {
    try {
        const result = yield call(req.getFlatsNoReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_COMPLAINT_FLATS_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_COMPLAINT_FLATS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addLogAComplaint(action) {
    try {
        if (action.data.attachments) {
            const path = complaintTicketDocPath({ societyId: action.data?.societyId, flatId: action.data.flatsNo, fileName: action.data.attachments?.name })
            yield uploadImageOnFirebase(action.data.attachments, path)
            action.data.attachments = path;
        }

        const result = yield call(req.addLogAComplaintReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.ADD_LOG_A_COMPLAINT_SUCCESS, payload: result.data });
            action.data?.onSuccessCallback?.()
            notify('success', result?.message)
        } else {
            yield put({ type: types.ADD_LOG_A_COMPLAINT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addLogAComplaintAuditLog(action) {
    try {

        const result = yield call(req.addLogAComplaintReqAuditLog, action);
        if (result.statusCode === 200) {
            yield put({ type: types.ADD_LOG_A_COMPLAINT_AUDIT_LOG_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.ADD_LOG_A_COMPLAINT_AUDIT_LOG_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* editComplaint(action) {
    try {
        if (action.data.attachments) {
            const path = complaintTicketDocPath({ societyId: action.data?.societyId, flatId: action.data.flatsNo, fileName: action.data.attachments?.name })
            yield uploadImageOnFirebase(action.data.attachments, path)
            action.data.attachments = [path];
        } else {
            action.data.attachments = [];
        }

        const result = yield call(req.editComplaintReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.EDIT_LOG_A_COMPLAINT_SUCCESS, payload: result.data });
            action.data?.onSuccessCallback?.()
            notify('success', "Ticket updated successfully")
        } else {
            yield put({ type: types.EDIT_LOG_A_COMPLAINT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* getLogAComplaintSettingsSaga() {
    yield takeLatest(types.GET_COMPLAINT_NATURE_LIST, getComplaintNatureList);
    yield takeLatest(types.GET_COMPLAINT_TYPE_LIST, getComplaintTypeList);
    yield takeLatest(types.GET_COMPLAINT_WINGS_LIST, getWingsList);
    yield takeLatest(types.GET_COMPLAINT_FLATS_LIST, getFlatsNo);
    yield takeLatest(types.ADD_LOG_A_COMPLAINT, addLogAComplaint);
    yield takeLatest(types.ADD_LOG_A_COMPLAINT_AUDIT_LOG, addLogAComplaintAuditLog);
    yield takeLatest(types.EDIT_LOG_A_COMPLAINT, editComplaint);

}
