import * as Yup from "yup";

Yup.addMethod(Yup.array, "checkUnique", function (propertyName, message) {
  return this.test("unique", message, function (list) {
    const uniqueSet = new Set();
    const { path } = this;
    list.forEach((item, index) => {
      if (uniqueSet.has(item[`${propertyName}`])) {
        throw this.createError({
          path: `${path}[${index}].${propertyName}`,
          message,
        });
        // return false;
      } else {
        uniqueSet.add(item[`${propertyName}`]);
      }
    })
    return true;
  });
}
);
export const AddGateValidation = () => {
  return Yup.object({
    gates: Yup.array()
      .of(
        Yup.object().shape({
          gateId: Yup.string(),
          name: Yup.string().required("*Gate name number required"),
          latitude: Yup.number()
            .min(-90, 'Latitude must be at least -90') // Ensures the value is not less than -90
            .max(90, 'Latitude must be at most 90') // Ensures the value is not greater than 90
            .typeError('Latitude must be a valid number'),
          longitude: Yup.number()
            .min(-180, 'Longitude must be at least -180') // Ensures the value is not less than -180
            .max(180, 'Longitude must be at most 180') // Ensures the value is not greater than 180
            .typeError('Longitude must be a valid number')

        })
      )
      .checkUnique("name", "*Gate name must be unique"),
  });
};


export const GateSettingValidation = () => {
  return Yup.object({
    inGate: Yup.string().required('*Required'),
    outGate: Yup.string().required('*Required'),
    gateAccess: Yup.string().required('*Required'),
  });
};


export const StaffInOutValidation = () => {
  return Yup.object({
    staffCode: Yup.string().required('*Staff code is required').min(4, '*Staff code should be of 4 digit'),
  });
};

export const MainVisitorFormValidation = () => {
  return Yup.object({
    //prefix: Yup.string().required('*Required'),
    photo: Yup.string(),
    firstName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    lastName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    email: Yup.string().trim()
      .test('email', '*Please enter valid email address', (value) => {
        if (value?.length > 0)
          return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(value);
        else return true;
      }),
    mobileCountryCode: Yup.string().required('*Required'),
    countryISO: Yup.string().required('*Required'),
    mobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),

    phone: Yup.string().matches(/^[+]{1}[0-9]+[-]{1}[0-9]+$/, "Format should be +91-XXXXXXXX13").max(16, 'Number can contain max 12 digits').required('*Required'),
    count: Yup.string().trim().required('*Required').test("not-only-zeros", "*Count can not be 0", (value) => {
      return !/^0/.test(value);
    }),
    idProofType: Yup.string(),
    idProofNo: Yup.string()
      .when(['idProofType'], (idProofType, schema) => {
        return !idProofType[0] ?
          schema.test({
            test: idProofNo => !idProofNo?.length,
            message: '*Please select ID Proof Type'
          })
          :
          idProofType[0] === 'PAN' ?
            schema.required('*Required').min(10, '*PAN No must be of 10 digits')
              .test('not-only-zeros', '*Invalid PAN number', value => {
                return /[a-zA-Z]{5}\d{4}[a-zA-Z]{1}/.test(value);
              })
            :
            schema.test('not-only-zeros', '*Invalid identity proof number', value => {
              return !/(^$)|(^0)/.test(value);
            })
      }),
    vehicleType: Yup.string(),
    vehicleNumber: Yup.string().trim().test('not-only-zeros', '*Invalid vehicle number', value => {
      return /^(?!0).*/.test(value);
    }),
    visitorType: Yup.string().required('*Required'),
    from: Yup.string().trim().required('*Required'),
    laptop: Yup.string(),
    belongings: Yup.string().trim(),
    whereToVisit: Yup.array()
      .of(
        Yup.object().shape({
          wingId: Yup.string().required('*Required'),
          flatId: Yup.string().required('*Required'),
          memberId: Yup.string().required('*Required'),
        })
      ),
    purposeId: Yup.string().required('*Required'),
    pin: Yup.string(),
    mobilePINCheck: Yup.string(),
    addAsFrequentVisitor: Yup.string(),
    sendSMS: Yup.string(),
    comment: Yup.string(),

  })
}
export const MainVisitorFormPublicValidation = () => {
  return Yup.object({
    //prefix: Yup.string().required('*Required'),
    photo: Yup.string(),
    firstName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    email: Yup.string().trim()
      .test('email', '*Please enter valid email address', (value) => {
        if (value?.length > 0)
          return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(value);
        else return true;
      }),
    mobileCountryCode: Yup.string().required('*Required'),
    countryISO: Yup.string().required('*Required'),
    mobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),

    phone: Yup.string().matches(/^[+]{1}[0-9]+[-]{1}[0-9]+$/, "Format should be +91-XXXXXXXX13").max(16, 'Number can contain max 12 digits').required('*Required'),
    idProofType: Yup.string(),
    idProofNo: Yup.string()
      .when(['idProofType'], (idProofType, schema) => {
        return !idProofType[0] ?
          schema.test({
            test: idProofNo => !idProofNo?.length,
            message: '*Please select ID Proof Type'
          })
          :
          idProofType[0] === 'PAN' ?
            schema.required('*Required').min(10, '*PAN No must be of 10 digits')
              .test('not-only-zeros', '*Invalid PAN number', value => {
                return /[a-zA-Z]{5}\d{4}[a-zA-Z]{1}/.test(value);
              })
            :
            schema.test('not-only-zeros', '*Invalid identity proof number', value => {
              return !/(^$)|(^0)/.test(value);
            })
      }),
    vehicleType: Yup.string(),
    vehicleNumber: Yup.string().trim().test('not-only-zeros', '*Invalid vehicle number', value => {
      return /^(?!0).*/.test(value);
    }),
    visitorType: Yup.string().required('*Required'),
    from: Yup.string().trim().required('*Required'),
    laptop: Yup.string(),
    belongings: Yup.string().trim(),
    whereToVisit: Yup.array()
      .of(
        Yup.object().shape({
          wingId: Yup.string().required('*Required'),
          flatId: Yup.string().required('*Required'),
          memberId: Yup.string().required('*Required'),
        })
      ),
    purposeId: Yup.string().required('*Required'),
    pin: Yup.string(),
    mobilePINCheck: Yup.string(),
    addAsFrequentVisitor: Yup.string(),
    sendSMS: Yup.string(),
    comment: Yup.string(),

  })
}

export const AddParkingTypeValidation = () => {
  return Yup.object({
    parkingType: Yup.string().trim().required('*Required')
  });
};
