import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button/Button";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import { EditIcon, DeleteIcon } from "../../../../assets";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { Link, useNavigate } from "react-router-dom";
import {
  ADD_FROM_EXISITING_OCCUPANT_ENDPOINT,
  FAMILY_MEMBERS_LIST_ENDPOINT,
  MEMBER_STAFF_DETAILS_ENDPOINT,
  MORTGAGE_FORM_ENDPOINT,
  NOMINEES_DETAILS_ENDPOINT,
  UPLOAD_FLAT_DOCUMENTS_ENDPOINT,
  VEHICLE_DETAILS_ENDPOINT,
} from "../../../../Routing/routes";
import { useDispatch, useSelector } from "react-redux";
import { getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import Loader from "../../../../components/Loader/Loader";
import moment from "moment";
import { FieldArray } from "formik";
import {
  deleteOccupants,
  updateOccupantDetails,
} from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import LeavePageModal from "../../../../components/Modal/LeavePageModal";

const FlatTab = ({ label, active = false, className, OnDelete, onClick }) => {
  return (
    <div
      className={`inline-flex items-center gap-2 border-b-4 py-2 pl-2 cursor-pointer  ${
        active ? "border-red-650" : "border-transparent "
      } ${className}`}
    >
      <span onClick={onClick}>{label}</span>
      <DeleteIcon className="fill-red-650" onClick={OnDelete} />
      <div className="border-r border-[#CCC] py-3"></div>
    </div>
  );
};
const FlatTabsContainer = ({ data, currentTab, setCurrentTab, OnDelete }) => {
  return (
    <div className="border bg-[#FDF6F6] rounded-lg overflow-hidden h-fit">
      {data.map((item, index) => (
        <FlatTab
          key={index}
          active={index === currentTab}
          onClick={() => setCurrentTab(index)}
          label={`Flat Details: ${(index + 1).toString()?.padStart(2, "0")}`}
          className="mr-2"
          OnDelete={() => OnDelete(index)}
        />
      ))}
    </div>
  );
};

const FlatForm = ({
  flatData,
  setFieldValue,
  subscribeId,
  currentTab,
  userId,
  values,
  dirty,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { occupantDetail, updateLoader } = useSelector(
    (state) => state.occupantInfoReducer
  );
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const [leavePageModal, setLeavePageModal] = useState(false);
  const [navigateURL, setNavigateURL] = useState("");

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleMortgageClick = ({ flatId }) => {
    setLeavePageModal(true);
    setNavigateURL(
      `${MORTGAGE_FORM_ENDPOINT}/${occupantDetail.flat_data[0].society_id}/${flatId}`
    );
  };

  const handleFamilyClick = ({ flatId }) => {
    setLeavePageModal(true);
    setNavigateURL(
      `${FAMILY_MEMBERS_LIST_ENDPOINT}/${subscribeId}/${userId}/${flatId}`
    );
  };

  const handleVehicleClick = ({ flatId }) => {
    setLeavePageModal(true);
    setNavigateURL(
      `${VEHICLE_DETAILS_ENDPOINT}/${subscribeId}/${userId}/${flatId}`
    );
  };

  const handleNomineeClick = ({ flatId }) => {
    setLeavePageModal(true);
    setNavigateURL(
      `${NOMINEES_DETAILS_ENDPOINT}/${subscribeId}/${userId}/${flatId}`
    );
  };

  const handleStaffClick = ({ flatId }) => {
    setLeavePageModal(true);
    setNavigateURL(
      `${MEMBER_STAFF_DETAILS_ENDPOINT}/${subscribeId}/${userId}/${flatId}`
    );
  };

  const handleConfirm = () => {
    dispatch(
      updateOccupantDetails({
        ...values,
        societyId: loginData?.default_community?.community_id,
        sessionId: loginData?.session_id,
        ipAddress,
        onEndCallack: () => {
          navigate(navigateURL);
        },
      })
    );
  };
  useEffect(() => {
    const societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
  }, []);
  return (
    <div className="flex justify-between w-full gap-4 mt-3 ">
      <div className="w-1/2 pr-5 text-sm">
        <div className="flex justify-between mb-4">
          <span className="w-1/2">Wing Number</span>
          <span className="w-1/2">{flatData?.wingNumber || "-"}</span>
        </div>
        <div className="flex justify-between mb-4">
          <span className="w-1/2">Flat Number</span>
          <span className="w-1/2">{flatData?.flatNo || "-"}</span>
        </div>
        <div className="flex justify-between mb-6">
          <span className="w-1/2">Build Up Area in Sq. Ft.</span>
          <span className="w-1/2">{flatData?.buildUpArea || "-"}</span>
        </div>
        <div className="flex justify-between mb-5">
          <span className="w-1/2 text-sm">Lives Here Or Not</span>
          <div className="w-1/2">
            <Checkbox
              text="Yes"
              initialchecked={flatData?.liveHereOrNot === "Yes"}
              onChange={(e) => {
                setFieldValue(
                  `flats.${currentTab}.liveHereOrNot`,
                  e.target.checked ? "Yes" : "No"
                );
              }}
            />
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <TextInputFM
            label="Billing Name"
            placeholder="Enter Billing Name"
            className="w-56"
            name={`flats.${currentTab}.billingName`}
            // maxLength={30}
            type="text"
          />
        </div>
        <div className="flex justify-between">
          <span>Date of Payment Of Entrance Fee</span>
          <DatePickerComponentFM
            className="justify-between w-56 ml-4 h-9"
            onDateChange={(selectedDate) => {
              setFieldValue(
                `flats.${currentTab}.dateOfpayment`,
                moment(new Date(selectedDate)).format("YYYY-MM-DD")
              );
            }}
            name={`flats.${currentTab}.dateOfpayment`}
            defaultValue={
              flatData?.dateOfpayment ? new Date(flatData?.dateOfpayment) : null
            }
          />
        </div>
        <div className="flex justify-between">
          <span>Date of Admission</span>
          <DatePickerComponentFM
            className="justify-between w-56 ml-4 h-9"
            onDateChange={(selectedDate) => {
              setFieldValue(
                `flats.${currentTab}.dateofAdmission`,
                moment(new Date(selectedDate)).format("YYYY-MM-DD")
              );
            }}
            name={`flats.${currentTab}.dateofAdmission`}
            defaultValue={
              flatData?.dateofAdmission
                ? new Date(flatData?.dateofAdmission)
                : null
            }
          />
        </div>
        <TextInputFM
          label="Landline Number (Residence)"
          placeholder="Enter Landline Number"
          className="w-56"
          name={`flats.${currentTab}.landlineNumber`}
          type="number"
          maxLength="10"
        />
        <TextInputFM
          label="Profession"
          placeholder="Enter Profession"
          className="w-56"
          name={`flats.${currentTab}.profession`}
          maxLength={30}
        />
        <div>
          <TextAreaFM
            label="Full Address"
            placeholder="Enter Address"
            className={"w-56"}
            row={4}
            name={`flats.${currentTab}.fullAddress`}
            maxLength={200}
          />
          <div className="flex w-full mb-2">
            <span className="w-1/2"></span>
            <Link to={`${UPLOAD_FLAT_DOCUMENTS_ENDPOINT}/${flatData?.flatId}`}>
              <button className="-mt-4 text-blue-600 cursor-pointer hover:underline">
                Upload Flat Documents
              </button>
            </Link>
          </div>
        </div>
        <TextInputFM
          label="Number Of Share Certificate"
          placeholder="Enter Count"
          className="w-56"
          name={`flats.${currentTab}.noOfShareCertificate`}
          type="number"
          maxLength={100}
        />
        <TextInputFM
          label="Electric Meter"
          placeholder="Enter Meter Number"
          className="w-56"
          name={`flats.${currentTab}.electricMeter`}
          maxLength={50}
        />
        <TextInputFM
          label="Member Security Deposit"
          placeholder="Enter Amount"
          className="w-56"
          name={`flats.${currentTab}.memberSecurityDeposit`}
          type="decimal"
        />
        <div className="flex justify-between mb-4 text-sm">
          <span className="w-1/2">Lien/Mortgage Register</span>
          <button
            type="button"
            className="flex items-center w-1/2 gap-2 cursor-pointer text-red-650"
            onClick={() => handleMortgageClick({ flatId: flatData?.flatId })}
          >
            <EditIcon className="fill-red-650" /> Edit
          </button>
        </div>
        <div className="flex justify-between mb-4 text-sm">
          <span className="w-1/2">Number Of Family Member</span>
          <div className="flex w-1/2 gap-4">
            <span>{flatData?.noOfFamilymem}</span>
            <button
              type="button"
              className="flex items-center gap-2 cursor-pointer text-red-650"
              onClick={() => handleFamilyClick({ flatId: flatData?.flatId })}
            >
              <EditIcon className="fill-red-650" /> Edit
            </button>
          </div>
        </div>
        <div className="flex justify-between mb-4 text-sm">
          <span className="w-1/2">Number of Vehicle</span>
          <div className="flex w-1/2 gap-4">
            <span>{flatData?.noOfVehicle}</span>
            <button
              type="button"
              className="flex items-center gap-2 cursor-pointer text-red-650"
              onClick={() => handleVehicleClick({ flatId: flatData?.flatId })}
            >
              <EditIcon className="fill-red-650" /> Edit
            </button>
          </div>
        </div>
      </div>
      <div className="w-1/2 pr-5 text-sm">
        <div className="flex justify-between mb-4">
          <span className="w-1/2">Floor Number</span>
          <span className="w-1/2">{flatData?.floorNo}</span>
        </div>
        <div className="flex justify-between mb-4">
          <span className="w-1/2 ">Main Member</span>
          <span className="w-1/2">{flatData?.mainMember}</span>
        </div>
        <div className="flex justify-between mb-6">
          <span className="w-1/2 ">Joint Member</span>
          <span className="w-1/2">{flatData?.jointMember}</span>
        </div>
        <div className="flex justify-between mb-6">
          <span className="w-1/2 text-sm">Photo ID Submitted Or Not</span>
          <div className="w-1/2">
            <Checkbox
              text="Yes"
              initialchecked={flatData?.photoSubmitted === "Yes"}
              onChange={(e) => {
                setFieldValue(
                  `flats.${currentTab}.photoSubmitted`,
                  e.target.checked ? "Yes" : "No"
                );
              }}
            />
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <TextInputFM
            label="GSTIN Number"
            placeholder="Enter GSTIN Number"
            className="w-56"
            name={`flats.${currentTab}.gstNo`}
            maxLength={20}
            type="alphaNumber"
          />
        </div>
        <div className="flex justify-between">
          <span>Date of Membership</span>
          <DatePickerComponentFM
            className="justify-between w-56 ml-4 h-9"
            onDateChange={(selectedDate) => {
              setFieldValue(
                `flats.${currentTab}.dateOfMembership`,
                moment(new Date(selectedDate)).format("YYYY-MM-DD")
              );
            }}
            name={`flats.${currentTab}.dateOfMembership`}
            defaultValue={
              flatData?.dateOfMembership
                ? new Date(flatData?.dateOfMembership)
                : null
            }
          />
        </div>
        <TextInputFM
          label="Age On The Date Of Admission"
          placeholder="Enter Age"
          className="w-56"
          name={`flats.${currentTab}.age`}
          type="number"
          maxLength="3"
        />
        <div className="flex justify-between">
          <span className="mt-[7.8px]">CAM Start Date</span>
          <DatePickerComponentFM
            className="justify-between w-56 ml-4 h-9"
            onDateChange={(selectedDate) => {
              setFieldValue(
                `flats.${currentTab}.camStartDate`,
                moment(new Date(selectedDate)).format("YYYY-MM-DD")
              );
            }}
            name={`flats.${currentTab}.camStartDate`}
            defaultValue={
              flatData?.camStartDate ? new Date(flatData?.camStartDate) : null
            }
          />
        </div>
        {/* <div className="mt-[54px]"> */}
        <TextInputFM
          label="Intercom Number"
          placeholder="Enter Intercom Number"
          className="w-56"
          name={`flats.${currentTab}.intercomNo`}
          maxLength={20}
          type="alphaNumber"
        />
        {/* </div> */}
        <div className="mb-8">
          <TextAreaFM
            label="Brief Description Of Flat"
            placeholder="Enter Description"
            className={"w-56"}
            row={4}
            name={`flats.${currentTab}.description`}
            maxLength={300}
          />
        </div>
        <TextInputFM
          label="Share Certificate Number"
          placeholder="Enter Certificate Number"
          className="w-56"
          name={`flats.${currentTab}.shareCertificateNo`}
          type="number"
        />
        <TextInputFM
          label="Sale Deed Number"
          placeholder="Enter Sale Deed Number"
          className="w-56"
          name={`flats.${currentTab}.saleDeedNo`}
          type="number"
        />
        <TextInputFM
          label="Builder Security Deposit"
          placeholder="Enter Amount"
          className="w-56"
          name={`flats.${currentTab}.buildSecurityDeposit`}
          type="decimal"
        />
        <div className="flex justify-between mb-4 text-sm">
          <span className="w-1/2">Number Of Nominee</span>
          <div className="flex w-1/2 gap-4">
            <span>
              {flatData?.noOfNominee?.toString()?.padStart(2, "0") || "00"}
            </span>
            <button
              type="button"
              className="flex items-center gap-2 cursor-pointer text-red-650"
              onClick={() => handleNomineeClick({ flatId: flatData?.flatId })}
            >
              <EditIcon className="fill-red-650" /> Edit
            </button>
          </div>
        </div>
        <div className="flex justify-between mb-4 text-sm">
          <span className="w-1/2">Number Of Staff</span>
          <div className="flex w-1/2 gap-4">
            <span>{flatData?.noOfStaff}</span>
            <button
              className="flex items-center gap-2 cursor-pointer text-red-650"
              type="button"
              onClick={() => handleStaffClick({ flatId: flatData?.flatId })}
            >
              <EditIcon className="fill-red-650" /> Edit
            </button>
          </div>
        </div>
        <div className="flex justify-between mb-4 text-sm">
          <span className="w-1/2">Number of Water Inlets</span>
          <div className="flex w-1/2 gap-4">
            <span>{flatData?.noOfWaterInlets}</span>
          </div>
        </div>
      </div>
      <LeavePageModal
        onCancel={() => setLeavePageModal(false)}
        isOpen={leavePageModal}
        onConfirm={handleConfirm}
        loading={updateLoader}
      />
    </div>
  );
};

const FlatDetails = ({
  dirty,
  userDetails,
  loading,
  values,
  setFieldValue,
  subscribeId,
  userId,
}) => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);

  const handleChooseMoreFlatModal = () => {
    navigate(
      `${ADD_FROM_EXISITING_OCCUPANT_ENDPOINT}?search=${encodeURIComponent(
        userDetails?.subscriber_pri_email
      )}`
    );
  };

  const deleteFlat = (arrayHelpers, index, flatId, subscribeId) => {
    dispatch(
      deleteOccupants({
        deleteflatId: flatId,
        subscriberId: subscribeId,
        sessionId: loginData?.session_id,
        ipAddress,
        onSuccessCallback: () => {
          arrayHelpers.remove(index);
        },
      })
    );
  };

  return (
    <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-4">
      <div className="border-b border-[#CCC] mb-3">
        <ListHeader leftContent="Edit Flat Details">
          <Button
            label="Choose More Flat"
            className="h-8 "
            onClick={handleChooseMoreFlatModal}
          />
        </ListHeader>
      </div>
      <FieldArray
        name="flats"
        render={(arrayHelpers) => (
          <div className="min-h-[300px]">
            {!loading && values?.flats?.length === 0 ? (
              <div className="flex items-center justify-center">
                No Flat Details
              </div>
            ) : loading ? (
              <Loader />
            ) : (
              <>
                <FlatTabsContainer
                  data={values.flats}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  OnDelete={(index) => {
                    deleteFlat(
                      arrayHelpers,
                      index,
                      values.flats?.[currentTab]?.flatId,
                      subscribeId
                    );
                  }}
                />
                <FlatForm
                  dirty={dirty}
                  currentTab={currentTab}
                  flatData={values.flats?.[currentTab]}
                  setFieldValue={setFieldValue}
                  subscribeId={subscribeId}
                  userId={userId}
                  values={values}
                />
              </>
            )}
          </div>
        )}
      ></FieldArray>
    </div>
  );
};
export default FlatDetails;
