import apiEndpoints from "../../../api";
import axiosInstance from "../../../axiosInstance";
import { logOut } from "../../../../utils/helpers/logOut";
import { notify } from "../../../../utils/notification";
import { errorMsg } from "../../../../consts/responseMessages";
import getBrowserDetails from "../../../../utils/helpers/getBrowserDetails";

export async function getComplaintCategoryListReq(action) {
  return axiosInstance
    .get(apiEndpoints.COMPLAINT_CATEGORY_LIST_API)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getComplaintCategoryDetailsListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.COMPLAINT_CATEGORY_DETAILS_LIST_API}?id=${action.data.categoryId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function editComplaintCategoryReq(action) {
  const BODY = {
    society_id: action.data.society_id,
    title: action.data?.title,
    id: action.data?.id,
    msg_template: action.data?.msgTemplate,
    type: action.data?.type,
    sub_category: action.data?.subCategory?.map((item) => ({
      id: item?.id || 0,
      title: item?.subTitle,
      msg_template: item?.subMessageTemplate,
    })),
    ip: action.data?.ipAddress,
    session_id: action.data?.sessionId,
    browser_details: getBrowserDetails(),
  };
  return axiosInstance
    .put(`${apiEndpoints.EDIT_COMPLAINT_CATEGORY_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function addComplaintCategoryReq(action) {
  const BODY = {
    society_id: action.data.society_id,
    title: action.data?.title,
    msg_template: action.data?.msgTemplate,
    sub_category: action.data?.subCategory?.map((item) => ({
      title: item?.subTitle,
      msg_template: item?.subMessageTemplate,
    })),
    ip: action.data?.ipAddress,
    session_id: action.data?.sessionId,
    browser_details: getBrowserDetails(),
  };
  return axiosInstance
    .post(`${apiEndpoints.ADD_COMPLAINT_CATEGORY_API}`, { ...BODY })

    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function deleteCategoryReq(action) {
  const BODY = {
    id: action.data?.fileId,
    ip: action.data?.ipAddress,
    session_id: action.data?.sessionId,
    browser_details: getBrowserDetails(),
  };

  return axiosInstance
    .put(`${apiEndpoints.DELETE_COMPLAINT_CATEGORY_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      }
      else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getComplaintCategoryAuditLogsReq(action) {
  const BODY = {
    society_id: action.data.societyId,
  }
  return axiosInstance.post(`${apiEndpoints.GET_COMPLAINT_CATEGORY_AUDIT_LOG_API}`, { ...BODY })
    .then(response => {
      return response;
    }).catch((errors) => {
      console.log("errors", errors);
      if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
        logOut()
      } else if (errors.response?.data.statusCode === 501) {
        notify('warning', errors.response.data.message);
      }
      return errors

    })
}

export async function getComplaintSettingReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_COMPLAINT_SETTINGS_API}?society_id=${action.data.societyId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function updateComplaintSettingReq(action) {
  const BODY = {
    society_id: action.data.societyId,
    cat_setting: action.data.cat_setting,
  };

  return axiosInstance
    .put(`${apiEndpoints.UPDATE_COMPLAINT_SETTING_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getComplaintSettingReqV2(data) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_COMPLAINT_SETTINGS_API}?society_id=${data.societyId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function updateComplaintSettingReqV2(data) {
  const BODY = {
    society_id: data.societyId,
    cat_setting: data.cat_setting,
  };

  return axiosInstance
    .put(`${apiEndpoints.UPDATE_COMPLAINT_SETTING_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}


export async function getComplaintTypeSettingReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.VIEW_COMPLAINT_TYPE_SETTING_API}?society_id=${action.data.societyId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function updateComplaintTypeSettingReq(action) {
  console.log(action.data);
  const BODY = {
    society_id: action.data?.societyId,
    yes_no: action.data?.yesNo,
  };

  return axiosInstance
    .put(`${apiEndpoints.UPDATE_COMPLAINT_TYPE_SETTING_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}